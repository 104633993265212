import * as React from "react";
import { connect } from "react-redux";
import { Col, Row, Container, Button, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Card from "../../Card/Card";
import { Link, Redirect } from "react-router-dom";
import "../../../assets/css/quotation/view-quotation.css";
import { ApplicationState } from "../../../store/index";
import { type } from "os";
import * as Account from "../../../store/Account";
import { WorkInProcess, ManufacturingOverhead } from "../common/AccountType";




//interface IAccountDetailsInternalProps {

//}

//interface IAccountDetailsState {
//    accountHeadId: number,
//    fromDate: Date,
//    toDate:Date
//}

//type AccountDetailsProps = IAccountDetailsInternalProps & IAccountDetailsState

let date = new Date();
date.setDate(date.getDate());
const toDate = date.toISOString().substr(0,10);
date = new Date();
date.setFullYear(2020, 2, 30);
const fromDate = date.toISOString().substr(0,10);

class AccountDetailsView extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            accountHeadId: 0,
            fromDate: fromDate,
            toDate: toDate,
        }
        this.handleLoadForm = this.handleLoadForm.bind(this);
        this.loadDate = this.loadDate.bind(this);
    }

    componentWillMount(): void {
        let id = this.props.match.params.id;
        if (id) {
            let params = {
                accountId: id,
                toDate: this.state.toDate,
                fromDate: this.state.fromDate,
                isDetail: true,
                voucherNo: 0
            }
            this.props.requestJournalDetails(params);
            this.setState({accountHeadId: id})
        }

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            let id = nextProps.match.params.id;
            if (id) {
                let params = {
                    accountId: id,
                    toDate: this.state.toDate,
                    fromDate: this.state.fromDate,
                    isDetail: true,
                    voucherNo: 0
                }
                this.props.requestJournalDetails(params);
                this.setState({accountHeadId: id})
            }

        }
    }

    _thousandSeparator(num) {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }





    _getAmountForCell(a, type) {
        return a.isOpeningBalance
            ? (a.type === type
                ? this._thousandSeparator(a.amount.toFixed(2))
                : "---")
            : a.type === type
                ? "---"
                : this._thousandSeparator(a.amount.toFixed(2));
    }


    _generateRow(a, convt1, convt2, initBalance, endingBalance, isSpecial) {
        const voucherDt = a.voucherDate && new Date(a.voucherDate);
        const minDate = new Date(1, 1, 1);
        //const jvnInv = (a.account.name === "Sales" && a.salesInvoice.invoiceCode
        const jvnInv = (a.account.name === "Sales" && a.salesInvoice && a.salesInvoice.invoiceCode

            ? (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}<br />{`${a.salesInvoice.invoiceCode}`}</div>)
            : (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}</div>));
        if (isSpecial) {
            let op = a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit;
            return [
                (<Link
                    title="Click to see details"
                    to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(a.voucherNo)}` : `/accounting/details/${a.account.name}-${a.accountId}`}>
                    {a.account.name}
                </Link>),
                op > 0 ? this._thousandSeparator(op.toFixed(2)) : "0.00",
                op < 0 ? this._thousandSeparator(Math.abs(op).toFixed(2)) : "0.00",
                a.debit ? this._thousandSeparator(a.debit.toFixed(2)) : "0.00",
                a.credit ? this._thousandSeparator(a.credit.toFixed(2)) : "0.00",
                endingBalance > 0 ? this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) : "0.00",
                endingBalance < 0 ? this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) : "0.00"
            ];

        } else {
            return [
                voucherDt && voucherDt.getTime() > minDate.getTime() ? convt1.format(voucherDt) : "",
                a.isOpeningBalance ?
                    "(Opening Balance)"
                    : <Link
                        title="Click to see details"
                        to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(a.voucherNo)}` : `/accounting/details/${a.account.name}-${a.accountId}`}>
                        {a.account.name}
                    </Link>,
                //Math.abs(initBalance).toMoney() + " " + (initBalance >= 0 ? "Dr" : "Cr"),
                this._getAmountForCell(a, 1),
                this._getAmountForCell(a, 2),
                this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) + " " + (endingBalance >= 0 ? "Cr" : "Dr"),
                a.comments || "No Comments",
                a.voucherNo ? jvnInv : ""
            ];
        }
    }






    generateRows(journalModel) {
        debugger;
        journalModel = { ...journalModel }
        if (journalModel.journals) {
            journalModel.journals = [...journalModel.journals]
        }
        const opt1 = { month: "long", day: "numeric", year: "numeric" };
        const opt2 = { month: "short", day: "numeric" };
        const convt1 = new Intl.DateTimeFormat("en-US", opt1);
        const convt2 = new Intl.DateTimeFormat("en-US", opt2);
        let lastBalance, manufecturings, endingBalance = journalModel.openingBalance ? journalModel.openingBalance.debit - journalModel.openingBalance.credit : 0;
        //if (journalModel.isDetail && journalModel.account && journalModel.account.id === WorkInProcess) {
        //    manufecturings = journalModel.journals.filter(j => j.account.parentId === ManufacturingOverhead);
        //    journalModel.journals = journalModel.journals.filter(j => j.account.parentId !== ManufacturingOverhead);
        //}
        const rows = (journalModel.journals || []).map((a, i) => {
            const voucherDt = a.voucherDate && new Date(a.voucherDate);
            debugger;
            if (!journalModel.isDetail) {
                if (!lastBalance) {
                    //if (journalModel.openingBalance) {
                    lastBalance = journalModel.openingBalance.debit - journalModel.openingBalance.credit;
                    //}
                    //else {
                    //    lastBalance = 0;
                    //}

                }
                return [
                    voucherDt ? convt1.format(voucherDt) : "",
                    "Balance",
                    this._thousandSeparator(Math.abs(lastBalance).toFixed(2)) + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                    this._thousandSeparator(a.debit.toFixed(2)),
                    this._thousandSeparator(a.credit.toFixed(2)),
                    this._thousandSeparator(Math.abs((lastBalance = (a.debit && a.credit ? ((a.debit - a.credit) + lastBalance) : 0))).toFixed(2)) + " " + (a.debit && a.credit ? a.debit >= a.credit ? "Dr" : "Cr" : "")
                ];
            }

            let initBalance = endingBalance;
            if (journalModel.isSpecial) {
                endingBalance = (a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit) + (a.debit - a.credit);
            } else {
                endingBalance += a.isOpeningBalance
                    ? a.type === 2
                        ? a.amount
                        : -1 * a.amount
                    : a.type === 1
                        ? a.amount
                        : -1 * a.amount;
            }
            return this._generateRow(a, convt1, convt2, initBalance, endingBalance, journalModel.isSpecial);
        });
        if (rows.length === 0) {
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
        }
        let openingBalance = journalModel.openingBalance;
        if (journalModel.isDetail && openingBalance) {
            var date = new Date(this.state.fromDate)
            var dateCal = date.setDate(date.getDate() - 1)

            rows.splice(0, 0, [
                "Initial Balance",
                `From ${convt1.format(dateCal)}`,
                "",
                "",
                //openingBalance.debit >= openingBalance.credit ? (openingBalance.debit - openingBalance.credit).toMoney() : "",
                //openingBalance.credit > openingBalance.debit ? (openingBalance.credit - openingBalance.debit).toMoney() : "",
                this._thousandSeparator(Math.abs(openingBalance.debit - openingBalance.credit).toFixed(2)) + " " +
                ((openingBalance.debit - openingBalance.credit) >= 0 ? "Dr" : "Cr"), "", ""
            ])
        }
        if (!journalModel.isDetail) {
            rows.push([
                "",
                "Total",
                this._thousandSeparator((Math.abs(lastBalance) || 0).toFixed(2)) + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                "",
                "",
                ""
            ]);
            return rows;
        }

        if (journalModel.isSpecial) {
            let sumOfOpDr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.debit - j.account.openingBalanceJournal.credit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            let sumOfOpCr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.credit - j.account.openingBalanceJournal.debit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            let sumOfDr = journalModel.journals
                .map(j => j.debit)
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .map(j => j.credit)
                .reduce((a, b) => a + b, 0);

            let endDr = sumOfOpDr + sumOfDr;
            let endCr = sumOfOpCr + sumOfCr;

            let row = [
                "Total: ",
                this._thousandSeparator(sumOfOpDr.toFixed(2)),
                this._thousandSeparator(sumOfOpCr.toFixed(2)),
                `৳ ${this._thousandSeparator(sumOfDr.toFixed(2))}`,
                `৳ ${this._thousandSeparator(sumOfCr.toFixed(2))}`,
                this._thousandSeparator(endDr.toFixed(2)),
                this._thousandSeparator(endCr.toFixed(2))

            ];
            rows.push(row);
        }
        else if (journalModel.journals) { // total balance calculation

            let sumOfCr = journalModel.journals
                .filter(a => journalModel.isDetail
                    ? a.isOpeningBalance
                        ? a.type === 2
                        : a.type === 1
                    : a.amount < 0)
                .map(j => j.amount)
                .reduce((a, b) => a + b, 0);

            let sumOfDr = journalModel.journals
                .filter(a => journalModel.isDetail ? a.isOpeningBalance ? a.type === 1 : a.type === 2 : a.amount > 0)
                .map(j => j.amount)
                .reduce((a, b) => a + b, 0);

            let row = [
                "Total Debit/Credit",
                "",
                "৳ " + this._thousandSeparator(sumOfDr.toFixed(2)),
                "৳ " + this._thousandSeparator(sumOfCr.toFixed(2)),
                "",
                "",
                ""
            ];
            rows.push(row);


            if (openingBalance) {
                sumOfCr += openingBalance.credit;
                sumOfDr += openingBalance.debit;
            }

            let totalBalance = Math.abs(sumOfCr - sumOfDr);

            rows.push([
                "",
                "Total",
                sumOfDr >= sumOfCr ? "৳ " + this._thousandSeparator(totalBalance.toFixed(2)) : "",
                sumOfCr > sumOfDr ? "৳ " + this._thousandSeparator(totalBalance.toFixed(2)) : "",
                "",
                "",
                ""
            ]);

        }
        return rows;

    }

    handleLoadForm(e: any) {
        e.preventDefault();
        let id = this.props.match.params.id;
        let params = {
            accountId: id,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            isDetail: true,
            voucherNo: 0
        }
        this.props.requestJournalDetails(params);
    }
    loadDate(e) {
        let target = e.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        } as any)


    }

    render() {
        const { journalModel, isLoading } = this.props;
        let heads = [], rows = [];
        if (!isLoading && journalModel && journalModel.journals) {
            heads = !journalModel.isSpecial
                ? [
                    "Date of Transaction",
                    "Account Against",
                    journalModel.isDetail ? "Debit" : "Opening Balance",
                    journalModel.isDetail ? "Credit" : "Debit",
                    journalModel.isDetail ? "Note" : "Credit",
                    journalModel.isDetail ? "JVN/INV" : "Ending Balance"
                ]
                : [
                    "Account Against",
                    "Opening(Debit)",
                    "Opening(Credit)",
                    "Debit",
                    "Credit",
                    "Ending(Debit)",
                    "Ending(Credit)"
                ];

            if (!journalModel.isSpecial) {
                //if (journalModel.isDetail) {
                //    header.splice(2, 0, "Opening Balance");
                //}
                if (journalModel.isDetail) {
                    heads.splice(4, 0, "Balance");
                }
            }

            rows = this.generateRows(journalModel) as any;

        }

        const convt1 = new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: 'numeric' });

        return (
            <div className="corporateClient-section">
                <Row>
                    <Col md={12} className="cardPaddiingAccounDetails">
                        <Card
                            title=""
                            content={
                                <form onSubmit={this.handleLoadForm}>
                                    <Row>
                                        <div className=' d-inline shadow ml-3 searchFilter'>
                                            <i className='pe-7s-search' style={{ fontSize: 24 }}></i>
                                        </div>
                                        <h4 style={{ marginBottom: 20, marginTop: -20 }}>&nbsp; Filter </h4>
                                    </Row>
                                    <Row>
                                        <Col md={3} className='mb-2 InputGroup'>
                                            <label>From</label><br />
                                            <input className='date-picker-from-input inputField' type="date" name="fromDate" value={this.state.fromDate} onChange={this.loadDate} placeholder='01/01/2020' />

                                        </Col>

                                        <Col md={3} className='mb-2 ml-auto InputGroup'>
                                            <label>To</label><br />
                                            <input className='date-picker-from-input inputField' type="date" name="toDate" value={this.state.toDate} placeholder='01/01/2020' onChange={this.loadDate} />
                                        </Col>

                                        <Col md={3} className='mt-2 ml-auto mb-2 form'>

                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" id="customControlValidation1" name="radio-stacked" checked />
                                                <label className="custom-control-label" htmlFor="customControlValidation1">Deatails View</label>
                                            </div>

                                            <div className="custom-control custom-radio mb-2">
                                                <input type="radio" className="custom-control-input" id="customControlValidation2" name="radio-stacked" />
                                                <label className="custom-control-label" htmlFor="customControlValidation2">Brief View</label>

                                            </div>


                                        </Col>

                                        <Col md={2} className='mb-2'>
                                            <Button onChange={this.handleLoadForm} className='btn btn-sm btn-info text-uppercase btn-search' formAction='search' active >
                                                <i className="fa fa-search" style={{ fontSize: 14, color: 'white', }}></i>&nbsp;
                                                             Search
                                                    </Button>

                                        </Col>
                                    </Row>


                                    {/*<Row>

                                        <Col md={5} className='mt-1'>

                                            <input className='form-control inputField' type='search' placeholder='Search Client' />
                                        </Col>

                                    </Row>*/}


                                </form>
                            }
                        />
                    </Col>
                    <Col md={12}>
                        <Card
                            title=""
                            content={
                                <form>
                                    <Row>
                                        <Col>
                                            <div className="header-title title2">
                                                <h4 >
                                                    <i className='pe-7s-user' style={{ fontSize: 34 }}></i>
                                                    {(journalModel !== null && journalModel.account !== null) ? journalModel.account.name : ""}
                                                    {(journalModel !== null && journalModel.account !== null) ? typeof journalModel.account !== "undefined" ? `(${convt1.format(new Date(journalModel.account.createDate))})` : null : null}
                                                </h4>
                                            </div>
                                        </Col>
                                        {/* <Col>
                                            <div className="newBtn">
                                                <Link to="/corporateClient/new" className='btn-sm'>
                                                    <i className="pe-7s-add-user" style={{ fontSize: 16, color: 'red', }}></i>&nbsp;
                                                    Create New Client
                                             </Link>
                                            </div>
                                        </Col> */}
                                    </Row>


                                    <Table hover responsive striped>
                                        <thead>
                                            <tr>
                                                {heads.map(a => (
                                                    <th>
                                                        <strong>{a}</strong>
                                                        <i className="fa fa-sort"></i>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map(r => (<tr>  {r.map(c => (<td>{c}</td>))} </tr>))}

                                        </tbody>
                                    </Table>
                                    <Pagination aria-label="Page navigation example">



                                    </Pagination>
                                    <Row>
                                        <a className="btnRound shadow" target="_blank" href={`/print/account-details/${this.state.accountHeadId}?fromDate=${this.state.fromDate}&toDate=${toDate}`}><i className="pe-7s-print print"></i></a>
                                    </Row>

                                </form>

                            }
                        />
                    </Col>
                </Row>
            </div>


        );


    }


}

export default connect((state: ApplicationState) => {

    return {
        ...state.account
    }

}, { ...Account.actionCreators })(AccountDetailsView as any)

//export default connect<any>((state: ApplicationState) => {
//    return {
//        ...state.supplier,
//        ...state.client,
//        ...state.ownersEquity,
//        ...state.bank,
//        ...state.account,
//        ...state.authorization,
//        ...state.employee
//    }
//}, {
//    ...Supplier.actionCreators,
//    ...Client.actionCreators,
//    ...OwnersEquity.actionCreators,
//    ...BankAccount.actionCreators,
//    ...actionCreators,
//    ...Employee.actionCreators
//})(AddAccountHead as any)