import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import { IVehicleModel, IVehicleTypeModel, IVehicleBrandModel, IVehicleModelModel, IVehicleVersionModel, IVehicleJournalModel } from "../models/VehicleModel";
import graphQl from "../services/GraphQL";

export interface IVehicleState {
    vehicle: IVehicleModel | null,
    vehicleModel: IVehicleModelModel | null,
    vehicleUpdateError: Error | null,
    fetchError: Error | null,
    isVehicleUpdating: boolean,
    isLoading: boolean,
    vehicleBrands: Array<IVehicleBrandModel> | null,
    vehicleModels: Array<IVehicleModelModel> | null,
    vehicleTypes: Array<IVehicleTypeModel> | null,
    vehicleVersions: Array<IVehicleVersionModel> | null,
    vehicleModelAdded: boolean,
    vehicleInfoItemAdded: boolean,
    vehicleAdded: boolean,
    isResetVSearch: boolean
}


//Request GET actions
export interface RequestGetVehicles extends Action<string> {
    type: "REQUEST_GET_VEHICLES";
}

export interface RequestGetVehicleTypes extends Action<string> {
    type: "REQUEST_GET_VEHICLE_TYPES";
}

export interface RequestGetVehicleModels extends Action<string> {
    type: "REQUEST_GET_VEHICLE_MODELS";
}
export interface RequestGetVehicleModel extends Action<string> {
    type: "REQUEST_GET_VEHICLE_MODEL";
}

export interface RequestGetVehicleBrands extends Action<string> {
    type: "REQUEST_GET_VEHICLE_BRANDS";
}

export interface RequestGetVehicleVersions extends Action<string> {
    type: "REQUEST_GET_VEHICLE_VERSIONS";
}


//Request ADD actions
export interface RequestAddOrUpdateVehicle extends Action<string> {
    type: "REQUEST_ADD_UPDATE_VEHICLE";
}

export interface RequestAddOrUpdateVehicleModel extends Action<string> {
    type: "REQUEST_ADD_UPDATE_VEHICLE_MODEL";
}

export interface RequestAddOrUpdateVehicleType extends Action<string> {
    type: "REQUEST_ADD_UPDATE_VEHICLE_TYPE";
}

export interface RequestAddOrUpdateVehicleBrand extends Action<string> {
    type: "REQUEST_ADD_UPDATE_VEHICLE_BRAND";
}

export interface RequestAddOrUpdateVehicleAndClient extends Action<string> {
    type: "REQUEST_ADD_UPDATE_VEHICLE_AND_CLIENT";
}


//Response GET actions
export interface RespondGetVehicles extends Action<string> {
    type: "RESPOND_GET_VEHICLES";
}

export interface RespondGetVehicleTypes extends Action<string> {
    type: "RESPOND_GET_VEHICLE_TYPES";
    payload: Array<IVehicleTypeModel> | null;
    error: Error | null
}

export interface RespondGetVehicleModels extends Action<string> {
    type: "RESPOND_GET_VEHICLE_MODELS";
    payload: Array<IVehicleModelModel> | null;
    error: Error | null
}

export interface RespondGetVehicleModel extends Action<string> {
    type: "RESPOND_GET_VEHICLE_MODEL";
    payload: IVehicleModelModel | null;
    error: Error | null
}

export interface RespondGetVehicleBrands extends Action<string> {
    type: "RESPOND_GET_VEHICLE_BRANDS";
    payload: Array<IVehicleBrandModel> | null;
    error: Error | null
}

export interface RespondGetVehicleVersions extends Action<string> {
    type: "RESPOND_GET_VEHICLE_VERSIONS";
    payload: Array<IVehicleVersionModel> | null;
    error: Error | null
}

//Response ADD actions
export interface RespondAddOrUpdateVehicle extends Action<string> {
    type: "RESPOND_ADD_UPDATE_VEHICLE";
    payload: IVehicleModel | null;
    error: Error | null
}

export interface RespondAddOrUpdateVehicleModel extends Action<string> {
    type: "RESPOND_ADD_UPDATE_VEHICLE_MODEL";
    payload: IVehicleModelModel | null;
    error: Error | null
}
export interface RespondAddOrUpdateVehicleType extends Action<string> {
    type: "RESPOND_ADD_UPDATE_VEHICLE_TYPE";
    payload: IVehicleTypeModel | null;
    error: Error | null
}
export interface RespondAddOrUpdateVehicleBrand extends Action<string> {
    type: "RESPOND_ADD_UPDATE_VEHICLE_BRAND";
    payload: IVehicleBrandModel | null;
    error: Error | null
}


export interface RequestGetVehicle extends Action<string> {
    type: "REQUEST_GET_VEHICLE";
}

export interface RequestAddUpdateVehicle extends Action<string> {
    type: "REQUEST_ADD_UPDATE_VEHICLE";
}

export interface RespondGetVehicle extends Action<string> {
    type: "RESPOND_GET_VEHICLE";
    payload: IVehicleModel | null;
    error: Error | null
}

export interface RspondAddUpdateVehicle extends Action<string> {
    type: "RESPOND_ADD_UPDATE_VEHICLE";
    payload: IVehicleModel | null;
    error: Error | null
}

export interface RspondAddUpdateVehicleAndClient extends Action<string> {
    type: "RESPOND_ADD_UPDATE_VEHICLE_AND_CLIENT";
    payload: IVehicleModel | null;
    error: Error | null
}

//Reset


export interface RESET {
    type: "RESET_VEHICLE_ACTION";
}


type KnownAction = RequestGetVehicles |
    RequestGetVehicleTypes |
    RequestGetVehicleModels |
    RequestGetVehicleBrands |
    RequestGetVehicleVersions |
    RequestAddOrUpdateVehicleModel |
    RespondGetVehicles |
    RespondGetVehicleTypes |
    RespondGetVehicleModels |
    RespondGetVehicleBrands |
    RequestGetVehicleModel |
    RespondGetVehicleVersions |
    RequestAddOrUpdateVehicle |
    RespondAddOrUpdateVehicle |
    RespondAddOrUpdateVehicleModel |
    RequestAddOrUpdateVehicleType |
    RespondAddOrUpdateVehicleType |
    RequestAddOrUpdateVehicleBrand |
    RespondGetVehicleModel |
    RespondAddOrUpdateVehicleBrand |
    RequestGetVehicle |
    RequestAddOrUpdateVehicleAndClient |
    RspondAddUpdateVehicleAndClient |
    RspondAddUpdateVehicle |
    RespondGetVehicle | RequestAddUpdateVehicle | 
    RESET

export const actionCreators = {
    requestAddOrUpdateVehicle: (vehicle: IVehicleModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_VEHICLE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addOrUpdateVehicle: IVehicleModel }>("addOrUpdateVehicle(vehicle:$vehicle){id modelId modelYear mileage licenseNo chessisNo clientId engineNo client{id name type contactPerson accountName accountNo address areaId bankName bKashAccountNo branchName cellPhone cityId}  model{id brandId name vehicleTypeId vehicleType{ id name code} brand{id name }}}", ["$vehicle:VehicleInputType!"], { vehicle }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE", payload: data.addOrUpdateVehicle, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE", payload: null, error: e });
        }
    },

    requestAddOrUpdateVehicleAndClient: (vehicle: IVehicleModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_VEHICLE_AND_CLIENT" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ vehicleClient: IVehicleModel }>("vehicleClient:addOrUpdateVehicleAndClient(vehicle:$vehicle){id modelId modelYear mileage licenseNo chessisNo clientId engineNo client{id name type contactPerson accountId accountName accountNo address areaId bankName bKashAccountNo branchName cellPhone cityId}  model{id brandId name vehicleTypeId vehicleType{ id name code} brand{id name }}}", ["$vehicle:VehicleInputType!"], { vehicle }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_AND_CLIENT", payload: data.vehicleClient, error: null });
            // dispatch({ type: "RESET_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_AND_CLIENT", payload: null, error: e });
        }
    },

    requestGetVehicleTypes: (code: string | null = null): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_VEHICLE_TYPES" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ vehicleTypes: Array<IVehicleTypeModel> }>("vehicleTypes(code:$code){ id name code}", ["$code:String"], { code }, authData);
            console.log(data);
            dispatch({ type: "RESPOND_GET_VEHICLE_TYPES", payload: data.vehicleTypes, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_VEHICLE_TYPES", payload: null, error: e });
        }
    },
    requestGetVehicleBrands: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_VEHICLE_BRANDS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ vehicleBrands: Array<IVehicleBrandModel> }>("vehicleBrands{ id name code}", [], {}, authData);
            dispatch({ type: "RESPOND_GET_VEHICLE_BRANDS", payload: data.vehicleBrands, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_VEHICLE_BRANDS", payload: null, error: e });
        }
    },

    requestGetVehicleModels: (typeId?: number | null, brandId?: number | null, name?: string | null): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_VEHICLE_MODELS" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ vehicleModels: Array<IVehicleModelModel> }>(
                `vehicleModels(typeId:$typeId,brandId:$brandId,name:$name){
                    id name code brandId active vehicleTypeId 
                    vehicleType {id name} 
                    vehicleBrand{id name}
	            }`,
                ["$typeId:Int", "$brandId:Int", "$name:String"],
                { typeId, brandId, name }, authData);

            dispatch({ type: "RESPOND_GET_VEHICLE_MODELS", payload: data.vehicleModels, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_VEHICLE_MODELS", payload: null, error: e });
        }
    },
    requestGetVehicleModel: (modelId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_VEHICLE_MODEL" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ vehicleModel: IVehicleModelModel }>("vehicleModel(modelId:$modelId){ id name code brandId active vehicleTypeId vehicleType{ id name code} brand{id name} }", ["$modelId:Int!"], { modelId }, authData);
            dispatch({ type: "RESPOND_GET_VEHICLE_MODEL", payload: data.vehicleModel, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_VEHICLE_MODEL", payload: null, error: e });
        }
    },

    requestAddOrUpdateVehicleModel: (vehicleModel: IVehicleModelModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_VEHICLE_MODEL" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addOrUpdateVehicleModel: IVehicleModelModel }>("addOrUpdateVehicleModel(vehicleModel:$vehicleModel){ id name code brandId }", ["$vehicleModel:VehicleModelInputType!"], { vehicleModel }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_MODEL", payload: data.addOrUpdateVehicleModel, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_MODEL", payload: null, error: e });
        }
    },

    requestGetVehicle: (licenseNo: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_VEHICLE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ vehicle: IVehicleModel }>("vehicle(licenseNo:$licenseNo){id modelId modelYear mileage licenseNo chessisNo clientId engineNo client{id name type accountName contactPerson accountNo address isCommercial areaId bankName accountId bKashAccountNo branchName cellPhone cityId}  model{id brandId name vehicleTypeId vehicleType{ id name code} brand{id name}}}", ["$licenseNo:String"], { licenseNo }, authData);
            dispatch({ type: "RESPOND_GET_VEHICLE", payload: data.vehicle, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_VEHICLE", payload: null, error: e });
        }
    },

    requestGetVehicleById: (vehicleId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_VEHICLE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ vehicleById: IVehicleModel }>("vehicleById(vehicleId:$vehicleId){id modelId modelYear mileage licenseNo chessisNo clientId engineNo client{id name type accountName contactPerson accountNo address areaId bankName accountId bKashAccountNo branchName cellPhone cityId parent {name id} }  model{id brandId name vehicleTypeId vehicleType{ id name code} brand { id name }}}", ["$vehicleId:Int!"], { vehicleId }, authData);
            dispatch({ type: "RESPOND_GET_VEHICLE", payload: data.vehicleById, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_VEHICLE", payload: null, error: e });
        }
    },

    requestAddOrUpdateVehicleType: (vehicleType: IVehicleTypeModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_VEHICLE_TYPE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addOrUpdateVehicleType: IVehicleTypeModel }>("addOrUpdateVehicleType(vehicleType:$vehicleType){ id name code active }", ["$vehicleType:VehicleTypeInputType!"], { vehicleType }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_TYPE", payload: data.addOrUpdateVehicleType, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_TYPE", payload: null, error: e });
        }
    },

    requestAddOrUpdateVehicleBrand: (vehicleBrand: IVehicleBrandModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_VEHICLE_BRAND" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addOrUpdateVehicleBrand: IVehicleBrandModel }>("addOrUpdateVehicleBrand(vehicleBrand:$vehicleBrand){ id name code active  }", ["$vehicleBrand:VehicleBrandInputType!"], { vehicleBrand }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_BRAND", payload: data.addOrUpdateVehicleBrand, error: null });
            dispatch({ type: "RESET_VEHICLE_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_VEHICLE_BRAND", payload: null, error: e });
        }
    },
    resetVehicleState: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "RESET_VEHICLE_ACTION" });
    },
}

const unloadedState: IVehicleState = {
    vehicle: null,
    vehicleModel: null,
    vehicleUpdateError: null,
    isVehicleUpdating: false,
    isLoading: false,
    vehicleBrands: null,
    vehicleModels: null,
    vehicleTypes: null,
    vehicleVersions: null,
    fetchError: null,
    vehicleModelAdded: false,
    vehicleInfoItemAdded: false,
    vehicleAdded: false,
    isResetVSearch: false
}


export const reducer: Reducer<IVehicleState> = (state: IVehicleState | undefined, incomingAction: Action): IVehicleState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_VEHICLE":
            return {
                ...state,
                isVehicleUpdating: true
            }
        case "RESPOND_ADD_UPDATE_VEHICLE":
            return {
                ...state,
                vehicle: action.payload,
                vehicleUpdateError: action.error,
                isVehicleUpdating: false,
                vehicleAdded: action.payload !== null
            }

        case "REQUEST_ADD_UPDATE_VEHICLE_AND_CLIENT":
            return {
                ...state,
                isVehicleUpdating: true
            }
        case "RESPOND_ADD_UPDATE_VEHICLE_AND_CLIENT":
            debugger;
            return {
                ...state,
                vehicle: action.payload,
                vehicleUpdateError: action.error,
                isVehicleUpdating: false,
                vehicleAdded: action.payload !== null
            }

        case "REQUEST_GET_VEHICLE_TYPES":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_VEHICLE_TYPES":
            var payload = action;
            return {
                ...state,
                vehicleTypes: action.payload,
                fetchError: action.error,
                isLoading: false
            }
        case "REQUEST_GET_VEHICLE_BRANDS":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_VEHICLE_BRANDS":
            return {
                ...state,
                vehicleBrands: action.payload,
                fetchError: action.error,
                isLoading: false
            }

        case "REQUEST_GET_VEHICLE_MODELS":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_VEHICLE_MODELS":
            return {
                ...state,
                vehicleModels: action.payload,
                fetchError: action.error,
                isLoading: false
            }

        case "REQUEST_GET_VEHICLE_MODEL":
            return {
                ...state,
                isLoading: false
            }
        case "RESPOND_GET_VEHICLE_MODEL":
            return {
                ...state,
                vehicleModel: action.payload,
                fetchError: action.error,
                isLoading: false
            }

        case "REQUEST_GET_VEHICLE":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_VEHICLE":
            return {
                ...state,
                fetchError: action.error,
                isLoading: false,
                vehicle: action.payload,
                isResetVSearch: action.payload == null
            }

        case "REQUEST_ADD_UPDATE_VEHICLE_MODEL":
            return {
                ...state,
                isLoading: true,
                vehicleModelAdded: false,
            }

        case "RESPOND_ADD_UPDATE_VEHICLE_MODEL":
            return {
                ...state,
                fetchError: action.error,
                isLoading: false,
                vehicleModelAdded: action.payload !== null
            }

       

        case "REQUEST_ADD_UPDATE_VEHICLE_TYPE":
            return {
                ...state,
                isLoading: true,
                vehicleInfoItemAdded: false,
            }

        case "RESPOND_ADD_UPDATE_VEHICLE_TYPE":
            return {
                ...state,
                fetchError: action.error,
                isLoading: false,
                vehicleInfoItemAdded: action.payload !== null
            }

        case "REQUEST_ADD_UPDATE_VEHICLE_BRAND":
            return {
                ...state,
                isLoading: true,
                vehicleInfoItemAdded: false,
            }

        case "RESPOND_ADD_UPDATE_VEHICLE_BRAND":
            return {
                ...state,
                fetchError: action.error,
                isLoading: false,
                vehicleInfoItemAdded: action.payload !== null
            }

        case "RESET_VEHICLE_ACTION":
            return {
                ...state,
                fetchError: null,
                isLoading: false,
                vehicleModelAdded: false,
                vehicleInfoItemAdded: false,
                vehicleAdded: false,
                isResetVSearch: false,
                vehicle: null
            }


        default:
            return state;
    }
}
