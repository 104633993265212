import * as React from "react";
import { Grid, Row, Col, Table, Tabs, Tab } from "react-bootstrap";
import { Card } from "../../Card/Card";
import { Input, InputGroup, FormGroup, CustomInput, Label, Alert } from "reactstrap";
import Button from "../../CustomButton/CustomButton"
import "../../../assets/css/accounts/cashBankPayment.css"
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import * as Account from "../../../store/Account";
import Select from "react-select";
import { IJournalModel } from "../../../models/SupplierModel";
import { Redirect } from "react-router";

interface IAddPaymentReceiptDropDown {
    label: string,
    value: number
}


interface IAddPaymentReceiptsProps {

}

interface IAddPamentReceiptsState {
    paymentDate: Date
    paymentToAccountId: number,
    paymentAccountId: number,
    amount: number,
    notes: string
}

type Props = IAddPaymentReceiptsProps & Account.AccountState & typeof Account.actionCreators;



class AddSupplierPayment extends React.PureComponent<Props, IAddPamentReceiptsState> {
    state = {
        paymentDate: new Date(),
        paymentToAccountId: 0,
        paymentAccountId: 0,
        amount: 0,
        notes: ""
    };


    _renderDataList() {
        var items: IAddPaymentReceiptDropDown[] = []
        if (this.props.accounts != null) {
            this.props.accounts.map((q) => {
                items.push({
                    label: q.name,
                    value: q.id
                })
            })
        }
        return items;
    }

    _handleReceiptSearch(keyWord: string) {
        if (keyWord !== "" && this.props.accounts) {
            this.props.requestFilterAccounts(keyWord, false, true, false, true)
        }
    }

    componentDidMount() {
        this.props.requestPaymentAccounts();
    }

    //Debit 1, Credit 2
    handleSubmit = (e: React.FormEvent<HTMLFormElement>): any => {
        e.preventDefault();
        if (this._isFormValid()) {
            let journals: Array<IJournalModel> = [{
                accountId: this.state.paymentToAccountId,
                amount: this.state.amount,
                id: 0,
                comments: this.state.notes,
                isOpeningBalance: false,
                type: 1,
                voucherDate: this.state.paymentDate,
                voucherNo: undefined,
                active: true
            }, {
                accountId: this.state.paymentAccountId,
                amount: this.state.amount,
                id: 0,
                comments: this.state.notes,
                isOpeningBalance: false,
                type: 2,
                voucherDate: this.state.paymentDate,
                voucherNo: undefined,
                active: true
            }]
            this.props.requestAddJournalEnty(journals);
        }
    }



    componentWillReceiveProps(nextProps: Props) {
        nextProps.isJournalPosted && this._resetState()
    }

    _isFormValid(): boolean {
        if (this.state.paymentToAccountId !== 0
            && this.state.amount > 0
            && this.state.paymentAccountId !== 0
        ) return true

        return false
    }

    _resetState() {
        this.setState({
            amount: 0,
            notes: "",
            paymentAccountId: 0,
            paymentToAccountId: 0
        })
    }
    render() {
        const { paymentAccounts, isJournalPosted, journalEntryResult, error } = this.props;
        if (isJournalPosted && journalEntryResult !== null) {
            return <Redirect to="/account/payments" />;
        }
        return (
            <div>
                <Grid >
                    <Row>
                        <Col md={7} className="mt-5" style={{ margin: "0 auto" }}>
        <Card
            title=''
        content={
        <div>
        <form onSubmit={this.handleSubmit}>

        <Row>
            <div className=' d-inline shadow ml-3 titleMargin'>
        <i className='pe-7s-credit' style={{ fontSize: 32 }}></i>
        </div>

        <h4 style={{ marginBottom: 20, marginTop: -20 }}>&nbsp;Supplier Payments</h4>
        </Row>

        <div>
        {
            error &&
        <Alert color="danger">
            Failed!
            </Alert>
    }
        {
            isJournalPosted &&
            <Alert color="success">
                Success
                </Alert>
        }

        </div>

        <Row>
        <Col md={6}>
            <Label>Payment TO</Label>
        <Select

        value={this._renderDataList().filter(option => {
                return this.state.paymentToAccountId == option.value;
            })}
        className="from-control"
        onInputChange={(v) => this._handleReceiptSearch(v)}
        options={this._renderDataList()}
        onChange={(v: IAddPaymentReceiptDropDown) => {
            this.setState({
                paymentToAccountId: v.value
            })
        }} />

        </Col>

        <Col md={6}>
        <Label>Date*</Label> <br/>
        <DatePicker
            disabled={true}
        className="from-control"
        selected={this.state.paymentDate}
        onChange={(e) => {
            this.setState({ paymentDate: e })
        }}
        customInput={<input className='form-control' placeholder="Date" />}
        />
        </Col>
        </Row>
        <Row>
        <Col md={6}>
            <Label>Amount*</Label>
            <Input className='from-control' type="text" name="name" placeholder=''
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({
                amount: parseInt(e.target.value)
            })
        }}
        value={this.state.amount}
        />
        </Col>
        <Col md={6}>
            <Label>Payment Type*</Label>
        <select
        className="form-control"
        value={this.state.paymentAccountId}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            this.setState({
                paymentAccountId: parseInt(e.currentTarget.value)
            })
        }}>
        <option>-- Select Payment --</option>
        {paymentAccounts && paymentAccounts.map(item => { return <option value={item.id}> {item.name} </option> })}
        </select>

        </Col>
        </Row>      

        <Row>
        <Col md={12}>
            <Label>Notes*</Label>
            <textarea className="payment-text-area-fild" id="exampleFormControlTextarea1" rows={3}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                this.setState({
                    notes: e.target.value
                })
            }}
            value={this.state.notes}
            />
            </Col>
            </Row>

            <Row>
            <Col md={12}>

                <Button pullRight fill bsStyle="info" type="submit" disabled={this.props.isJournalPosting}>{this.props.isJournalPosting ? "Submiting..." : "Submit"}</Button>
                </Col>
                </Row>


                </form>
                </div>
        } />
        </Col>
        </Row>
        </Grid>
        </div>
        )
        }

        componentWillUnmount() {
            this.props.resetAccounts();
        }

    }

        export default connect((state: ApplicationState) => { return { ...state.account, } }, { ...Account.actionCreators })(AddSupplierPayment as any);
