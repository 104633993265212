
function parseBaseUrl() {
    const host = window.location.host;
    if (host.startsWith("localhost")) {
        return "https://localhost:44332/";
    } else if (host.includes("test")) {
        return "https://api-topgear-test.xeonsoftware.com/";
    } else if (host.includes("demo")) {
        return "https://api-automobile-demo.xeonsoftware.com/";
    } else {
        return "https://api-topgear.xeonsoftware.com/";
    }
}

export const BASE_URL = parseBaseUrl();

export const LOGIN = "api/auth/login";
export const GraphQL = "graphql";

export const ACCOUNTING_REPORT = "report/accounting";