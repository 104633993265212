import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import graphQl from "../services/GraphQL";
import { IQoutationItem } from "../models/QoutationItem";
import { IQoutation, IJobCard, IQoutationWrapper } from "../models/Qoutation";
import {
    ISalesInvoiceItem,
    ISalesInvoice,
    ISalesInvoiceWrapper,
    IOutsideService,
    IInvoicePayment,
    IInvoiceStockItem,
    IInvoicePaymentWrapper
} from "../models/SalesInvoice";
import { IVehicleModel } from "../models/VehicleModel";
import { ICommissionModel } from "../models/CommissionModel";
import * as VehicleStore from "./Vehicle";
import cloneDeep from 'lodash/cloneDeep';
import { IProductStockItemModel, IProductStockModel } from "../models/ProductStock";

export interface IQoutationState {
    isItemSearching: boolean;
    isQoutationPosting: boolean;
    isPosted: boolean;
    qoutationItems?: Array<IQoutationItem>;
    outsideServices?: Array<IOutsideService>;
    salesItems?: Array<ISalesInvoiceItem>;
    qoutation?: IQoutation;
    qoutations?: IQoutationWrapper;
    salesInvoices?: ISalesInvoiceWrapper;
    salesInvoice?: ISalesInvoice;
    invoiceCommissions?: ICommissionModel[];
    invoicePayments: IInvoicePayment[];
    isResetQtSearch?: boolean;
    invoiceAdded?: boolean,
    isQoutationLoading?: boolean,
    isInvoiceLoading?: boolean,
    jobCard: IJobCard,
    costPriceAdded: boolean,
    outsideServiceAdded: boolean,
    invoiceSubmitting: boolean,
    invoicedFailed: boolean,
    invoicePayment: IInvoicePayment,
    invoiceStockItems: IInvoiceStockItem[],
    page: number,
    pageSize: number,
    totalCount: number,
    isInvoiceCanceling: boolean,
    isInvoiceCanceled: boolean
}

export interface RequestGetQoutationItems extends Action<string> {
    type: "REQUEST_GET_QOUTATION_ITEMS";
}


export interface RespondGetQoutationItems extends Action<string> {
    type: "RESPOND_GET_QOUTATION_ITEMS";
    payload: Array<IQoutationItem> | null;
    error: Error | null
}

export interface RequestGetQoutationByNo extends Action<string> {
    type: "REQUEST_GET_QOUTATION_BY_NO";
}

export interface RequestGetSalesInvoices extends Action<string> {
    type: "REQUEST_GET_SALES_INVOICES";
}

export interface RespondGetSalesInvoices extends Action<string> {
    type: "RESPOND_GET_SALES_INVOICES";
    payload: ISalesInvoiceWrapper | null;
    error: Error | null
}

export interface RequestInvoicePayments extends Action<string> {
    type: "REQUEST_GET_INVOICE_PAYMENTS";
}

export interface RespondInvoicePayments extends Action<string> {
    type: "RESPOND_GET_INVOICE_PAYMENTS";
    payload: IInvoicePaymentWrapper | null;
    error: Error | null
}

export interface RequestGetSalesInvoice extends Action<string> {
    type: "REQUEST_GET_SALES_INVOICE";
}

export interface RespondGetSalesInvoice extends Action<string> {
    type: "RESPOND_GET_SALES_INVOICE";
    payload: ISalesInvoice | null;
    error: Error | null
}

export interface RequestGetSalesInvoiceCommission extends Action<string> {
    type: "REQUEST_GET_SALES_INVOICE_COMMISSION";
}

export interface RespondGetSalesInvoiceCommission extends Action<string> {
    type: "RESPOND_GET_SALES_INVOICE_COMMISSION";
    payload: ICommissionModel[] | null;
    error: Error | null
}

export interface RespondGetQoutationByNo extends Action<string> {
    type: "RESPOND_GET_QOUTATION_BY_NO";
    payload: IQoutation | null;
    error: Error | null
}

export interface RequestGetQoutation extends Action<string> {
    type: "REQUEST_GET_QOUTATION";
}


export interface RespondGetQoutation extends Action<string> {
    type: "RESPOND_GET_QOUTATION";
    payload: IQoutation | null;
    error: Error | null
}

export interface RequestGetSalesItems extends Action<string> {
    type: "REQUEST_GET_SALES_ITEMS";
}

export interface RequestGetSalesItemsByInvCode extends Action<string> {
    type: "REQUEST_GET_SALES_ITEMS_INV_CODE";
}


export interface RespondGetSalesItems extends Action<string> {
    type: "RESPOND_GET_SALES_ITEMS";
    payload: Array<ISalesInvoiceItem> | null;
    error: Error | null
}
export interface RespondGetSalesItemsInvCode extends Action<string> {
    type: "RESPOND_GET_SALES_ITEMS_INV_CODE";
    payload: Array<ISalesInvoiceItem> | null;
    error: Error | null
}


export interface RequestCostPriceEntry extends Action<string> {
    type: "REQUEST_COST_PRICE_ENTRY";
}


export interface RespondCostPriceEntry extends Action<string> {
    type: "RESPOND_COST_PRICE_ENTRY";
    payload: any | null;
    error: Error | null
}

export interface RequestAddQoutation extends Action<string> {
    type: "REQUEST_ADD_QOUTATION";
}


export interface RespondAddQoutation extends Action<string> {
    type: "RESPOND_ADD_QOUTATION";
    payload: IQoutation | null;
    error: Error | null
}

export interface RequestGetQoutations extends Action<string> {
    type: "REQUEST_GET_QOUTATIONS";
}

export interface RequestAddSalesInvoice extends Action<string> {
    type: "REQUEST_ADD_INVOICE";
}

export interface RespondAddSalesInvoice extends Action<string> {
    type: "RESPOND_ADD_INVOICE";
    payload: ISalesInvoice | null;
    error: Error | null
}

export interface RespondGetQoutations extends Action<string> {
    type: "RESPOND_GET_QOUTATIONS";
    payload: IQoutationWrapper | null;
    error: Error | null
}


export interface RequestGetOutsideServices extends Action<string> {
    type: "REQUEST_GET_OUTSIDE_SERVICES";
}

export interface RespondGetOutsideServices extends Action<string> {
    type: "RESPOND_GET_OUTSIDE_SERVICES";
    payload: IOutsideService[] | null;
    error: Error | null
}

export interface RequestAddOutsideServices extends Action<string> {
    type: "REQUEST_ADD_OUTSIDE_SERVICES";
}

export interface RespondAddOutsideServices extends Action<string> {
    type: "RESPOND_ADD_OUTSIDE_SERVICES";
    payload: IOutsideService[] | null;
    error: Error | null
}

export interface RequestAddInvoicePayment extends Action<string> {
    type: "REQUEST_ADD_INVOICE_PAYMENT";
}

export interface RespondAddInvoicePayment extends Action<string> {
    type: "RESPOND_ADD_INVOICE_PAYMENT";
    payload: IInvoicePayment | null;
    error: Error | null
}

export interface RequestGetInvoiceStockItems extends Action<string> {
    type: "REQUEST_GET_INVOICE_STOCK_ITEMS";
}

export interface RespondGetInvoiceStockItems extends Action<string> {
    type: "RESPOND_GET_INVOICE_STOCK_ITEMS";
    payload: IInvoiceStockItem[] | null;
    error: Error | null
}

export interface RequestCancelInvoice extends Action<string> {
    type: "REQUEST_CANCEL_INVOICE";
}

export interface RespondCancelInvoice extends Action<string> {
    type: "RESPOND_CANCEL_INVOICE";
    payload: ISalesInvoice | null;
    error: Error | null
}

export interface RESET extends Action<string> {
    type: "RESET";
}

export interface RESET_INVOICE extends Action<string> {
    type: "RESET_INVOICE";
}

export interface REMOVE_INVOICES extends Action<string> {
    type: "REMOVE_INVOICES";
}

type KnownAction = RequestGetQoutationItems |
    RespondGetQoutationItems |
    RequestGetSalesItemsByInvCode |
    RequestGetSalesItems |
    RespondGetSalesItems |

    RequestCostPriceEntry |
    RespondCostPriceEntry |

    RequestCancelInvoice |
    RespondCancelInvoice |

    RequestGetQoutation |
    RespondGetQoutation |
    RequestAddSalesInvoice |
    RespondAddSalesInvoice |
    RequestAddQoutation |
    RequestGetQoutationByNo |
    RespondGetQoutationByNo |
    RequestGetSalesInvoices |
    RespondGetSalesInvoices |
    RequestInvoicePayments |
    RespondInvoicePayments |
    RequestGetSalesInvoice |
    RespondGetSalesInvoice |
    RequestGetSalesInvoiceCommission |
    RespondGetSalesInvoiceCommission |
    RespondAddQoutation |
    RequestGetQoutations |
    RespondGetQoutations |
    RespondGetSalesItemsInvCode |
    RequestGetOutsideServices |
    RespondGetOutsideServices |
    RequestAddOutsideServices |
    RespondAddOutsideServices |
    RequestAddInvoicePayment |
    RespondAddInvoicePayment |
    RequestGetInvoiceStockItems |
    RespondGetInvoiceStockItems |
    REMOVE_INVOICES |
    RESET_INVOICE |
    RESET;


export const actionCreators = {
    requestGetQoutationItems: (filter: String, modelId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_QOUTATION_ITEMS" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ qoutationItems: Array<IQoutationItem> }>("qoutationItems(filter:$filter,modelId:$modelId){id productId code price title note quantity productStockItemId isService measuringUnit}", ["$filter:String!", "$modelId:Int"], { filter, modelId }, authData);

            dispatch({ type: "RESPOND_GET_QOUTATION_ITEMS", payload: data.qoutationItems, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_QOUTATION_ITEMS", payload: null, error: e });
        }
    },

    requestGetQoutations: (page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_QOUTATIONS" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ qoutations: IQoutationWrapper }>("qoutations(page:$page, pageSize:$pageSize){items {id qtNo vehicleId total discountPercent contactPerson vAT tax createDate qoutationItems{id code price title  quantity isService} vehicle{id modelId clientId licenseNo client{id name type cellPhone cityId} model{id brandId name  brand{id name}}}} page pageSize totalCount}", ["$page:Int!", "$pageSize:Int!"], { page, pageSize }, authData);

            dispatch({ type: "RESPOND_GET_QOUTATIONS", payload: data.qoutations, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_QOUTATIONS", payload: null, error: e });
        }
    },

    requestGetSalesInvoices: (vehicleId: number = 0, accountId: number = 0, page: number, pageSize: number,
        sortKey: string, ascending: boolean, filterKeys: string, filterValues: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            const appState = getState();
            try {
                dispatch({ type: "REQUEST_GET_SALES_INVOICES" });
                const authData = appState.authorization!.authData;

                const data = await graphQl.executeQuery<{ salesInvoices: ISalesInvoiceWrapper }>("salesInvoices (vehicleId:$vehicleId,accountId:$accountId, page:$page, pageSize:$pageSize,sortKey:$sortKey,ascending:$ascending,filterKeys:$filterKeys,filterValues:$filterValues){ items {id total totalPayment vehicleId clientId jobCardNo  vAT tax invoiceCode paymentAmount  qoutation{id qtNo} vehicle{id modelId licenseNo} createDate salesDate status  client { id name accountId parent{id name}}} page pageSize totalCount}", ["$vehicleId:Int!", "$accountId:Int!", "$page:Int!", "$pageSize:Int!", "$sortKey:String!", "$ascending:Boolean!", "$filterKeys:String!", "$filterValues:String!"], { vehicleId, accountId, page, pageSize, sortKey, ascending, filterKeys, filterValues }, authData);
                dispatch({ type: "RESPOND_GET_SALES_INVOICES", payload: data.salesInvoices, error: null });

            } catch (e) {
                dispatch({ type: "RESPOND_GET_SALES_INVOICES", payload: null, error: e });
            }
        },

    requestGetSalesInvoicesByDate: (vehicleId: number = 0, accountId: number = 0, fromDate: Date, toDate: Date, page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SALES_INVOICES" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ salesInvoices: ISalesInvoiceWrapper }>("salesInvoices:salesInvoicesByDate (vehicleId:$vehicleId,accountId:$accountId, fromDate:$fromDate, toDate:$toDate, page:$page, pageSize:$pageSize){ items {id total vehicleId clientId discount  vAT tax invoiceCode paymentAmount totalPayment qoutation{id qtNo} vehicle{id modelId licenseNo} createDate salesDate  client { id name parent{id name}}} page pageSize totalCount}", ["$vehicleId:Int!", "$accountId:Int!", "$fromDate:Date", "$toDate:Date", "$page:Int!", "$pageSize:Int!"], { vehicleId, accountId, fromDate, toDate, page, pageSize }, authData);

            dispatch({ type: "RESPOND_GET_SALES_INVOICES", payload: data.salesInvoices, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SALES_INVOICES", payload: null, error: e });
        }
    },

    requestGetSalesInvoiceItemsByInvoiceId: (invoiceId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SALES_ITEMS" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ salesItems: Array<ISalesInvoiceItem> }>("salesItems:salesItemsByInvoiceId(id: $id) {id productId productStockItemId  productStockItem { id  stockId  productId   supplierId costPrice}  originalQty quantity salesPrice product {id name }}", ["$id:Int"], { id: invoiceId }, authData);

            dispatch({ type: "RESPOND_GET_SALES_ITEMS", payload: data.salesItems, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SALES_ITEMS", payload: null, error: e });
        }
    },

    requestGetSalesInvoiceItemsByInvoiceCode: (invCode: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SALES_ITEMS_INV_CODE" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ salesItems: Array<ISalesInvoiceItem> }>("salesItems:salesItemsByInvoiceCode(invCode: $invCode) {id productId quantity salesPrice invoice{id invoiceClosed} product { id name productModels { id name}} productStockItemId invoiceId }", ["$invCode:Int"], { invCode: invCode }, authData);

            dispatch({ type: "RESPOND_GET_SALES_ITEMS_INV_CODE", payload: data.salesItems, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SALES_ITEMS_INV_CODE", payload: null, error: e });
        }
    },

    requestGetSalesInvoiceShort: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SALES_INVOICE" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ salesInvoice: ISalesInvoice }>(
                `salesInvoice(id: $id) { id vehicle { id licenseNo } total commissionVouchers totalCommission invoiceCode invoiceClosed createDate}`,
                ["id: Int!"], { id }, authData);

            dispatch({ type: "RESPOND_GET_SALES_INVOICE", payload: data.salesInvoice, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SALES_INVOICE", payload: null, error: e });
        }
    },

    requestGetSalesInvoiceCommission: (invoiceId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SALES_INVOICE_COMMISSION" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ salesInvoice: ISalesInvoice }>(
                `salesInvoice(id: $id) { id vehicle { id licenseNo } total commissions{  name phone amount commissionDate } commissionVouchers totalCommission invoiceCode }`,
                ["id: Int!"], { id: invoiceId }, authData);

            dispatch({ type: "RESPOND_GET_SALES_INVOICE_COMMISSION", payload: data.salesInvoice.commissions, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SALES_INVOICE_COMMISSION", payload: null, error: e });
        }
    },

    requestGetSalesInvoice: (id: number, invCode: number = 0): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const appState = getState();

        try {

            dispatch({ type: "REQUEST_GET_SALES_INVOICE" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ salesInvoice: ISalesInvoice }>(
                `salesInvoice(id: $id, invCode:$invCode) { 
                    id createdBy createDate salesDate discount total status vAT tax totalPayment status invoiceCode clientId vehicleId contactPerson paymentAmount jobCardNo
                    client {
                     id name type cityId contactPerson accountName accountNo address areaId bankName bKashAccountNo branchName cellPhone isCommercial accountId
                    }
                    vehicle {
                      id clientId 
    	              chessisNo engineNo licenseNo mileage modelId 
                      client{id name type contactPerson accountId accountName accountNo address areaId bankName bKashAccountNo branchName cellPhone cityId}
                      model{ 
                        id
                        name 
                        brandId
                        vehicleTypeId
                        brand {
                          id
                          name
                        }
                        vehicleType{
                          id
                          name
                        }
                      } modelYear
                    }
                    invoiceItems {
                      code
                      product{
                        id name code isService category { name }
                      }
                      invoiceId
                      productId
                      id
                      name
                      note
                      measuringUnit
                      productStockItemId
                      active
                      isService
                      quantity
                      salesPrice
                    }
                }`,
                ["id: Int!", "invCode: Int"], { id, invCode }, authData);

            dispatch({ type: "RESPOND_GET_SALES_INVOICE", payload: data.salesInvoice, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SALES_INVOICE", payload: null, error: e });
        }
    },

    requestGetQoutationByNo: (qtNo: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_QOUTATION_BY_NO" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ qoutation: IQoutation }>("qoutation:qoutationByNo(qtNo:$qtNo){ id qtNo vehicleId discountPercent contactPerson vAT tax total qoutationItems{id productId code price title note quantity productStockItemId isService measuringUnit}  vehicle{id modelId modelYear licenseNo chessisNo mileage clientId engineNo client{id name type accountName contactPerson accountNo address areaId bankName accountId bKashAccountNo branchName cellPhone cityId}  model{id brandId name  vehicleTypeId vehicleType{ id name code } brand{id name}}}}", ["$qtNo:Int!"], { qtNo }, authData);
            dispatch({ type: "RESPOND_GET_QOUTATION_BY_NO", payload: data.qoutation, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_QOUTATION_BY_NO", payload: null, error: e });
        }
    },

    requestGetInvoicePayments: (page: number, pageSize: number,
        sortKey: string, ascending: boolean, filterKeys: string, filterValues: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
            const appState = getState();
            try {
                dispatch({ type: "REQUEST_GET_INVOICE_PAYMENTS" });
                const authData = appState.authorization!.authData;

                const data = await graphQl.executeQuery<{ invoicePayments: IInvoicePaymentWrapper }>("invoicePayments (page:$page, pageSize:$pageSize,sortKey:$sortKey,ascending:$ascending,filterKeys:$filterKeys,filterValues:$filterValues){ items { id amount createDate voucherNo salesInvoice{ invoiceCode total client{ name id } vehicle{ licenseNo } } } page pageSize totalCount }", ["$page:Int!", "$pageSize:Int!", "$sortKey:String!", "$ascending:Boolean!", "$filterKeys:String!", "$filterValues:String!"], { page, pageSize, sortKey, ascending, filterKeys, filterValues }, authData);
                dispatch({ type: "RESPOND_GET_INVOICE_PAYMENTS", payload: data.invoicePayments, error: null });

            } catch (e) {
                dispatch({ type: "RESPOND_GET_INVOICE_PAYMENTS", payload: null, error: e });
            }
        },

    requestGetQoutation: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_QOUTATION" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ qoutation: IQoutation }>("qoutation(id:$id){ id qtNo vehicleId discountPercent contactPerson total vAT tax qoutationItems{id code price title  quantity isService note productId measuringUnit} createDate vehicle{id modelId modelYear licenseNo chessisNo clientId mileage engineNo client{id name type accountName accountNo address isCommercial areaId bankName accountId bKashAccountNo branchName cellPhone cityId}  model{id brandId name  vehicleTypeId vehicleType{ id name code } brand{id name}}}}", ["$id:Int!"], { id }, authData);
            dispatch({ type: "RESPOND_GET_QOUTATION", payload: data.qoutation, error: null });
            // dispatch({ type: "RESET" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_QOUTATION", payload: null, error: e });
        }
    },

    requestGetOutsideServices: (invoiceId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_OUTSIDE_SERVICES" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ outsideServices: IOutsideService[] }>("outsideServices(invoiceId:$invoiceId){id services invoiceId supplierId cost payment paymentAccountId supplier{name cellPhone} paymentAccount{id name} invoice{id}}", ["$invoiceId:Int!"], { invoiceId }, authData);
            dispatch({ type: "RESPOND_GET_OUTSIDE_SERVICES", payload: data.outsideServices, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_OUTSIDE_SERVICES", payload: null, error: e });
        }
    },

    requestGetInvoiceStockItems: (invoiceId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_INVOICE_STOCK_ITEMS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ invoiceStockItems: IInvoiceStockItem[] }>("invoiceStockItems(invoiceId:$invoiceId){id productStockItemId salesInvoiceItemId productStockItem{  id productId productModelId costPrice productModel{id name} product{id name} } salesQuantity }", ["$invoiceId:Int!"], { invoiceId }, authData);
            dispatch({ type: "RESPOND_GET_INVOICE_STOCK_ITEMS", payload: data.invoiceStockItems, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_INVOICE_STOCK_ITEMS", payload: null, error: e });
        }
    },

    requestAddOutsideServices: (outsideServices: IOutsideService[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_OUTSIDE_SERVICES" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ outsideServices: IOutsideService[] }>("outsideServices:outsideServiceEntry(outsideServices:$outsideServices){id services invoiceId supplierId cost payment paymentAccountId  paymentAccount{id name}  supplier{name cellPhone} invoice{id}}", ["$outsideServices:[OutsideServiceInputType]!"], { outsideServices }, authData);
            dispatch({ type: "RESPOND_ADD_OUTSIDE_SERVICES", payload: data.outsideServices, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_ADD_OUTSIDE_SERVICES", payload: null, error: e });
        }
    },

    requestAddQoutation: (qoutation: IQoutation): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_QOUTATION" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeMutation<{ addQoutation: IQoutation }>("addQoutation(qoutation:$qoutation){id qtNo vehicleId total}", ["$qoutation:QoutationInputType!"], { qoutation }, authData);

            dispatch({ type: "RESPOND_ADD_QOUTATION", payload: data.addQoutation, error: null });
            dispatch({ type: "RESET" });

        } catch (e) {
            dispatch({ type: "RESPOND_ADD_QOUTATION", payload: null, error: e });
        }
    },

    requestAddSalesInvoice: (vehicle: IVehicleModel, invoiceCp: ISalesInvoice): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const appState = getState();
        try {
            let invoice: ISalesInvoice = cloneDeep(invoiceCp);
            invoice.invoiceItems.map((item) => {
                item.name = undefined;
                item.code = undefined
            });

            await dispatch(VehicleStore.actionCreators.requestAddOrUpdateVehicleAndClient(vehicle) as any);
            invoice.id = invoice.invoiceItems[0].invoiceId;
            invoice.clientId = getState().vehicle.vehicle.clientId;
            invoice.vehicleId = getState().vehicle.vehicle.id;
            invoice.invoiceItems.forEach(i => {
                i.product = undefined;
            })

            dispatch({ type: "REQUEST_ADD_INVOICE" });
            const authData = appState.authorization!.authData;
            var data = null;
            if (invoice.status == 0) {
                data = await graphQl.executeMutation<{ invoice: ISalesInvoice }>("invoice:draftInvoice(invoice:$invoice) { id total vehicleId clientId discount createDate contactPerson invoiceItems { id quantity salesPrice productId   } client { id name}}", [`$invoice:SalesInvoiceInputType!`], { invoice }, authData)
            } if (invoice.status == 1) {
                data = await graphQl.executeMutation<{ invoice: ISalesInvoice }>("invoice:postInvoice(invoice:$invoice) { id total vehicleId clientId discount createDate contactPerson invoiceItems { id quantity salesPrice productId   } client { id name}}", [`$invoice:SalesInvoiceInputType!`], { invoice }, authData)
            }
            dispatch({ type: "RESPOND_ADD_INVOICE", payload: data.invoice, error: null });
            // dispatch({ type: "RESET" });

        } catch (e) {
            dispatch({ type: "RESPOND_ADD_INVOICE", payload: null, error: e });
        }
    },

    requestCostPriceEntry: (productStockItems: Array<IProductStockItemModel>, invoiceId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_COST_PRICE_ENTRY" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ data: any }>("data:costPriceEntry(productStockItems:$productStockItems,invoiceId:$invoiceId) { id costPrice salesPrice }", [`$productStockItems:[ProductStockItemInputType]!`, `$invoiceId:Int!`], { productStockItems, invoiceId }, authData)
            dispatch({ type: "RESPOND_COST_PRICE_ENTRY", payload: data.data, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_COST_PRICE_ENTRY", payload: null, error: e });
        }
    },

    requestAddNewInvoicePayment: (invoicePayment: IInvoicePayment): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_INVOICE_PAYMENT" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ data: IInvoicePayment }>("data:addNewInvoicePayment(invoicePayment:$invoicePayment) { id invoiceId amount voucherNo  }", [`$invoicePayment:InvoicePaymentInputType!`], { invoicePayment }, authData)
            dispatch({ type: "RESPOND_ADD_INVOICE_PAYMENT", payload: data.data, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_INVOICE_PAYMENT", payload: null, error: e });
        }
    },

    requestCancelInvoice: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_CANCEL_INVOICE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<any>("cancelInvoice(id:$id) {id total}", [`$id:Int!`], { id }, authData)
            dispatch({ type: "RESPOND_CANCEL_INVOICE", payload: data, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_CANCEL_INVOICE", payload: null, error: e });
        }
    },

    resetState: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "RESET" });
    },

    resetInvoiceState: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "RESET_INVOICE" });
    },

    removeSalesInvoice: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "REMOVE_INVOICES" });
    }
}


const unloadedState: IQoutationState = {
    isItemSearching: false,
    qoutationItems: null,
    qoutation: null,
    isQoutationPosting: false,
    isPosted: false,
    qoutations: null,
    salesInvoices: null,
    invoicePayments: [],
    isResetQtSearch: false,
    invoiceAdded: false,
    isQoutationLoading: false,
    isInvoiceLoading: false,
    jobCard: null,
    costPriceAdded: false,
    outsideServiceAdded: false,
    invoiceSubmitting: false,
    invoicedFailed: false,
    outsideServices: null,
    invoicePayment: null,
    invoiceStockItems: null,
    page: 1,
    pageSize: 10,
    totalCount: 0,
    isInvoiceCanceling: false,
    isInvoiceCanceled: false
}

export const reducer: Reducer<IQoutationState> = (state: IQoutationState | undefined, incomingAction: Action): IQoutationState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_GET_QOUTATION_ITEMS":
            return {
                ...state,
                isItemSearching: true
            }
        case "RESPOND_GET_QOUTATION_ITEMS":
            return {
                ...state,
                isItemSearching: true,
                qoutationItems: action.payload
            }
        case "REQUEST_GET_QOUTATIONS":
            return {
                ...state,
                isQoutationLoading: true
            }

        case "RESPOND_GET_QOUTATIONS":
            return {
                ...state,
                qoutations: action.payload,
                isQoutationLoading: false
            }

        case "REQUEST_ADD_INVOICE":
            return {
                ...state,
                invoiceSubmitting: true,
                invoiceAdded: false,
                invoicedFailed: false
            }

        case "RESPOND_ADD_INVOICE":
            return {
                ...state,
                invoiceAdded: action.payload !== null,
                salesInvoice: action.payload,
                invoiceSubmitting: false,
                invoicedFailed: action.error !== null
            }
        case "REQUEST_CANCEL_INVOICE":
            return {
                ...state,
                isInvoiceCanceling: true
            }

        case "RESPOND_CANCEL_INVOICE":
           
            return {
                ...state,
                isInvoiceCanceling: false,
                isInvoiceCanceled: action.payload!==null
            }

        case "REQUEST_GET_QOUTATION_BY_NO":
            return {
                ...state,
            }

        case "RESPOND_GET_QOUTATION_BY_NO":
            return {
                ...state,
                qoutation: action.payload,
                isResetQtSearch: action.payload == null
            }

        case "REQUEST_GET_SALES_INVOICES":
            return {
                ...state,
            }

        case "RESPOND_GET_SALES_INVOICES":
            if (action.error) {
                return {
                    ...state
                };
            }
            return {
                ...state,
                salesInvoices: action.payload,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                totalCount: action.payload.totalCount
            }

        case "REQUEST_GET_INVOICE_PAYMENTS":
            return {
                ...state,
            }

        case "RESPOND_GET_INVOICE_PAYMENTS":
            if (action.error) {
                return {
                    ...state
                };
            }
            return {
                ...state,
                invoicePayments: action.payload.items,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                totalCount: action.payload.totalCount
            }

        case "REQUEST_ADD_INVOICE_PAYMENT":
            return {
                ...state,
                invoicePayment: null
            }

        case "RESPOND_ADD_INVOICE_PAYMENT":
            return {
                ...state,
                invoicePayment: action.payload
            }

        case "REQUEST_GET_OUTSIDE_SERVICES":
            return {
                ...state,
            }

        case "RESPOND_GET_OUTSIDE_SERVICES":
            return {
                ...state,
                outsideServices: action.payload
            }

        case "REQUEST_GET_INVOICE_STOCK_ITEMS":
            return {
                ...state,
            }

        case "RESPOND_GET_INVOICE_STOCK_ITEMS":
            return {
                ...state,
                invoiceStockItems: action.payload
            }

        case "REQUEST_ADD_OUTSIDE_SERVICES":
            return {
                ...state,
            }

        case "RESPOND_ADD_OUTSIDE_SERVICES":
            return {
                ...state,
                outsideServices: action.payload,
                outsideServiceAdded: action.payload !== null
            }

        case "REQUEST_GET_SALES_INVOICES":
            return {
                ...state,
            }

        case "REQUEST_COST_PRICE_ENTRY":
            return {
                ...state,
                costPriceAdded: false
            }

        case "RESPOND_COST_PRICE_ENTRY":
            return {
                ...state,
                costPriceAdded: action.payload !== null && action.error == null,
            }

        case "RESPOND_GET_SALES_INVOICE":
            return {
                ...state,
                isInvoiceLoading: false,
                salesInvoice: action.payload
            }

        case "RESPOND_GET_SALES_INVOICE_COMMISSION":
            return {
                ...state,
                isInvoiceLoading: false,
                invoiceCommissions: action.payload
            }

        case "REQUEST_GET_SALES_ITEMS":
            return {
                ...state
            }

        case "RESPOND_GET_SALES_ITEMS":

            return {
                ...state,
                salesItems: action.payload
            }
        case "REQUEST_GET_SALES_ITEMS_INV_CODE":
            return {
                ...state,
                isItemSearching: true
            }

        case "RESPOND_GET_SALES_ITEMS_INV_CODE":

            return {
                ...state,
                salesItems: action.payload,
                isItemSearching: false

            }

        case "REQUEST_GET_QOUTATION":
            return {
                ...state,
                isQoutationLoading: true
            }

        case "RESPOND_GET_QOUTATION":
            return {
                ...state,
                qoutation: action.payload,
                isResetQtSearch: action.payload == null,
                isQoutationLoading: false
            }

        case "REMOVE_INVOICES":
            return {
                ...state,
                salesInvoices: null,
            }

        case "REQUEST_ADD_QOUTATION":
            return {
                ...state,
                isQoutationPosting: true
            }
        case "RESPOND_ADD_QOUTATION":
            return {
                ...state,
                isQoutationPosting: false,
                qoutation: action.payload,
                isPosted: action.payload !== null
            }

        case "RESET_INVOICE":
            return {
                ...state,
                isInvoiceLoading: false,
                salesInvoice: null,
                isInvoiceCanceled:false
            };

        case "RESET":
            return {
                ...state,
                isQoutationPosting: false,
                costPriceAdded: false,
                qoutationItems: null,
                isResetQtSearch: false,
                invoiceAdded: false,
                isInvoiceLoading: false,
                invoiceSubmitting: false,
                isPosted: false,
                salesItems: null,
                outsideServices: null,
                invoiceStockItems: null,
                qoutation: null,
                page: 1,
                pageSize: 10,
                totalCount: 0
            }
        default:
            return state;
    }
}