import * as WeatherForecasts from "./WeatherForecasts";
import * as Authorization from "./Authorization";
import * as Vehicle from "./Vehicle";
import * as Counter from "./Counter";
import * as Product from "./Product";
import * as Inventory from "./Inventory";
import * as Service from "./Service";
import * as Client from "./Client";
import * as Supplier from "./Supplier";
import * as Qoutation from "./Qoutation";
import * as Account from "./Account";
import * as JobCard from "./JobCard";
import * as User from "./User";
import * as OwnersEquity from "./OwnersEquity";
import * as Bank from "./Bank";
import * as Employee from "./Employee";
import * as Commission from "./SalesCommision";
import * as Journal from "./Journal";

import { reducer as toastrReducer } from 'react-redux-toastr'

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    authorization: Authorization.IAuthorizationState | undefined;
    vehicle: Vehicle.IVehicleState | undefined;
    client: Client.IClientState | undefined;
    product: Product.IProductState | undefined;
    inventory: Inventory.IInventoryState | undefined;
    serviceOb: Service.IServiceState | undefined;
    supplier: Supplier.ISupplierState | undefined;
    qoutation: Qoutation.IQoutationState | undefined;
    account: Account.AccountState | undefined;
    user: User.IUserState | undefined;
    jobCard: JobCard.JobCardState | undefined;
    ownersEquity: OwnersEquity.IOwnersEquityState | undefined;
    bank: Bank.IBankState | undefined;
    employee: Employee.IEmployeeState | undefined;
    commission: Commission.ICommissionState | undefined;
    journal: Journal.IJournalState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    authorization: Authorization.reducer,
    vehicle: Vehicle.reducer,
    client: Client.reducer,
    product: Product.reducer,
    inventory: Inventory.reducer,
    serviceOb: Service.reducer,
    supplier: Supplier.reducer,
    qoutation: Qoutation.reducer,
    account: Account.reducer,
    user: User.reducer,
    jobCard: JobCard.reducer,
    ownersEquity: OwnersEquity.reducer,
    bank: Bank.reducer,
    employee: Employee.reducer,
    commission: Commission.reducer,
    journal: Journal.reducer,
    toastr: toastrReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}