import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import { IUserModel } from "../models/UserModel";
import graphQl from "../services/GraphQL";


export interface IUserState {
    user: IUserModel | null,
    users: Array<IUserModel> | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
}


//Request GET actions
export interface RequestGetUsers extends Action<string> {
    type: "REQUEST_GET_USERS";
}

export interface RequestGetUser extends Action<string> {
    type: "REQUEST_GET_USER";
}
//Request ADD actions
export interface RequestAddOrUpdateUser extends Action<string> {
    type: "REQUEST_ADD_UPDATE_USER";
}

export interface RespondGetUsers extends Action<string> {
    type: "RESPOND_GET_USERS";
    payload: Array<IUserModel> | null;
    error: Error | null
}

export interface RespondGetUser extends Action<string> {
    type: "RESPOND_GET_USER";
    payload: IUserModel | null;
    error: Error | null
}



export interface RespondAddOrUpdateUser extends Action<string> {
    type: "RESPOND_ADD_UPDATE_USER";
    payload: IUserModel | null;
    error: Error | null
}
export interface IResetUser extends Action<string> {
    type: "RESET_USER";
}


type KnownAction = RequestGetUsers |
    RequestGetUser |
    RequestAddOrUpdateUser |
    RespondGetUsers |
    RespondGetUser |
    RespondAddOrUpdateUser |
    IResetUser


export const actionCreators = {
    requestAddOrUpdateUser: (userInfo: IUserModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_USER" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeMutation<{ addOrUpdateUserInfo: IUserModel }>("addOrUpdateUserInfo(userInfo:$userInfo){  firstName sex email }", ["$userInfo:UserInputType!"], { userInfo }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_USER", payload: data.addOrUpdateUserInfo, error: null });
            dispatch({ type: "RESET_USER" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_USER", payload: null, error: e });
        }
    },
    requestGetUsers: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_USERS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ users: Array<IUserModel> }>("users{fullName, firstName lastName email address phoneNumber }", [], {}, authData);
            dispatch({ type: "RESPOND_GET_USERS", payload: data.users, error: null });
            dispatch({ type: "RESET_USER" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_USERS", payload: null, error: e });
        }

    }

}

const unloadedState: IUserState = {
    user: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isLoaded: false,
    isPosted: false,
    users: []
}


export const reducer: Reducer<IUserState> = (state: IUserState | undefined, incomingAction: Action): IUserState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_USER":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_ADD_UPDATE_USER":

            var payload = action;
            return {
                ...state,
                user: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_USERS":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_USERS":
            return {
                ...state,
                users: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: false
            }

        case "REQUEST_GET_USER": {
            return {
                ...state,
                isLoading: true
            }
        }
        case "RESPOND_GET_USER": {
            return {
                ...state,
                user: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "RESET_USER":
            {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: false,
                    isPosted: false,
                    isPosting: false,

                }
            }


        default:
            return state;
    }
}
