import * as React from "react";

export interface InputInternalProps { }

export interface TextAreaInternalProps { }

export interface SelectInternalProps {
    items: [{ value: String, text: String }]
}

type InputProps = InputInternalProps & React.InputHTMLAttributes<HTMLInputElement>;
type TextAreaProps = TextAreaInternalProps & React.InputHTMLAttributes<HTMLTextAreaElement>;
type SelectProps = SelectInternalProps & React.InputHTMLAttributes<HTMLSelectElement>;

export function Input(props: InputProps) {

    return <input {...props} />;

}

export function TextArea(props: TextAreaProps) {
    
    return <textarea {...props} />;

}

export function Select(props: SelectProps) {
    const { items, ...restProps } = props;
    return <select {...restProps}>{items.map((o, i) => <option key={i} value={o.value as any}>{o.text}</option>)}</select>;
}