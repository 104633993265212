import * as React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";


import { RouteComponentProps, match } from "react-router-dom";
import * as Quatation from "../../store/Qoutation";
import * as Inventory from "../../store/Inventory";

type actionCreators = typeof Quatation.actionCreators;
type inventoryActionCreators = typeof Inventory.actionCreators;

interface InvoiceReportProps extends RouteComponentProps,
    Quatation.IQoutationState,
    Inventory.IInventoryState,
    actionCreators,
    inventoryActionCreators {
    match: match<{ invoiceId?: string }>
}

interface InvoiceReportState {

}



class InvoiceReport extends React.PureComponent<InvoiceReportProps, InvoiceReportState> {

    componentDidMount() {
        const invoiceId = parseInt(this.props.match.params.invoiceId);

        this.props.requestGetSalesInvoiceCommission(invoiceId);
        this.props.requestGetSalesInvoiceShort(invoiceId);
        this.props.requestGetInvoiceStockItems(invoiceId);
        this.props.requestGetOutsideServices(invoiceId);
        this.props.requestInvoiceSpareItems(invoiceId);
    }



    render() {
        const { outsideServices, invoiceCommissions, invoiceStockItems, salesInvoice, spareProducts } = this.props;

        let totalOutsideServiceCost = 0;
        outsideServices && outsideServices.forEach(item => {
            totalOutsideServiceCost += item.cost;
        });

        let totalProductCost = 0;
        invoiceStockItems && invoiceStockItems.forEach(item => {
            totalProductCost += item.productStockItem && item.productStockItem.costPrice * item.salesQuantity;
        });
        let totalCommissions = 0;
        invoiceCommissions && invoiceCommissions.forEach(item => {
            totalCommissions += item.amount;
        });

        return (
            <div>
                <React.Fragment>
                    <div style={{ position: "relative", marginTop: "80px" }}>
                        <div style={{ textAlign: "center" }}>
                            <h2 style={{ textTransform: "uppercase" }}>Invoice Closing Report</h2>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <hr />
                        </div>

                        <table className="tblInvTop" style={{ float: "right" }}>
                            <tbody>
                                <tr>
                                    <td>DATE : </td>
                                    <td>{salesInvoice && new Date(salesInvoice.createDate).toDateString()}</td>
                                </tr>
                                <tr>
                                    <td>INVOICE NO : </td>
                                    <td> {salesInvoice && salesInvoice.invoiceCode}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div style={{ clear: "both" }}></div>
                        {invoiceStockItems &&
                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Product Costing</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>PRODUCT NAME</th>
                                            <th>MODEL</th>
                                            <th>QUANTITY</th>
                                            <th>COST PRICE</th>
                                            <th>TOTAL AMOUNT (BDT)</th>
                                        </tr>
                                        {invoiceStockItems.map(item => {

                                            return <tr>
                                                <td>{item.productStockItem && item.productStockItem.product && item.productStockItem.product.name}</td>
                                                <td>{item.productStockItem && item.productStockItem.productModel && item.productStockItem.productModel.name}</td>

                                                <td>{item.salesQuantity}</td>
                                                <td>{item.productStockItem && item.productStockItem.costPrice}</td>
                                                <td><NumberFormat value={item.productStockItem && item.productStockItem.costPrice * item.salesQuantity} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                            </tr>
                                        })}
                                        <tr className="tblDataTotal">
                                            <td colSpan={4} style={{ textTransform: "uppercase", textAlign: "right" }}>Total</td>
                                            <td colSpan={2}>
                                                <NumberFormat value={totalProductCost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        }
                        {outsideServices &&
                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Outside Service Costing</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>SUPPLIER NANE</th>
                                            <th>SERVICES </th>
                                            <th>COST</th>
                                            <th>PAYMENT</th>
                                            <th>PAYMENT TYPE</th>
                                        </tr>


                                        {outsideServices.map(item => {
                                            return <tr>


                                                <td style={{ width: "200px" }}>
                                                    <p>{item.supplier && item.supplier.name}</p>
                                                </td>

                                                <td style={{ width: "200px" }}>
                                                    <p>{item.services && item.services}</p>
                                                </td>

                                                <td style={{ width: "200px" }}>
                                                    <p><NumberFormat value={item.cost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></p>
                                                </td>

                                                <td style={{ width: "200px" }}>
                                                    <p><NumberFormat value={item.payment} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></p>

                                                </td>

                                                <td style={{ width: "200px" }}>
                                                    <p> {item.paymentAccount && item.paymentAccount.name}</p>
                                                </td>

                                            </tr>
                                        })
                                        }
                                        <tr className="tblDataTotal">
                                            <td colSpan={3} style={{ textTransform: "uppercase", textAlign: "right" }}>Total</td>
                                            <td colSpan={3}>
                                                <NumberFormat value={totalOutsideServiceCost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        }
                        {invoiceCommissions &&
                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Sales Commission</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>NANE</th>
                                            <th>PHONE </th>
                                            <th>NOTE</th>
                                            <th>AMOUNT</th>
                                        </tr>

                                        {invoiceCommissions.map(item => {
                                            return <tr >
                                                <td>{item.name}</td>
                                                <td>{item.phone}</td>
                                                <td>{item.note}</td>
                                                <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                            </tr>

                                        })}
                                        <tr className="tblDataTotal">
                                            <td colSpan={3} style={{ textTransform: "uppercase", textAlign: "right" }}>Total</td>
                                            <td colSpan={2}>
                                                <NumberFormat value={totalCommissions} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        }
                        {spareProducts &&
                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Spare Products</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>PRODUCT NAME</th>
                                            <th>MODEL</th>
                                            <th>QUANTITY</th> 
                                        </tr>
                                        {spareProducts.map(item => {

                                            return <tr>
                                                <td>{item.product&& item.product.name}</td>
                                                <td>{item.productModel && item.productModel.name}</td>

                                                <td>{item.quantity}</td>
                                              </tr>
                                        })}
                                        <tr className="tblDataTotal">
                                            <td colSpan={2} style={{ textTransform: "uppercase", textAlign: "right" }}>Total</td>
                                            <td colSpan={2}>
                                                <NumberFormat value={totalProductCost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                </React.Fragment>
            </div>
        )
    }
}


export default connect((state: ApplicationState) => {
    return { ...state.qoutation, ...state.inventory }
}, { ...Quatation.actionCreators, ...Inventory.actionCreators })(InvoiceReport as any);