
export const LC_COUNTRY_KEY = "91e1d91f-c005-4184-b160-4176a2066807";
export const LC_KEY = "MTljNGQzYzctMTk0OS00YTgyLThhNzAtMGNlZTc0OGU4NGU1";

export const LC_AUTH_KEY = "4c198ace-bd4a-414d-a752-ded2afa5f6dd";

export const MeasuringUnits = [
    { label: "Set", value: 1 },
    { label: "Pcs", value: 2 },
    { label: "Liter", value: 3 },
    { label: "Feet", value: 4 },
    { label: "Kg", value: 5 },
    { label: "CFT", value: 6 },
    { label: "Set/Pcs", value: 7 },
    { label: "lb", value: 8 },
    { label: "Inch", value: 9 },
]

export const LicenseArea= [
    { label: "--Select--", value: 0 },
    { label: "Dhaka Metro", value: 1 },
    { label: "Khulna Metro", value: 2 },
    { label: "Chatto Metro", value: 3 },
    { label: "B.BARIA", value: 4 },
    { label: "BAGERHAT", value: 5 },
    { label: "BANDARBAN", value: 6 },
    { label: "BARGUNA", value: 7 },
    { label: "BARISAL", value: 8 },
    { label: "BARISAL METRO", value: 9 },
    { label: "BHOLA", value: 10 },
    { label: "BOGRA", value: 11 },
    { label: "CHANDPUR", value: 12 },
    { label: "CHATTA METRO", value: 13 },
    { label: "CHITTAGONG", value: 14 },
    { label: "CHUADANGA", value: 15 },
    { label: "COMILLA", value: 16 },
    { label: "COXSBAZAR", value: 17 },
    { label: "DHAKA", value: 18 },
    { label: "DINAJPUR", value: 19 },
    { label: "FENI", value: 20 },
    { label: "GAIBANDA", value: 21 },
    { label: "GAIBANDHA", value: 22 },
    { label: "GAZIPUR", value: 23 },
    { label: "GOPALGANJ", value: 25 },
    { label: "HABIGONG", value: 26 },
    { label: "JAIPURHAT", value: 27 },
    { label: "JAMALPUR", value: 28 },
    { label: "JESSORE", value: 29 },
    { label: "JHALAKATI", value: 30 },
    { label: "JHENIDAH", value: 31 },
    { label: "JHENIDHA", value: 32 },
    { label: "KHAGRACHARI", value: 33 },
    { label: "KHULNA", value: 34 },
    { label: "KHULNA METRO", value: 35 },
    { label: "KISHOREGANJ", value: 36 },
    { label: "KURIGRAM", value: 37 },
    { label: "KUSHTIA", value: 38 },
    { label: "LAKSHMIPUR", value: 39 },
    { label: "LAXMIPUR", value: 40 },
    { label: "LALMONIRHAT", value: 41 },
    { label: "MADARIPUR", value: 42 },
    { label: "MAGURA", value: 43 },
    { label: "MANIKGANJ", value: 44 },
    { label: "MEHERPUR", value: 45 },
    { label: "MOULAVIBAZAR", value: 46 },
    { label: "MYMENSINGH", value: 47 },
    { label: "MUNSHIGANJ", value: 48 },
    { label: "NAOGAON", value: 49 },
    { label: "NARAIL", value: 50 },
    { label: "NARAYANGANJ", value: 51 },
    { label: "NARSINGDI", value: 52 },
    { label: "NATOR", value: 53 },
    { label: "NATORE", value: 54 },
    { label: "NAWABGANJ", value: 55 },
    { label: "NETRAKONA", value: 56 },
    { label: "NILPHAMARI", value: 57 },
    { label: "NOAKHALI", value: 58 },
    { label: "PABNA", value: 59 },
    { label: "PANCHAGAR", value: 60 },
    { label: "PATUAKHALI", value: 61 },
    { label: "PIROJPUR", value: 62 },
    { label: "RAJBARI", value: 63 },
    { label: "RAJ METRO", value: 64 },
    { label: "RAJSHAHI", value: 65 },
    { label: "RANGAMATI", value: 66 },
    { label: "RANGPUR", value: 67 },
    { label: "SATKHIRA", value: 68 },
    { label: "SHARIATPUR", value: 69 },
    { label: "SHERPUR", value: 70 },
    { label: "SIRAJGONG", value: 71 },
    { label: "SIRAJGONJ", value: 72 },
    { label: "SUNAMGONJ", value: 73 },
    { label: "SYLHET", value: 74 },
    { label: "SHERPUR", value: 75 },
    { label: "SYLHET METRO", value: 76 },
    { label: "TANGAIL", value: 77 },
    { label: "THAKURGAON", value: 78 },
];

export const LicenseCode = [
    { label: "--Select--", value: 0 },
    { label: "A", value: 1 },
    { label: "AU", value: 2 },
    { label: "BA", value: 3 },
    { label: "BHA", value: 4 },
    { label: "CAA", value: 5 },
    { label: "CHA", value: 6 },
    { label: "DA", value: 7 },
    { label: "DAW", value: 8 },
    { label: "DHA", value: 9 },
    { label: "E", value: 10 },
    { label: "FA", value: 11 },
    { label: "GA", value: 12 },
    { label: "GHA", value: 13 },
    { label: "HA", value: 14 },
    { label: "JA", value: 15 },
    { label: "JHA", value: 16 },
    { label: "KA", value: 17 },
    { label: "KHA", value: 18 },
    { label: "LA", value: 19 },
    { label: "MA", value: 20 },
    { label: "NA", value: 21 },
    { label: "PA", value: 22 },
    { label: "RA", value: 23 },
    { label: "SA", value: 24 },
    { label: "SHA", value: 25 },
    { label: "TA", value: 26 },
    { label: "TAW", value: 27 },
    { label: "THA", value: 28 },
    { label: "U", value: 29 },
    { label: "WUA", value: 30 },
    { label: "ZA", value: 31 }
];
