import * as React from "react";
import { Container } from "reactstrap";
import routes from "../../routes/routes.default";
import { RouteLooper } from "../common/RouteLooper";

export default class DefaultLayout extends React.PureComponent {

    render() {
      
        return (
            <React.Fragment>
                <Container>
                    <RouteLooper routes={routes} />
                </Container>
            </React.Fragment>

        );
    }
}