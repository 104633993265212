import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import { IServiceCategoryModel, IServiceModel } from "../models/ServiceModel";
import graphQl from "../services/GraphQL";


export interface IServiceState {
    service: IServiceModel | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    productServiceCategories: Array<IServiceCategoryModel> | null,
    services: Array<IServiceModel> | null;
}


//Request GET actions
export interface RequestGetServices extends Action<string> {
    type: "REQUEST_GET_SERVICES";
}

export interface RequestGetProductServiceCategories extends Action<string> {
    type: "REQUEST_GET_PRODUCT_SERVICE_CATEGORIES";
}

export interface RequestGetService extends Action<string> {
    type: "REQUEST_GET_SERVICE";
}
//Request ADD actions
export interface RequestAddOrUpdateService extends Action<string> {
    type: "REQUEST_ADD_UPDATE_SERVICE";
}

export interface RequestGetProductServiceCategory extends Action<string> {
    type: "REQUEST_GET_PRODUCT_SERVICE_CATEGORY";
}

export interface RespondGetProductServiceCategories extends Action<string> {
    type: "RESPOND_GET_PRODUCT_SERVICE_CATEGORIES";
    payload: Array<IServiceCategoryModel> | null;
    error: Error | null
}

export interface RespondGetServices extends Action<string> {
    type: "RESPOND_GET_SERVICE_MODELS";
    payload: Array<IServiceModel> | null;
    error: Error | null
}

export interface RespondGetService extends Action<string> {
    type: "RESPOND_GET_SERVICE";
    payload: IServiceModel | null;
    error: Error | null
}

export interface RespondGetProductServiceCategory extends Action<string> {
    type: "RESPOND_GET_PRODUCT_CATEGORY";
    payload: IServiceCategoryModel | null;
    error: Error | null
}

export interface RespondAddOrUpdateService extends Action<string> {
    type: "RESPOND_ADD_UPDATE_SERVICE";
    payload: IServiceModel | null;
    error: Error | null;
}

export interface IResetService extends Action<string> {
    type: "RESET_SERVICE";
}

export interface IRequestGetFilterServices {
    type:"REQUEST_GET_FILTER_SERVICES"
}

export interface IRespondGetFilterServices {
    type: "RESPOND_GET_FILTER_SERVICE_MODELS",
    payload: Array<IServiceModel> | null;
    error:Error|null;
}




type KnownAction = RequestGetServices |
    RequestGetProductServiceCategories |
    RequestGetService |
    RequestAddOrUpdateService |
    RequestGetProductServiceCategory |
    RespondGetProductServiceCategories |
    RespondGetServices |
    RespondGetProductServiceCategory |
    RespondAddOrUpdateService |
    RespondGetService |
    IResetService | IRequestGetFilterServices | IRespondGetFilterServices

export const actionCreators = {
    requestAddOrUpdateService: (serviceInfo: IServiceModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_SERVICE" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeMutation<{ addOrUpdateService: IServiceModel }>("addOrUpdateService(serviceInfo:$serviceInfo){ serviceId name code serviceCategoryId }", ["serviceInfo:ServiceInputType!"], { serviceInfo }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_SERVICE", payload: data.addOrUpdateService, error: null });
            dispatch({ type: "RESET_SERVICE" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_SERVICE", payload: null, error: e });
        }
    },

    requestGetProductServiceCategories: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCT_SERVICE_CATEGORIES" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ serviceCategories: Array<IServiceCategoryModel> }>("serviceCategories{ id name code }", [], {}, authData);
            dispatch({ type: "RESPOND_GET_PRODUCT_SERVICE_CATEGORIES", payload: data.serviceCategories, error: null });
            dispatch({ type: "RESET_SERVICE" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCT_SERVICE_CATEGORIES", payload: null, error: e });
        }
    },

    requestGetServices: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SERVICES" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ services: Array<IServiceModel> }>("services{  name code serviceCategory{ name }}", [], {}, authData);
            dispatch({ type: "RESPOND_GET_SERVICE_MODELS", payload: data.services, error: null });
            dispatch({ type: "RESET_SERVICE" });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SERVICE_MODELS", payload: null, error: e });
        }

    },

    requestServiceFilter: (searchString:string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_FILTER_SERVICES" });
            const authData = appState.authorization!.authData;
            


            const data = await graphQl.executeQuery<{ filterServices: Array<IServiceModel> }>("filterServices(filter:$filter){ id name code serviceCategory{ name } categoryId}", ["$filter:String!"], { filter: searchString}, authData);
            dispatch({ type: "RESPOND_GET_FILTER_SERVICE_MODELS", payload: data.filterServices, error: null });
            dispatch({ type: "RESET_SERVICE" });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_FILTER_SERVICE_MODELS", payload: null, error: e });
        }

    }

}

const unloadedState: IServiceState = {
    service: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isPosted: false,
    productServiceCategories: null,
    services: null,
    isLoaded: false
}


export const reducer: Reducer<IServiceState> = (state: IServiceState | undefined, incomingAction: Action): IServiceState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_SERVICE":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_ADD_UPDATE_SERVICE":

            var payload = action;
            return {
                ...state,
                service: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_SERVICE":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_SERVICE_MODELS":
            return {
                ...state,
                services: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: false
            }

        case "REQUEST_GET_SERVICES": {
            return {
                ...state,
                isLoading: true
            }
        }
        case "RESPOND_GET_SERVICE": {
            return {
                ...state,
                service: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }

        case "REQUEST_GET_PRODUCT_SERVICE_CATEGORIES": {
            return {
                ...state,
                isLoading: true
            }
        }
        case "RESPOND_GET_PRODUCT_SERVICE_CATEGORIES": {
            var actions = action;
            return {
                ...state,
                productServiceCategories: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }
        }

        case "REQUEST_GET_FILTER_SERVICES":
        {
                return {
                    ...state,
                    isLoading:true
                }
            }

        case "RESPOND_GET_FILTER_SERVICE_MODELS":
        {
                return {
                    ...state,
                    services: action.payload,
                    isLoading: false,
                    isLoaded:true
                }
        }
        case "RESET_SERVICE":
        {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: false,
                    isPosted: false,
                    isPosting: false,
                    
                }
        }

        default:
            return state;
    }
}
