import * as React from "react";
import { History, LocationState } from "history";
import AdminNavbar from "../Navbars/AdminNavbar";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import routes from "../../routes/routes.authorized";
import { RouteLooper } from "../common/RouteLooper";
import { IRoute } from "../../models/RouteModel";
import image from "../../assets/img/sidebar-3.jpg";
import cloneDeep from "lodash/cloneDeep";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import * as Login from "../../store/Authorization";

export interface IAuthorizedLayoutProps {
    location: Location,
    history: History<LocationState>,
}

export interface AuthorizedLayoutState {
    image: any,
    color: string,
    hasImage: boolean,
    routes: IRoute[]
}

type AuthorizedLayoutProps = IAuthorizedLayoutProps & Login.AuthorizationState;

class AuthorizedLayout extends React.PureComponent<AuthorizedLayoutProps, AuthorizedLayoutState> {

    filterRoutes = (routes) => {

        return routes.filter(r => {
            const canAdd = (!r.roles) || (r.roles.some(b => this.props.authData.roles.includes(b)));
            if (canAdd && r.children) {
                r.children = this.filterRoutes(r.children);
            }
            return canAdd;
        });
    };
    
    state = {
        image: image,
        color: "black",
        hasImage: true,
        routes: this.filterRoutes(cloneDeep(routes))
    };
    
    private mainPanel = React.createRef<HTMLDivElement>();

    getBrandText(path: string, routes: IRoute[]): string {
        for (let i = 0; i < routes.length; i++) {
            const r = routes[i];
            if (r.component == null && r.children!.length > 0) {
                const title = this.getBrandText(path, r.children!);
                if (title === "Home") {
                    continue;
                }
                return title;
            }
            if (path.indexOf(routes[i].path) !== -1) {
                return routes[i].title as string;
            }
        }
        return "Home";
    };

    componentDidUpdate(e: AuthorizedLayoutProps) {
        if (
            window.innerWidth < 993 &&
                e.history.location.pathname !== e.location.pathname &&
                document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
        if (e.history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement!.scrollTop = 0;
            this.mainPanel.current!.scrollTop = 0;
        }
    }

    render(): any {
        return (
            <div className="wrapper">
                {/*<NotificationSystem ref="notificationSystem" style={style} />*/}
                <Sidebar {...this.props} routes={this.state.routes} image={this.state.image} color={this.state.color} hasImage={this.state.hasImage} />

                <div id="main-panel" className="main-panel" ref={this.mainPanel} >

                    <div style={{ "minHeight": `${window.innerHeight - 62}px` }}>

                        <AdminNavbar {...this.props} brandText={this.getBrandText(this.props.location.pathname, this.state.routes)} />

                        <React.Suspense fallback={<div>Loading...</div>}>
                            <RouteLooper routes={this.state.routes} />
                        </React.Suspense>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {...state.authorization}
    }, {...Login.actionCreators}
)(AuthorizedLayout as any);