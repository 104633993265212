import * as React from "react";
import { NavLink } from "react-router-dom";
import { Collapse, CardBody, Card } from "reactstrap";
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks";
import logo from "../../assets/img/xeon-logo.png";
import { IRoute } from "../../models/RouteModel";
import "../../assets/css/custom.css";

import { Link, Redirect } from "react-router-dom";


export interface SidebarProps {
    location?: Location,
    image?: any,
    hasImage?: boolean,
    color?: string,
    routes?: IRoute[],
}

export interface SidebarState {
    inWidth: number,
    opener: Object,
}

class Sidebar extends React.PureComponent<SidebarProps, SidebarState> {

    state = {
        inWidth: window.innerWidth as number,
        opener: {}
    };

    activeRoute(routeName: string | string[]) {
        if (typeof routeName === "string") {
            return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
        } else {
            return routeName.some(((r: string) => this.props.location.pathname.indexOf(r) > -1) as any) ? "active" : "";
        }
    }

    updateDimensions() {
        this.setState({ inWidth: window.innerWidth as number });
    }
    componentDidMount() {
        this.updateDimensions = this.updateDimensions.bind(this);
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    toggleOpen(path: string) {

        const st = { ...this.state.opener };
        Object.keys(st).filter(a => a !== path).map(a => st[a] = false);
        st[path] = !(st[path] || false);
        this.setState({ opener: st });
    }

    getRouteLink(prop: IRoute, key: number): any {
        if (prop.component == null && prop.children.length > 0) {
            return (
                <li className={this.activeRoute(prop.children.map(a => a.path))} key={key}>

                    <NavLink to="#" className="nav-link" onClick={() => this.toggleOpen(prop.title as string)} activeClassName="active" style={{ marginBottom: '1rem' }}>
                        <i className={prop.icon as string} />
                        <p>{prop.title as string}</p>
                    </NavLink>

                    <Collapse isOpen={this.state.opener[prop.title as string] || false}>
                        <Card style={{ "backgroundColor": "transparent", "marginBottom": "0" }}>
                            <CardBody style={{ "padding": "0" }}>
                                <ul className="nav" style={{ "marginTop": "0" }}>
                                    {this.getRouteLinks(prop.children)}
                                </ul>
                            </CardBody>
                        </Card>

                    </Collapse>
                </li>
            );
        }
        return (
            <li className={this.activeRoute(prop.path)} key={key}>

                <NavLink to={prop.path} className="nav-link" activeClassName="active">
                    <i className={prop.icon as string} />
                    <p>{prop.title as string}</p>
                </NavLink>
            </li>
        );
    }

    getRouteLinks(routes: IRoute[]) {
        return routes.filter(a => a.isLink && !a.redirect).map((key, value) => this.getRouteLink(key, value));
    }

    render() {
        const sidebarBackground = {
          //  backgroundImage: `url(${this.props.image})`
            backgroundColor:'#000'
        };
        return (
            <div
                id="sidebar"
                className="sidebar"
                data-color={this.props.color}
                data-image={this.props.image}
            >
               
                {this.props.hasImage ? (<div className="sidebar-background" style={sidebarBackground} />) : (null)}
                <div className="logo">
                    <Link
                        to="/"
                        className="simple-text logo-mini"
                    >
                        <div className="logo-img">
                            <img src={logo} alt="logo_image" />
                        </div>
                    </Link>
                 
                   
                </div>
               
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        {this.state.inWidth <= 991 ? <AdminNavbarLinks /> : null}
                        {this.getRouteLinks(this.props.routes)}
                        
                    </ul>
                </div>
            </div>
        );
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateDimensions);
    }
}

export default Sidebar;
