import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Login from "../store/Authorization"
import {ApplicationState} from "../store";
import * as Client from "../store/Client";
import * as Account from "../store/Account";

// ReSharper disable once InconsistentNaming
const Authorization = (allowedRoles: any) : any =>

    (WrappedComponent: any=undefined, showMessage = true) : any => {
        class WithAuthorization extends React.Component<any, any> {

            constructor(props) {
                super(props);
                //props.requestGetMyRole();
            }

            render() {
                const { authData, component } = this.props;
                if (typeof WrappedComponent === "undefined") {
                    WrappedComponent = component;
                }
                if (typeof this.props.showMessage !== "undefined") {
                    showMessage = this.props.showMessage;
                }
                if (allowedRoles.some(a => authData.roles.includes(a))) {
                    return typeof WrappedComponent === "function" || WrappedComponent["$$typeof"].toString() === "Symbol(react.lazy)" || WrappedComponent["$$typeof"].toString() === "Symbol(react.memo)" ? <WrappedComponent {...this.props} /> : WrappedComponent;
                } else {
                    if (this.props.showText) {
                        return <div>{this.props.showText}</div>;
                    }
                    return showMessage ? <h1>Access is denied for the current user!</h1> : null;
                }
            }
        }

        return connect(
            (state: ApplicationState) => {
                return {...state.authorization}
            }, {...Login.actionCreators}
        )(WithAuthorization as any);
        
    }


export const SuperAdmin = Authorization(["super_admin"]);

export const Admin = Authorization(["super_admin", "admin"]);

export const AdminStore = Authorization(["super_admin", "admin", "admin_st"]);

export const AdminOperations = Authorization(["super_admin", "admin", "admin_op"]);

export const AdminAccounts = Authorization(["super_admin", "admin", "admin_ac"]);
export const ExecutiveAccounts = Authorization(["super_admin", "admin", "admin_ac", "executive_ac"]);

export const AccountPlusOperations = Authorization(["super_admin", "admin", "admin_ac", "admin_op", "executive_ac"]);

export const AccountsExclude = Authorization(["super_admin", "admin", "admin_st", "admin_op"]);


export const Components = {
    get AdminOperationsComponent(){
        return AdminOperations();
    },
    get AdminStoreComponent(){
        return AdminStore();
    }
};