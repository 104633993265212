import * as React from "react";
import {
    Grid,
    Row,
    Col,
    InputGroup,
    Button, FormControl, Table, Radio
} from "react-bootstrap";
import { Card } from "../../Card/Card"; import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import "../../../assets/css/viewStock.css";
import * as Qoutation from "../../../store/Qoutation";
import * as JobCard from "../../../store/JobCard";
import * as Inventory from "../../../store/Inventory";
import "../../../assets/css/jobcard/add-job-card.css";
import { IProductStockItemModel, ProductStockItemModel, IProductStockItemCount } from "../../../models/ProductStock";
import { Redirect } from "react-router";
import * as Account from "../../../store/Account";
import { toastr } from "react-redux-toastr";
import { Input } from "reactstrap";
import Select from "react-select";
import { IQoutationItem } from "../../../models/QoutationItem";
import { IProductBrand, IProductModelModel, IProductModel } from "../../../models/ProductModel";
import { IJobCartItem, IJobCardStockItem, IJobCard } from "../../../models/Qoutation";
import { RouteComponentProps } from "react-router";
import cloneDeep from 'lodash/cloneDeep';
import { ISalesInvoice } from "../../../models/SalesInvoice";
import { stringify } from "querystring";

interface INewJobCardState {
    jobCardNo: string;
    productStockItems: Array<IProductStockItemModel>;
    productWithModelStockCounts: Array<IProductStockItemCount>;
    jobCardItems: Array<IJobCartItem>;
    invoiceId: number;
    status: number;
    jobCardId: number;
    type: number;
}

interface INewJobCardProps {

}

interface INewJobCardDropdown {
    label: string,
    value: number;

}

type InvProps = RouteComponentProps & INewJobCardProps & JobCard.JobCardState & typeof JobCard.actionCreators & Inventory.IInventoryState & typeof Inventory.actionCreators & Qoutation.IQoutationState & typeof Qoutation.actionCreators & typeof Account.actionCreators;

class NewJobCard extends React.PureComponent<InvProps, INewJobCardState> {

    constructor(props: any) {
        super(props);
        this.state = {
            jobCardId: 0,
            jobCardNo: null,
            productStockItems: null,
            productWithModelStockCounts: [],
            jobCardItems: [],
            invoiceId: 0,
            status: 0,
            type: -1
        }
    }

    componentWillReceiveProps(nextProps: InvProps) {

        this._addOrUpdateStockItemCount(nextProps.productStockItemCountByModel)

        if (nextProps.jobCardItems && nextProps.jobCardItems.length > 0) {
            if (this.state.jobCardId !== nextProps.jobCardItems[0].jobCardId) {
                this._bindJobCardItems(nextProps.jobCardItems)
            }
        }
    }

    _bindJobCardItems = (jobCardItems: IJobCartItem[]) => {
        if (jobCardItems.length > 0) {

            this.setState(st => {
                return {
                    jobCardItems,
                    invoiceId: jobCardItems[0].jobCard.invoiceId,
                    jobCardNo: jobCardItems[0].jobCard.jobCardNo,
                    status: jobCardItems[0].jobCard.status,
                    jobCardId: jobCardItems[0].jobCardId
                }
            })
        }
    }

    _addOrUpdateStockItemCount(stockItemCount: IProductStockItemCount) {

        let exStockItemCount = this.state.productWithModelStockCounts && stockItemCount && this.state.productWithModelStockCounts.find(i => i.productId == stockItemCount.productId && i.productModelId == stockItemCount.productModelId)
        let counts: Array<IProductStockItemCount> = this.state.productWithModelStockCounts || [];
        if ((exStockItemCount == null || exStockItemCount == undefined) && stockItemCount !== null) {
            counts.push(stockItemCount);
            this.setState(st => {
                return { productWithModelStockCounts: counts }
            });
        } else if (exStockItemCount) {
            this.setState(st => {
                var modelStockCounts = [...st.productWithModelStockCounts];
                if (modelStockCounts !== null) {
                    var item = modelStockCounts.find(i => i.productId == stockItemCount.productId && i.productModelId == stockItemCount.productModelId);
                    item.count = stockItemCount.count;
                }

                return { productWithModelStockCounts: modelStockCounts };
            });
        }
    }

    componentWillMount() {
        let params = new URLSearchParams(this.props.location.search);
        var jobCardId = parseInt(params.get("jobCardId"));
        this.props.requestGetJobCardItems(jobCardId)
    }

    componentDidMount() {
        window.addEventListener("focus", this.focusHandler, false);
    }

    focusHandler = () => {
        this.state.jobCardItems && this.state.jobCardItems.forEach(item => {
            item.productModelId > 0 && this.props.requestGetProductStockItemCountByModel(item.productId, item.productModelId);
        })
    }

    _getStockItemCount(productId: number, productModelId: number): IProductStockItemCount {
        if (productId === null || productModelId === null) return null
        return this.state.productWithModelStockCounts && this.state.productWithModelStockCounts.find(i => i.productId == productId && i.productModelId == productModelId);
    }

    _updateItem(index: number, name: string, value: any) {

        if (name === "productModelId" && value) {
            //let exItem = this.state.jobCardItems ? this.state.jobCardItems.find(i => i.productModelId == value) : null;
            //if (exItem) return null
        }

        this.setState(st => {
            var jobCardItems = [...st.jobCardItems];
            if (jobCardItems !== null) {
                var item = jobCardItems[index];
                item[name] = value;
            }

            return { jobCardItems };
        });

    }


    removeItem = (position: number): void => {
        var items = this.state.jobCardItems.slice();

        if (items !== null) {
            items.splice(position, 1)
            this.setState({ jobCardItems: items })
        }

        this.forceUpdate();

    }

    _hasItemWithZeroQuantity = (productId: number, modelId: number): boolean => {

        let jobCardItems = this.state.jobCardItems;
        let item = jobCardItems && jobCardItems.find(i => i.productId == productId && i.productModelId == modelId);

        if (item && item.quantity <= 0) {
            return true;
        }
        return false;
    }


    _renderJobCardItems(): any {
        var items = this.state.jobCardItems;
        if (this.state.type == 1) {
            items = items.filter(x => x.id > 0)
        }
        var renderedItems = items && items.map((item: IJobCartItem, index: number) => {
            let models = item.product && item.product.productModels;
            let stkItemCn = this._getStockItemCount(item.productId, item.productModelId);
            let avlQty = stkItemCn ? stkItemCn.count : 0;

            return (<tr key={item.id} >
                <td>
                    {
                        item.id == 0 &&
                        <p onClick={() => this.removeItem(index)} style={{ fontSize: "14px", color: "red" }}>Remove</p>
                    }
                </td>

                <td>
                    {item.product && item.product.name}<br />
                    {<p style={{ fontSize: "14px", color: "red" }}>{`[#${item && item.product.code}]`}</p>}
                </td>

                <td style={{ minWidth: "100px" }}>
                    <select className='form-control' style={{ border: "1px solid #9d9fc4", outline: 0 }}
                        disabled={item.id !== 0}
                        value={item.productModelId}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {

                            let modelId = e.target.value == "" ? 0 : parseInt(e.target.value);

                            modelId !== 0 && this.props.requestGetProductStockItemCountByModel(item.productId, modelId)
                            this._hasItemWithZeroQuantity(item.productId, modelId) == false && this._updateItem(index, "productModelId", modelId);


                        }}>

                        <option value={0}>--Model--</option>
                        {models && models.map(i => <option value={i.id}>{i.name}</option>)}
                    </select>
                    {item.id == 0 && item.productModelId > 0 && <p style={{ fontSize: "14px", color: "red" }}>{`Avail-Qty: [${avlQty}]`}</p>}

                </td>

                <td style={{ width: "100px" }}>
                    <Input className='form-control' type='text' style={{ border: "1px solid #9d9fc4", outline: 0 }}
                        value={item.quantity}
                        disabled={item.id !== 0}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let qty = e.target.value == "" ? 0 : parseInt(e.target.value)
                            if (item.productModelId > 0) {
                                this._updateItem(index, "quantity", qty);
                            }
                        }}
                    />
                </td>

                <td style={{ width: "100px" }}>
                    <Input className='form-control' type='text' style={{ border: "1px solid #9d9fc4", outline: 0 }}
                        value={item.returnQuantity }
                        disabled={this.state.status == 1 || this.state.type == 0 || this.state.type == -1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let qty = e.target.value == "" ? 0 : parseInt(e.target.value)

                            if (item.productModelId > 0 && item.quantity >= qty && this.state.type == 1) {
                                this._updateItem(index, "returnQuantity", qty);
                            }
                        }}
                    />
                </td>

                <td style={{ width: "200px" }}>
                    <Input className='form-control' type='text' style={{ border: "1px solid #9d9fc4", outline: 0 }}
                        value={item.note}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            let note = e.target.value == "" ? "" : e.target.value

                            if (item.productModelId > 0) {
                                this._updateItem(index, "note", note);
                            }
                        }}
                    />
                </td>

                <td style={{ width: "120px" }}>
                    {
                        item.id == 0 && avlQty < item.quantity &&
                        <a href="/inventory/add-Inventory" target="_blank">Add Stock</a>
                    }
                </td>
            </tr>);
        })


        return renderedItems;
    }

    _handleProductSearch = (keyword: string) => {
        this.props.requestGetProducts(keyword);
    }

    _handleOnProductItemSelect = (productId: number) => {
        if (!this.isProductAlreadyInState(productId, 0) && this.state.type == 0) {
            this.setState(st => {
                var jobCardItems = [...st.jobCardItems];
                jobCardItems.push(this._createJobCartItem(productId, 0))

                return { jobCardItems };
            });
        }
    }

    _getProduct(productId): IProductModel {
        var product = this.props.products ? this.props.products.find(i => i.id == productId) : null;
        return product
    }

    _createJobCartItem(productId: number, modelId: number): IJobCartItem {
        let product = this._getProduct(productId);
        return {
            id: 0,
            productId: productId,
            productModelId: modelId,
            quantity: 0,
            returnQuantity: 0,
            jobCardId: 0,
            note: "",
            product
        }
    }


    isProductAlreadyInState(productId: number, productModelId: number): boolean {

        let jobCardItems = this.state.jobCardItems;
        let item = jobCardItems && jobCardItems.find(i => i.productId == productId && i.productModelId == productModelId);

        if (item) {
            return true;
        }
        return false;
    }

    renderProducts(): any {
        var items = [];
        this.props.products && this.props.products.filter(x => !x.isService).map((item: IProductModel) => {
            items.push({
                label: "[#" + item.code + "] " + item.name,
                value: item.id
            })
        })
        return items;
    }

    renderInvoices(): any {
        var items = [];
        const { salesInvoices } = this.props;
        salesInvoices && salesInvoices.items.filter(x => x.status==1).length > 0 && salesInvoices.items.map((item: ISalesInvoice) => {
            items.push({
                label: "[#" + item.invoiceCode + "] ",
                value: item.id
            })
        })
        return items;
    }


    renderProductBrands(): any {
        var items = [];
        this.props.productBrands && this.props.productBrands.map((item: IProductBrand) => {
            items.push({
                label: item.name,
                value: item.id
            })
        })
        return items;
    }


    renderProductModels(): any {
        var items = [];
        this.props.productModels && this.props.productModels.map((item: IProductModelModel) => {
            items.push({
                label: item.name,
                value: item.id
            })
        })
        return items;
    }

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (this._isItValidForm()) {
            if (this.state.type == 0) {
                this.props.requestSubmitJobCard(this._getJobCard())
            } else {
                this.props.requestReturnPostJobCardItems(null, this._getJobCardItems().filter(x => x.id > 0))
            }
        }
    }

    _getJobCard = (): IJobCard => {

        let jobCard: IJobCard = {
            id: this.state.jobCardId,
            jobCardNo: this.state.jobCardNo,
            jobCardItems: this._getJobCardItems(),
            invoiceId: this.state.invoiceId,
            status: this.state.status
        }

        return jobCard;
    }

    _getJobCardItems = (): Array<IJobCartItem> => {
        let jobCardItems = cloneDeep((this.state.jobCardItems || []));
        if (jobCardItems) {
            jobCardItems.forEach(i => {
                i.product = undefined
                i.productModel = undefined
                i.jobCard = undefined
            })
        }
        return jobCardItems;
    }


    _isAllJobCardItemValid = (): boolean => {
        var jobCardItems = this.state.jobCardItems;

        if (jobCardItems && jobCardItems.length > 0) {
            jobCardItems.forEach(item => {
                if (item.quantity <= 0 || item.productModelId <= 0) {
                    return false;
                }
            })
        }

        return true;
    }

    _isJobCardValid = (): boolean => {
        const { jobCardNo, jobCardItems, type } = this.state;
        if (jobCardNo === null || jobCardNo === "" && jobCardItems.length == 0) {
            toastr.error("Job Card NO. Is Required.");
            return false;
        }

        if (jobCardItems.filter(x => x.quantity == 0).length > 0 && type == 0) {

            toastr.error("Zero Quantity is not allowed!");

            return false;
        }

        return true;
    }

    _isItValidForm = (): boolean => {

        if (this._isAllJobCardItemValid() && this._isJobCardValid() && this.state.type !== -1 && !this.props.isJobCardSubmitting) {

            return true;
        }

        if (this.state.type == -1) {
            toastr.error("Please select Stockout/Return!")
        }

        return false;
    }


    _handleSearchInvoice(v: string) {
        this.props.requestGetSalesInvoices(0, 0, 1, 10, "invoiceCode", true, v !== "" ? "invoiceCode" : "", v)
    }

    _postJobCard = () => {
        if (this.state.invoiceId > 0) {
            var jobCard = this._getJobCard();
            this.props.requestReturnPostJobCardItems({ ...jobCard, status: 1 }, this._getJobCardItems());
        } else {
            toastr.error("Please select your invoice!");
        }
    }

    _issItemsFitForPost = (): boolean => {
        if (this.state.jobCardItems.filter(x => x.id <= 0).length > 0) return false;
        return true;
    }

    render() {

        if (this.props.jobCardAdded || this.props.jobCardPosted) {
            return <Redirect to="/inventory/management" />
        }

        return (
            <div className="content">
                <Row>
                    <Col md={10} style={{ margin: "0 auto" }}>
                        <Card
                            content={
                                <div>
                                    <form onSubmit={this.handleSubmit}>
                                        <Row>
                                            <span className=' d-inline shadow ml-3 cost-price-icon-box '>
                                                <i className='pe-7s-id' style={{ fontSize: 25 }}></i>
                                            </span>
                                            <h4 className='ml-3' style={{ marginBottom: 20, marginTop: -20 }}>New/Edit Job Card  </h4>
                                        </Row>
                                        <Row>
                                            <Col md={12} className="text-justify text-uppercase mt-5">
                                                <Row>
                                                    <Col md={4} className="text-left">
                                                        <input className='form-control' type="text" placeholder="Job Card Number"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                this.setState({ jobCardNo: e.target.value })
                                                            }}
                                                            value={this.state.jobCardNo}
                                                            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.keyCode === 13}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6} className='float-left'>
                                                        {this.state.type == 0 &&

                                                            <div>
                                                                <div>
                                                                    <h6 className="invoice-entry-head mb-2">Service / Spare parts</h6>
                                                                </div>
                                                                <Col className="filter-service-down">
                                                                    <Select
                                                                        onInputChange={(v) => this._handleProductSearch(v)}
                                                                        options={this.renderProducts()}
                                                                        onChange={(v: INewJobCardDropdown) => {
                                                                            this._handleOnProductItemSelect(v.value);
                                                                        }} />
                                                                </Col>
                                                            </div>

                                                        }
                                                    </Col>

                                                    <Col md={3} className='float-right'>
                                                        {
                                                            this.state.status !== 1 &&

                                                            <div className='float-right'>
                                                                <div className="form-check form-check-inline mt-4">
                                                                    <input className="form-check-input" type="radio" id="stockOut"

                                                                        name="type" value="0"
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            this.setState({ type: parseInt(e.target.value) })
                                                                        }} />
                                                                    <label className="form-check-label text-warning mt-2" htmlFor="stockOut">Stockout</label>
                                                                </div>
                                                                {
                                                                    this.state.jobCardId > 0 &&
                                                                    <div className="form-check form-check-inline mt-4">
                                                                        <input className="form-check-input" type="radio" id="return" name="type" value="1"
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                this.setState({ type: parseInt(e.target.value) })
                                                                            }} />
                                                                        <label className="form-check-label text-primary mt-2" htmlFor="return">Return</label>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>


                                                <Row>
                                                    <Col md={12}>
                                                        <Table responsive>
                                                            <thead style={{ backgroundColor: "#d3d3d3b2" }}>
                                                                <tr>
                                                                    <th colSpan={8}><label style={{ fontSize: 14 }}><b>Items</b></label></th>
                                                                </tr>
                                                            </thead>
                                                            <br />
                                                            <thead style={{ textAlign: "justify" }}>
                                                                <tr className="th-service-header ">
                                                                    <th>Remove</th>
                                                                    <th>Item Name</th>
                                                                    <th>Model</th>
                                                                    <th>Quantity</th>
                                                                    <th>Return Quantity</th>
                                                                    <th>Note</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <br />
                                                                {this._renderJobCardItems()}
                                                            </tbody>
                                                        </Table>
                                                        {
                                                            this.state.status == 0 &&
                                                            <Row>
                                                                <Col md={6}>
                                                                    {
                                                                        (this.state.type !== 1 && this.state.jobCardId > 0 && this._issItemsFitForPost()) &&
                                                                        <Select
                                                                            onInputChange={(v) => this._handleSearchInvoice(v)}
                                                                            options={this.renderInvoices()}
                                                                            onChange={(v: INewJobCardDropdown) => {
                                                                                this.setState({ invoiceId: v.value });
                                                                            }} />
                                                                    }

                                                                </Col>
                                                                <Col md={6}>
                                                                    <Button className='btn-info mb-5 pull-right' type="submit" active >
                                                                        {this.props.isJobCardSubmitting ? this.state.jobCardId > 0 ? "Updating..." : "Saving..." : this.state.jobCardId > 0 ? "Update" : "Save"}
                                                                    </Button>
                                                                    {
                                                                        (this.state.type !== 1 && this.state.jobCardId > 0 && this._issItemsFitForPost()) &&
                                                                        <Button
                                                                            className='btn-success mb-5 pull-right mr-2'
                                                                            onClick={(e) => this._postJobCard()}
                                                                            type="button" active>
                                                                            {this.props.isJobCardSubmitting ? "Posting..." : "Post"}
                                                                        </Button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        <br />
                                                        <br />
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </div >
        );
    }

    componentWillUnmount() {
        this.props.resetState();
        this.props.resetJobCardState();
    }
}
export default connect((state: ApplicationState) => {
    return {
        ...state.authorization,
        ...state.qoutation,
        ...state.jobCard,
        ...state.inventory
    }
}, {
    ...Qoutation.actionCreators,
    ...JobCard.actionCreators,
    ...Inventory.actionCreators
})(NewJobCard as any); 