import * as React from "react";
import {IRoute } from "../../models/RouteModel";
import PrivateRoute from "./PrivateRoute";
import { Route, Switch } from "react-router-dom";

export class RouteLooper extends React.PureComponent<{routes: IRoute[]}> {

    renderRoute(route: IRoute, key: number) {
        if (route.isPrivate) {
            return <PrivateRoute key={key} {...route} />;
        } else {
            return <Route exact={route.exact} path={route.path} component={route.component as React.ComponentType} key={key} />;
        }
    }

    render(): any {
        return (
            <Switch>
                {this.props.routes.map((route: IRoute, key: number) =>
                    route.component == null
                        ? route.children.map(this.renderRoute)
                        : route.children != null
                            ? ([route, ...route.children]).map(this.renderRoute)
                            : this.renderRoute(route, key)
                    )
                }
            </Switch>
            );
    }
}