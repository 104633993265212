import * as React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";


import { RouteComponentProps, match } from "react-router-dom";
import * as Quatation from "../../store/Qoutation";
import * as JobCard from "../../store/JobCard";
import * as Inventory from "../../store/Inventory";
import { MeasuringUnits } from "../../common/Constants";

type actionCreators = typeof Quatation.actionCreators & typeof JobCard.actionCreators;
type inventoryActionCreators = typeof Inventory.actionCreators;

interface InvoiceReportProps extends RouteComponentProps,
    Quatation.IQoutationState,
    Inventory.IInventoryState,
    JobCard.JobCardState,
    actionCreators,
    inventoryActionCreators {
    match: match<{ invoiceId?: string }>
}

interface InvoiceReportState {

}



class InvoiceReport extends React.PureComponent<InvoiceReportProps, InvoiceReportState> {

    componentDidMount() {
        const invoiceId = parseInt(this.props.match.params.invoiceId);
        this.props.requestGetJobCardStockItemsByInvoice(0, invoiceId);
        this.props.requestGetSalesInvoice(invoiceId, null);

        this.props.requestGetOutsideServices(invoiceId);
        this.props.requestGetSalesInvoiceCommission(invoiceId);
    }

    _renderCalculation(): any {
        const { jobCardStockItems, invoiceCommissions, salesInvoice, outsideServices } = this.props;

        var commissions = 0;
        var costPrice = 0;
        var outsideServiceCost = 0;
        var invoiceTotal = 0;
        var balance = 0;

        commissions = invoiceCommissions && invoiceCommissions.reduce((a, x, i, sr) => a + x.amount, 0);
        costPrice = jobCardStockItems && jobCardStockItems.reduce((a, b, index, src) => { return a + b.productStockItem.costPrice }, 0);
        outsideServiceCost = outsideServices && outsideServices.reduce((a, x, i, sr) => a + x.cost, 0);
        invoiceTotal = salesInvoice && salesInvoice.total;


        balance = invoiceTotal - (commissions + outsideServiceCost + costPrice);

        return (
            <table className="tblData">
                <tbody>
                    <tr className="erp-table-header" style={{   textTransform: "uppercase"  }}>
                        <td className="th-td-center">Invoice No.</td>
                        <td className="th-td-center">Job Card No.</td>
                        <td className="th-td-center">Items Cost Price</td>
                        <td className="th-td-center">Outside Service Cost</td>
                        <td className="th-td-center">Commissions</td>
                        <td className="th-td-center">Invoice Total</td>
                        <td className="th-td-center">Balance</td>
                    </tr>
                    <tr>
                        <td className="th-td-center" style={{ width: "200px" }}>
                            <p>
                                {salesInvoice && salesInvoice.invoiceCode}
                            </p>
                        </td>
                        <td className="th-td-center" style={{ width: "200px" }}>
                            <p>
                                {jobCardStockItems && jobCardStockItems.length > 0 && jobCardStockItems[0].jobCardItem && jobCardStockItems[0].jobCardItem.jobCard && jobCardStockItems[0].jobCardItem.jobCard.jobCardNo}
                            </p>
                        </td>
                        <td className="th-td-center" style={{ width: "200px" }}>
                            <p>
                                {costPrice}
                            </p>
                        </td>

                        <td className="th-td-center" style={{ width: "200px" }}>
                            <p>
                                {outsideServiceCost}
                            </p>
                        </td>
                        <td className="th-td-center" style={{ width: "200px" }}>
                            <p>
                                {commissions}
                            </p>
                        </td>
                        <td className="th-td-center" style={{ width: "200px" }}>
                            <p>
                                {invoiceTotal}
                            </p>
                        </td>
                        <td className="th-td-center" style={{ width: "200px" }}>
                            <p>
                                <NumberFormat value={balance} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                            </p>
                        </td>
                    </tr>
                </tbody>


            </table>
        );
    }


    render() {
        const { outsideServices, invoiceCommissions, jobCardStockItems, salesInvoice } = this.props;
        let totalOutsideServiceCost = 0;
        outsideServices && outsideServices.forEach(item => {
            totalOutsideServiceCost += item.cost;
        });

        let totalProductCost = 0;
        jobCardStockItems && jobCardStockItems.forEach(item => {
            totalProductCost += item.productStockItem && item.productStockItem.costPrice * item.quantity;
        });
        let totalCommissions = 0;
        invoiceCommissions && invoiceCommissions.forEach(item => {
            totalCommissions += item.amount;
        });

        return (
            <div>
                <React.Fragment>
                    <div style={{ position: "relative", marginTop: "80px" }}>
                        <div style={{ textAlign: "center" }}>
                            <h2 style={{ textTransform: "uppercase" }}>Invoice Report</h2>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <hr />
                        </div>

                        <table className="tblInvTop" style={{ float: "right" }}>
                            <tbody>
                                <tr>
                                    <td>DATE : </td>
                                    <td>{salesInvoice && new Date(salesInvoice.createDate).toDateString()}</td>
                                </tr>
                                <tr>
                                    <td>INVOICE NO : </td>
                                    <td> {salesInvoice && salesInvoice.invoiceCode}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div style={{ clear: "both" }}></div>

                        {
                            jobCardStockItems &&
                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Products</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>PRODUCT NAME</th>
                                            <th>MODEL</th>
                                            <th>QUANTITY</th>
                                            <th>RETURN QUANTITY</th>
                                            <th>USE</th>
                                            <th>COST PRICE</th>
                                            <th>TOTAL COST (BDT)</th>
                                        </tr>
                                        {jobCardStockItems.map(item => {

                                            return <tr>
                                                <td className="th-td-center">{item.jobCardItem && item.jobCardItem.product && item.jobCardItem.product.name}  </td>
                                                <td className="th-td-center">{item.jobCardItem && item.jobCardItem.productModel && item.jobCardItem.productModel.name}  </td>

                                                <td className="th-td-center">{item.jobCardItem && item.jobCardItem.quantity + ` ${item.jobCardItem.product.measuringUnit && item.jobCardItem.product.measuringUnit < 10 ? MeasuringUnits.find(it => it.value == item.jobCardItem.product.measuringUnit).label : ""}`}</td>
                                                <td className="th-td-center">{item.jobCardItem && item.jobCardItem.returnQuantity}</td>
                                                <td className="th-td-center">{item.jobCardItem && item.jobCardItem.quantity - item.jobCardItem.returnQuantity}</td>
                                                <td className="th-td-center">{item.productStockItem && item.productStockItem.costPrice}</td>
                                                <td className="th-td-center"><NumberFormat value={item.productStockItem && item.productStockItem.costPrice * item.quantity} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                            </tr>
                                        })}
                                        <tr className="tblDataTotal">
                                            <td colSpan={6} style={{ textTransform: "uppercase", textAlign: "right" }}>Total</td>
                                            <td colSpan={2}>
                                                <NumberFormat value={totalProductCost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        }

                        {
                            outsideServices &&
                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Outside Service</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>SUPPLIER NANE</th>
                                            <th>SERVICES </th>
                                            <th>COST</th>
                                            <th>PAYMENT</th>
                                            <th>PAYMENT TYPE</th>
                                        </tr>



                                        {outsideServices.map(item => {
                                            return <tr>

                                                <td className="th-td-center" style={{ width: "200px" }}>
                                                    <p>{item.supplier && item.supplier.name}</p>
                                                </td>

                                                <td className="th-td-center" style={{ width: "200px" }}>
                                                    <p>{item.services && item.services}</p>
                                                </td>

                                                <td className="th-td-center" style={{ width: "200px" }}>
                                                    <p><NumberFormat value={item.cost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></p>
                                                </td>

                                                <td className="th-td-center" style={{ width: "200px" }}>
                                                    <p><NumberFormat value={item.payment} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></p>

                                                </td>

                                                <td className="th-td-center" style={{ width: "200px" }}>
                                                    <p> {item.paymentAccount && item.paymentAccount.name}</p>
                                                </td>

                                            </tr>
                                        })
                                        }
                                        <tr className="tblDataTotal">
                                            <td colSpan={3} style={{ textTransform: "uppercase", textAlign: "right" }}>Total</td>
                                            <td colSpan={3}>
                                                <NumberFormat value={totalOutsideServiceCost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        }

                        {
                            invoiceCommissions &&
                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Sales Commission</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>NANE</th>
                                            <th>PHONE </th>
                                            <th>NOTE</th>
                                            <th>AMOUNT</th>
                                        </tr>

                                        {invoiceCommissions.map(item => {
                                            return <tr >
                                                <td className="th-td-center">{item.name}</td>
                                                <td className="th-td-center">{item.phone}</td>
                                                <td className="th-td-center">{item.note}</td>
                                                <td className="th-td-center"><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                            </tr>

                                        })}
                                        <tr className="tblDataTotal">
                                            <td colSpan={3} style={{ textTransform: "uppercase", textAlign: "right" }}>Total</td>
                                            <td colSpan={2}>
                                                <NumberFormat value={totalCommissions} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        }

                        {

                            <div>
                                <div>
                                    <h4 className="service-header" style={{ textTransform: "uppercase" }}>Invoice Summarry</h4>
                                </div>

                                {this._renderCalculation()}

                            </div>
                        }


                    </div>
                </React.Fragment>
            </div>
        )
    }
}


export default connect((state: ApplicationState) => {
    return {
        ...state.qoutation,
        ...state.inventory,
        ...state.jobCard
    }
}, {
    ...Quatation.actionCreators,
    ...Inventory.actionCreators,
    ...JobCard.actionCreators
})(InvoiceReport as any);