import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import { IAuthData, AuthData } from "../models/AuthData";
import db from "../common/TempDB";
import { LC_AUTH_KEY } from "../common/Constants";
import graphQl from "../services/GraphQL";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IAuthorizationState {
    isLoading: boolean;
    authData: IAuthData | null;
    error?: Error;
    isAuth: boolean;
}

export class AuthorizationState implements IAuthorizationState {
    isLoading: boolean;
    authData: IAuthData | null;
    error?: Error;
    isAuth: boolean;
    hasAuth(): boolean {

       if (this.authData) {
            return true;
       }
       return false;
    };

    constructor(isLoading: boolean, authData: IAuthData | null, error?: Error) {
        this.isLoading = isLoading;
        this.authData = authData;
        this.error = error;
        this.isAuth = this.hasAuth();
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestLoginAction extends Action<string> {
    type: "REQUEST_LOGIN";
}

interface SuccessLoginAction extends Action<string> {
    type: "SUCCESS_LOGIN";
    authData: IAuthData;
}

interface RequestLogOut extends Action<string> {
    type: "REQUEST_LOGOUT"
}


interface RespondLogOut extends Action<string> {
    type: "RESPOND_LOGOUT",
    authData: IAuthData
}


interface ErrorLoginAction extends Action<string> {
    type: "ERROR_LOGIN";
    error: Error;
}

export interface CheckLoginAction extends Action<string> {
    type: "CHECK_LOGIN";
    error: Error;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestLoginAction | SuccessLoginAction | ErrorLoginAction | CheckLoginAction | RespondLogOut | RequestLogOut;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestLogin: (userName: string, password: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        try {
            dispatch({ type: "REQUEST_LOGIN" });
            const authData = appState.authorization!.authData;
            let auth = {
                userName,
                password
            }
            const data = await graphQl.executeMutation<{ login: IAuthData }>("login(userName:$userName,password:$password){expiration,header,refreshToken,token,roles,me{firstName,lastName,sex,userId}}", ["$userName:String", "$password:String"], { userName, password })

            dispatch({ type: "SUCCESS_LOGIN", authData: data.login });
        } catch (e) {
            dispatch({ type: "ERROR_LOGIN", error: e });
        }

    },
    requestLogOut: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_LOGOUT" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ logOut: IAuthData }>("logOut{refreshToken}", [], {}, authData)

            dispatch({ type: "RESPOND_LOGOUT", authData: data.logOut });
        } catch (e) {
            dispatch({ type: "ERROR_LOGIN", error: e });
        }


    },



    checkLogin: () => ({ type: "CHECK_LOGIN" } as CheckLoginAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IAuthorizationState = new AuthorizationState(false, db.getItem<AuthData>(LC_AUTH_KEY));

export const reducer: Reducer<IAuthorizationState> = (state: IAuthorizationState | undefined, incomingAction: Action): IAuthorizationState => {
    if (state === undefined) {
        return { ...unloadedState };
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_LOGIN":
            return { ...new AuthorizationState(true, null) };

        case "SUCCESS_LOGIN": {
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            const newState = new AuthorizationState(false, action.authData);
            db.setItem(LC_AUTH_KEY, newState.authData);
            return { ...newState };
        }

        case "REQUEST_LOGOUT":
            return { ...state };

        case "RESPOND_LOGOUT":

            const newState = new AuthorizationState(false, action.authData)
            db.removeItem(LC_AUTH_KEY);
            return { ...newState };



        case "ERROR_LOGIN":
            return { ...new AuthorizationState(false, null, action.error) };

        case "CHECK_LOGIN": {
            if (state.authData == null) {
                return state;
            }
            return { ... new AuthorizationState(false, state.authData) };
        }

        default:
            return state;
    }


};
