import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import { IBankModel } from "../models/BankModel";
import graphQl from "../services/GraphQL";
import { IVehicleModel } from "../models/VehicleModel";

export interface IBankState {
    isLoading?: boolean,
    banks?: Array<IBankModel> | null,
    error: Error | null,
    bank: IBankModel | null,
    bankAdded: boolean;
}


//Request GET actions
export interface RequestGetBanks extends Action<string> {
    type: "REQUEST_GET_BANKS";
}
//Request add actions
export interface RequestAddUpdateBank extends Action<string> {
    type: "REQUEST_ADD_UPDATE_BANK";
}

//Response GET actions
export interface RespondGetBanks extends Action<string> {
    type: "RESPOND_GET_BANKS";
    payload: Array<IBankModel> | null;
    error: Error | null
}

//Respond add action
export interface RspondAddUpdateBank extends Action<string> {
    type: "RESPOND_ADD_UPDATE_BANK";
    payload: IBankModel | null;
    error: Error | null
}



//Reset

export interface RESETBANK {
    type: "RESET_BANK";
}


type KnownAction = RequestGetBanks | RespondGetBanks | RequestAddUpdateBank | RspondAddUpdateBank | RESETBANK

export const actionCreators = {

    requestAddBank: (bank: IBankModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_BANK" });
       
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addBankAccount: IBankModel }>("addBankAccount(bank:$bank){accountName}", ["$bank:BankAccountInputType!"], { bank }, authData);
       
            dispatch({ type: "RESPOND_ADD_UPDATE_BANK", payload: data.addBankAccount, error: null });
            dispatch({ type: "RESET_BANK" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_BANK", payload: null, error: e });
        }
    }

    
}

const unloadedState: IBankState = {
    banks: [],
    isLoading: false,
    error: null,
    bank: null,
    bankAdded: false
}


export const reducer: Reducer<IBankState> = (state: IBankState | undefined, incomingAction: Action): IBankState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_GET_BANKS":
            return {
                ...state,
                isLoading: true,
                bankAdded: false
            }
       
        case "REQUEST_ADD_UPDATE_BANK":
            return {
                ...state,
                isLoading: true,
                bankAdded: false
            }

        case "RESPOND_ADD_UPDATE_BANK": {
            return {
                ...state,
                bankAdded: true,
                isLoading: false,
                bank:action.payload
            }
        }

        case "RESPOND_GET_BANKS":
            return {
                ...state,
                isLoading: false,
                banks: action.payload,
                error: action.error,
                bankAdded: action.payload !== null
            }        

        case "RESET_BANK":
            return {
                ...state,
                isLoading: false,
                bankAdded: false
            }

        default:
            return state;
    }
}
