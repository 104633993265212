import * as React from "react";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import * as Client from "../../store/Client";
import * as Account from "../../store/Account";
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";

const today = new Date();

class CorporateClientPrint extends React.PureComponent<any, any> {
    
    state = {
        fromDate: today,
        toDate: today
    };
    
    componentDidMount(): void {
        let accountId = this.props.match.params.accountId;
        const qp = new URLSearchParams(this.props.location.search);
        let fromDate = new Date(qp.get("fromDate"));//new Date(date.setDate(date.getDate() - 1));
        let toDate = new Date(qp.get("toDate"));
        let isCorporateClient = qp.get("isCorporateClient");
        let params = {
            accountId: accountId,
            toDate: toDate,
            fromDate: fromDate,
            isDetail: true,
            voucherNo: 0,
            isCorporateClient: isCorporateClient
        };
        this.props.requestJournalDetails(params);
        if (accountId !== undefined) {
            this.setState({accountId: parseInt(accountId), toDate: toDate, fromDate: fromDate})
        }
    }

    _thousandSeparator(num) {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }


    _generateRow(a, convt1, convt2, initBalance, endingBalance, isSpecial) {
        const voucherDt = a.voucherDate && new Date(a.voucherDate);
        const minDate = new Date(1, 1, 1);
        //const jvnInv = (a.account.name === "Sales" && a.salesInvoice.invoiceCode
        const jvnInv = (a.account.name === "Sales" && a.salesInvoice && a.salesInvoice.invoiceCode

            ? (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}<br/>{`${a.salesInvoice.invoiceCode}`}</div>)
            : (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}</div>));
        if (isSpecial) {
            let op = a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit;
            return [
                a.account.name,
                this._thousandSeparator(op.toFixed(2)), //op > 0 ? this._thousandSeparator(op.toFixed(2)) : "0.00",
                //op < 0 ? this._thousandSeparator(Math.abs(op).toFixed(2)) : "0.00",
                a.debit ? this._thousandSeparator(a.debit.toFixed(2)) : "0.00",
                a.credit ? this._thousandSeparator(a.credit.toFixed(2)) : "0.00",
                this._thousandSeparator(Math.abs(endingBalance).toFixed(2)), //endingBalance > 0 ? this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) : "0.00",
                //endingBalance < 0 ? this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) : "0.00"
            ];

        } else {
            return [
                voucherDt && voucherDt.getTime() > minDate.getTime() ? convt1.format(voucherDt) : "",
                a.isOpeningBalance ?
                    "(Opening Balance)"
                    : <Link
                        title="Click to see details"
                        to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(a.voucherNo)}` : a.account.willHaveChild === false ? `/vehicle-sales-history/${a.accountId}?fromDate=${this.state.fromDate}&toDate=${this.state.toDate}` : `/corporate-client/list/${a.account.name}-${a.accountId}`}>
                        {a.account.name}
                    </Link>,
                //Math.abs(initBalance).toMoney() + " " + (initBalance >= 0 ? "Dr" : "Cr"),
                this._getAmountForCell(a, 1),
                this._getAmountForCell(a, 2),
                this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) + " " + (endingBalance >= 0 ? "Dr" : "Cr"),
                a.comments || "No Comments",
                a.voucherNo ? jvnInv : ""
            ];
        }
    }


    _getAmountForCell(a, type) {
        return a.isOpeningBalance
            ? (a.type === type
                ? this._thousandSeparator(a.amount.toFixed(2))
                : "---")
            : a.type === type
                ? "---"
                : this._thousandSeparator(a.amount.toFixed(2));
    }

    generateRows(journalModel) {
        journalModel = {...journalModel}
        if (journalModel.journals) {
            journalModel.journals = [...journalModel.journals]
        }
        const opt1 = {month: "long", day: "numeric", year: "numeric"};
        const opt2 = {month: "short", day: "numeric"};
        const convt1 = new Intl.DateTimeFormat("en-US", opt1);
        const convt2 = new Intl.DateTimeFormat("en-US", opt2);
        let lastBalance, manufecturings,
            endingBalance = journalModel.openingBalance ? journalModel.openingBalance.debit - journalModel.openingBalance.credit : 0;

        const rows = (journalModel.journals || []).map((a, i) => {
            const voucherDt = a.voucherDate && new Date(a.voucherDate);

            if (!journalModel.isDetail) {
                if (!lastBalance) {
                    //if (journalModel.openingBalance) {
                    lastBalance = journalModel.openingBalance.debit - journalModel.openingBalance.credit;
                    //}
                    //else {
                    //    lastBalance = 0;
                    //}

                }
                return [
                    voucherDt ? convt1.format(voucherDt) : "",
                    "Balance",
                    this._thousandSeparator(Math.abs(lastBalance).toFixed(2)) + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                    this._thousandSeparator(a.debit.toFixed(2)),
                    this._thousandSeparator(a.credit.toFixed(2)),
                    this._thousandSeparator(Math.abs((lastBalance = (a.debit && a.credit ? ((a.debit - a.credit) + lastBalance) : 0))).toFixed(2)) + " " + (a.debit && a.credit ? a.debit >= a.credit ? "Dr" : "Cr" : "")
                ];
            }

            let initBalance = endingBalance;
            if (journalModel.isSpecial) {
                endingBalance = (a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit) + (a.debit - a.credit);
            } else {
                endingBalance += a.isOpeningBalance
                    ? a.type === 2
                        ? a.amount
                        : -1 * a.amount
                    : a.type === 1
                        ? a.amount
                        : -1 * a.amount;
            }
            return this._generateRow(a, convt1, convt2, initBalance, endingBalance, journalModel.isSpecial);
        });
        if (rows.length === 0) {
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
        }
        let openingBalance = journalModel.openingBalance;

        if (journalModel.isDetail && openingBalance) {
            var date = new Date(this.state.fromDate)
            var dateCal = date.setDate(date.getDate() - 1)

            rows.splice(0, 0, [
                "Initial Balance",
                `From ${convt1.format(dateCal)}`,
                "",
                "",
                this._thousandSeparator(Math.abs(openingBalance.debit - openingBalance.credit).toFixed(2)) + " " +
                ((openingBalance.debit - openingBalance.credit) >= 0 ? "Dr" : "Cr"), "", ""
            ])
        }
        if (!journalModel.isDetail) {
            rows.push([
                "",
                "Total",
                this._thousandSeparator((Math.abs(lastBalance) || 0).toFixed(2)) + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                "",
                "",
                ""
            ]);
            return rows;
        }
        if (journalModel.isSpecial) {
            let sumOfOpDr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.debit - j.account.openingBalanceJournal.credit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            // let sumOfOpCr = journalModel.journals
            //     .map(j => j.account.openingBalanceJournal.credit - j.account.openingBalanceJournal.debit)
            //     .filter(j => j > 0)
            //     .reduce((a, b) => a + b, 0);

            let sumOfDr = journalModel.journals
                .map(j => j.debit)
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .map(j => j.credit)
                .reduce((a, b) => a + b, 0);

            let endDr = journalModel.journals
                .map(j => (j.account.openingBalanceJournal.debit - j.account.openingBalanceJournal.credit) + (j.debit - j.credit))
                .reduce((a, b) => a + b, 0);
            // let endCr = sumOfOpCr + sumOfCr;

            let row = [
                "Total: ",
                this._thousandSeparator(sumOfOpDr.toFixed(2)),
                // this._thousandSeparator(sumOfOpCr.toFixed(2)),
                `৳ ${this._thousandSeparator(sumOfDr.toFixed(2))}`,
                `৳ ${this._thousandSeparator(sumOfCr.toFixed(2))}`,
                this._thousandSeparator(endDr.toFixed(2)),
                // this._thousandSeparator(endCr.toFixed(2))

            ];
            rows.push(row);
        } else if (journalModel.journals) { // total balance calculation

            let sumOfDr = journalModel.journals
                .filter(a => journalModel.isDetail
                    ? a.isOpeningBalance
                        ? a.type === 2
                        : a.type === 1
                    : a.amount < 0)
                .map(j => Math.abs(j.amount))
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .filter(a => journalModel.isDetail ? a.isOpeningBalance ? a.type === 1 : a.type === 2 : a.amount > 0)
                .map(j => Math.abs(j.amount))
                .reduce((a, b) => a + b, 0);

            let row = [
                "Total Debit/Credit",
                "",
                "৳ " + this._thousandSeparator(sumOfDr.toFixed(2)),
                "৳ " + this._thousandSeparator(sumOfCr.toFixed(2)),
                "",
                "",
                ""
            ];
            rows.push(row);


            if (openingBalance) {
                sumOfCr += openingBalance.credit;
                sumOfDr += openingBalance.debit;
            }

            let totalBalance = Math.abs(sumOfCr - sumOfDr);

            rows.push([
                "",
                "Total",
                sumOfDr >= sumOfCr ? "৳ " + this._thousandSeparator(totalBalance.toFixed(2)) : "",
                sumOfCr > sumOfDr ? "৳ " + this._thousandSeparator(totalBalance.toFixed(2)) : "",
                "",
                "",
                ""
            ]);

        }
        return rows;
    }

    render() {
        const {journalModel, isLoading} = this.props;
        let heads = [], rows = [];
        if (!isLoading && journalModel && journalModel.journals as any) {
            heads = !journalModel.isSpecial
                ? [
                    "Date of Transaction",
                    "Account Against",
                    journalModel.isDetail ? "Debit" : "Opening Balance",
                    journalModel.isDetail ? "Credit" : "Debit",
                    journalModel.isDetail ? "Note" : "Credit",
                    journalModel.isDetail ? "JVN/INV" : "Ending Balance"
                ]
                : [
                    "Account Against",
                    "Previous Due", //"Opening(Debit)",
                    //"Opening(Credit)",
                    "New Sales",//"Debit",
                    "Paid",//"Credit",
                    "Current Due",//"Ending(Debit)",
                    //"Ending(Credit)"
                ];

            if (!journalModel.isSpecial) {
                //if (journalModel.isDetail) {
                //    header.splice(2, 0, "Opening Balance");
                //}
                if (journalModel.isDetail) {
                    heads.splice(4, 0, "Balance");
                }
            }

            rows = this.generateRows(journalModel) as any;
        }

        let rowsChunk = [];
        const chunk = 30;
        let partIndex = 0;
        let i, j;

        for (i = 0, j = rows.length; i < j; i += chunk) {

            rowsChunk.push(rows.slice(i, i + chunk));
        }
        
        const convt1 = new Intl.DateTimeFormat("en-US", {month: "long", day: "numeric", year: 'numeric'});


        return (<React.Fragment>
            <div style={{height: 100}}/>
            <div style={{ position: "relative" }}>
                <div style={{ textAlign: "center" }}>
                    <p style={{ fontWeight: "bold", fontSize: "35px", marginBottom:"20px"}}>Vehicle Sales Report</p>
                </div>
                <div style={{textAlign: "left"}}>
                    <p style={{fontSize: "15px" }}>
                        {(journalModel !== null && journalModel.account !== null) ? journalModel.account.name : ""}
                        {(journalModel !== null && journalModel.account !== null) ? typeof journalModel.account !== "undefined" ? `(${convt1.format(new Date(journalModel.account.createDate))})` : null : null}
                    </p>
                </div>

                <div style={{textAlign: "left"}}>
                    <p style={{ fontSize: "15px" }}>From: <b>{this.state.fromDate.toDateString()}</b></p>
                    <p style={{ fontSize: "15px" }}>To: <b>{this.state.toDate.toDateString()}</b></p>
                </div>
                <div style={{textAlign: "center"}}>
                    <hr/>
                </div>

                <div style={{clear: "both"}}/>
                {rowsChunk.map((item, mainIndex) => {
                    partIndex += rowsChunk[mainIndex].length;

                    return <div key={mainIndex}
                                style={{pageBreakAfter: mainIndex < rowsChunk.length - 1 || rowsChunk[rowsChunk.length - 1].length > 30 ? "always" : "avoid"}}>

                        <table className="tblData">
                            <tbody>
                            <tr className="erp-table-header" style={{textTransform: "uppercase"}}>
                                {heads.map(a => (
                                    <th>
                                        <strong>{a}</strong>
                                    </th>
                                ))}
                            </tr>
                            {item.map((s, index) => (
                                <tr key={index}>
                                    {s.map(a => <td className="th-td-center">{a}</td>)}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                })
                }
            </div>

        </React.Fragment>)

    }

}

export default connect(
    (state: ApplicationState) => {
        return {...state.client, ...state.account}
    }, {...Client.actionCreators, ...Account.actionCreators}
)(CorporateClientPrint as any);

