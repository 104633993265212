import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import { IClientModel } from "../models/ClientModel";
import graphQl from "../services/GraphQL";
import { IVehicleModel } from "../models/VehicleModel";

export interface IClientState {
    isLoading?: boolean,
    clients?: Array<IClientModel> | null,
    specialClients?: Array<IClientModel> | null,
    error: Error | null,
    client: IClientModel | null,

    clientAdded: boolean;
}


//Request GET actions
export interface RequestGetClients extends Action<string> {
    type: "REQUEST_GET_CLIENTS";
}
export interface UpdateClients extends Action<string> {
    type: "UPDATE_CLIENTS",
    payload: IClientModel
}

//Request add actions
export interface RequestAddUpdateClient extends Action<string> {
    type: "REQUEST_ADD_UPDATE_CLIENT";
}

//Response GET actions
export interface RespondGetCleints extends Action<string> {
    type: "RESPOND_GET_CLIENTS";
    payload: Array<IClientModel> | null;
    error: Error | null
}

//Respond add action
export interface RspondAddUpdateClient extends Action<string> {
    type: "RESPOND_ADD_UPDATE_CLIENT";
    payload: IClientModel | null;
    error: Error | null
}

export interface IRequestTopClients {
    type: "REQUEST_TOP_CLIENTS"
}

export interface IRespondTopClients {
    type: "RESPOND_TOP_CLIENTS";
    payload: Array<IClientModel> | null;
    error: Error | null;
}

//
export interface IRequestSpecialClients {
    type: "REQUEST_GET_SPECIAL_CLIENTS";
}

export interface IRespondSpecialClients {
    type: "RESPOND_GET_SPECIAL_CLIENTS";
    payload: Array<IClientModel> | null;
    error: Error | null;
}


//
export interface IRequestCorporateClients {
    type: "REQUEST_GET_CORPORATE_CLIENTS";
}

export interface IRespondCorporateClients {
    type: "RESPOND_GET_CORPORATE_CLIENTS";
    payload: Array<IClientModel> | null;
    error: Error | null;
}


//Reset

export interface RESET {
    type: "RESET_ACTION";
}


type KnownAction = RequestGetClients | RequestAddUpdateClient |
    RespondGetCleints | RspondAddUpdateClient | UpdateClients |
    IRequestTopClients | IRespondTopClients |
    IRequestSpecialClients | IRespondSpecialClients |
    IRequestCorporateClients | IRespondCorporateClients |

    RESET

export const actionCreators = {

    requestGetClients: (name: string, phone: string, isSearch: boolean = false): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_CLIENTS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ client: Array<IClientModel> }>("client(name:$name,phone:$phone,search:$search){id address contactPerson isCommercial cellPhone cityId name type accountId accountNo accountName bankName bKashAccountNo cityId areaId}", ["$name:String", "$phone:String", "$search:Boolean"], { name, phone, search: isSearch }, authData);
            dispatch({ type: "RESPOND_GET_CLIENTS", payload: data.client, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_CLIENTS", payload: null, error: e });
        }
    },

    requestAddOrUpdateClient: (client: IClientModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_CLIENT" });

            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addOrUpdateClient: IClientModel }>("addOrUpdateClient(client:$client){id name type cellPhone contactPerson}", ["$client:ClientInputType!"], { client }, authData);

            dispatch({ type: "RESPOND_ADD_UPDATE_CLIENT", payload: data.addOrUpdateClient, error: null });
            dispatch({ type: "RESET_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_CLIENT", payload: null, error: e });
        }
    },

    requestTopClients: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_TOP_CLIENTS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ clients: Array<IClientModel> }>("clients:topClients{account{journal{amount}}name,address}", [], {}, authData);

            dispatch({ type: "RESPOND_TOP_CLIENTS", payload: data.clients, error: null });
            dispatch({ type: "RESET_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_TOP_CLIENTS", payload: [], error: e });
        }
    },

    requestGetSpecialClients: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SPECIAL_CLIENTS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ clients: Array<IClientModel> }>("clients:specialClients{id address contactPerson cellPhone name type accountId accountNo accountName bankName bKashAccountNo parentId isSpecial isCommercial cityId areaId}", [], {}, authData);

            dispatch({ type: "RESPOND_GET_SPECIAL_CLIENTS", payload: data.clients, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_SPECIAL_CLIENTS", payload: [], error: e });
        }
    },

    requestGetCorporateClients: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_CORPORATE_CLIENTS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ clients: Array<IClientModel> }>("clients:corporateClients{id address contactPerson cellPhone name type accountId accountNo accountName bankName bKashAccountNo parentId isSpecial isCommercial cityId areaId clientCode}", [], {}, authData);

            dispatch({ type: "RESPOND_GET_CORPORATE_CLIENTS", payload: data.clients, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_CORPORATE_CLIENTS", payload: [], error: e });
        }
    },

    updateClients: (client: IClientModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        dispatch({ type: "UPDATE_CLIENTS", payload: client });

    },
    resetClientState: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        dispatch({ type: "RESET_ACTION" });
    }
}

const unloadedState: IClientState = {
    clients: [],
    specialClients: [],
    isLoading: false,
    error: null,
    client: null,

    clientAdded: false
}


export const reducer: Reducer<IClientState> = (state: IClientState | undefined, incomingAction: Action): IClientState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_GET_CLIENTS":
            return {
                ...state,
                isLoading: true,
                clientAdded: false
            }
        case "RESPOND_GET_CLIENTS":
            return {
                ...state,
                clients: action.payload,
                isLoading: false,
                error: action.error
            }
        case "REQUEST_ADD_UPDATE_CLIENT":
            return {
                ...state,
                isLoading: true,
                clientAdded: false
            }

        case "RESPOND_ADD_UPDATE_CLIENT":
            return {
                ...state,
                isLoading: false,
                client: action.payload,
                error: action.error,
                clientAdded: action.payload !== null
            }

        case "UPDATE_CLIENTS":
            var newState = {
                ...state,
            }
            if (newState.clients == null && action.payload !== null) {
                newState.clients = [action.payload]
            } else {
                if (action.payload == null) {
                    newState.clients = []
                } else {
                    var cl = newState.clients.find((item) => action.payload.id == item.id)                    
                    cl&&newState.clients.push(action.payload)
                }
            }


            return newState;


        case "REQUEST_TOP_CLIENTS":
            {
                return {
                    ...state,
                    isLoading: true,
                    clientAdded: false
                }
            }
        case "RESPOND_TOP_CLIENTS":
            {
                return {
                    ...state,
                    clients: action.payload,
                    isLoading: false,
                    error: action.error
                }
            }
        case "REQUEST_GET_SPECIAL_CLIENTS":
            {
                return {
                    ...state,
                    isLoading: true
                }
            }
        case "RESPOND_GET_SPECIAL_CLIENTS":
            {
                return {
                    ...state,
                    specialClients: action.payload,
                    error: action.error
                }
            }


        case "REQUEST_GET_CORPORATE_CLIENTS":
            {
                return {
                    ...state,
                    isLoading: true
                }
            }
        case "RESPOND_GET_CORPORATE_CLIENTS":
            {
                return {
                    ...state,
                    clients: action.payload,
                    error: action.error
                }
            }


        case "RESET_ACTION":
            return {
                ...state,
                isLoading: false,
                clientAdded: false,
                clients: null
            }

        default:
            return state;
    }
}
