import * as React from "react";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, DropdownButton, Dropdown } from "react-bootstrap";

import AdminNavbarLinks from "./AdminNavbarLinks";

class Header extends React.PureComponent<{ brandText: string },{ sidebarExists: boolean }> {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = () => {
        //this.parentElement.removeChild(this);
        document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }
  render() {
    return (

      <Navbar fluid>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="#pablo">{this.props.brandText}</a>
          </Navbar.Brand>
                <Navbar.Toggle onClick={this.mobileSidebarToggle} />
            </Navbar.Header>
            {/*
            <div className="logout-drop">
                <i className="pe-7s-power"></i>
                <div className="logout-content">
                    <p >Logout</p>
                </div>
            </div>
           */}
           
      </Navbar>
    );
  }
}

export default Header;
