import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import graphQl from "../services/GraphQL";

import { IJournalModel, IJournalEntryResponse, IJournalWrapper, ICashBankReciptWrapper, ICashBankRecipt } from "../models/SupplierModel";
import { IAccountModel, IBankTotal } from "../models/AccountModel";
import * as AccountType from "../components/pages/common/AccountType";
import { stat } from "fs";
import { IVehicleJournalModel, IVehicleJournalHistory } from "../models/VehicleModel";

export interface AccountState {
    isLoading?: boolean;
    paymentAccounts?: Array<IAccountModel> | null;
    accounts?: Array<IAccountModel> | null;
    cash?: IBankTotal | null;
    bank?: IBankTotal | null;
    //accountSummary?: {[key : number]: IBankTotal} | null;
    accountSummary?: any;
    journals: IJournalWrapper | null;
    reciptWrapper: ICashBankReciptWrapper | null;
    cashBankReceipts: Array<ICashBankRecipt>;
    error: Error | null;
    isJournalPosting: boolean;
    isJournalPosted: boolean;
    journalEntryResult?: IJournalEntryResponse;
    isAccountPosting: boolean;
    account?: IAccountModel | null;
    isAccountPosted: boolean;
    journalModel: {} | any;
    journalList: IJournalModel[];
    vehicleJournalHistory: IVehicleJournalHistory
}


//Request GET actions
export interface RequestAccount extends Action<string> {
    type: "REQUEST_ACCOUNT";
}




export interface RequestVehcleJournalsHistory extends Action<string> {
    type: "REQUEST_VEHICLE_JOURNAL_HISTORY";
}

export interface RespondAccount extends Action<string> {
    type: "RESPOND_ACCOUNT";
    accounts?: Array<IAccountModel> | null;
    error: Error | null;
}
export interface RequestBanckTotal extends Action<string> {
    type: "REQUEST_BANK_TOTAL";
}

export interface RespondBanckTotal extends Action<string> {
    type: "RESPOND_BANK_TOTAL";
    cash?: IBankTotal | null;
    bank?: IBankTotal | null;
    error: Error | null;
}
export interface RequestAccountSummary extends Action<string> {
    type: "REQUEST_ACCOUNT_SUMMARY";
}
export interface RequestPaymentAccount extends Action<string> {
    type: "REQUEST_PAYMENT_ACCOUNT";
}

export interface RespondPaymentAccount extends Action<string> {
    type: "RESPOND_PAYMENT_ACCOUNT";
    paymentAccounts?: Array<IAccountModel> | null;
    error: Error | null;
}


export interface RequestGetCashBankJournals extends Action<string> {
    type: "REQUEST_GET_CASH_BANK_JOURNALS";
}

export interface RespondGetCashBankJournals extends Action<string> {
    type: "RESPOND_GET_CASH_BANK_JOURNALS";
    payload?: Array<ICashBankRecipt> | null;
    error: Error | null;
}

export interface RequestGetJournalsByJvn extends Action<string> {
    type: "REQUEST_GET_JOURNALS_BY_JVN";
}

export interface RespondGetJournalsByJvn extends Action<string> {
    type: "RESPOND_GET_JOURNALS_BY_JVN";
    payload?: Array<IJournalModel> | null;
    error: Error | null;
}

export interface RespondAccountSummary extends Action<string> {
    type: "RESPOND_ACCOUNT_SUMMARY";
    payload: any;
    error: Error | null;
}

export interface RequestFilterAccount extends Action<string> {
    type: "REQUEST_FILTER_ACCOUNT";
}

export interface RespondFilterAccount extends Action<string> {
    type: "RESPOND_FILTER_ACCOUNT";
    accounts?: Array<IAccountModel> | null;
    error: Error | null;
}

export interface RequestAddJournalEntry extends Action<string> {
    type: "REQUEST_ADD_JOURNAL_ENTRY";
}

export interface RespondAddJournalEntry extends Action<string> {
    type: "RESPOND_ADD_JOURNAL_ENTRY";
    payload?: IJournalEntryResponse | null;
    error: Error | null;
}

export interface IRequestAddAccount {
    type: "REQUEST_ADD_ACCOUNT"
}

export interface IRespondAddAccount {
    type: "RESPOND_ADD_ACCOUNT",
    payload?: IAccountModel | null;
    error: Error | null;
}

export interface IRequestJournalDetails {
    type: "REQUEST_JOURNAL_DETAILS"
}

export interface IRespondJournalDetails {
    type: "RESPOND_JOURNAL_DETAILS",
    payload: any;
    error: Error | null;
}


export interface RspondVehicleJournalHistory extends Action<string> {
    type: "RESPOND_VEHICLE_JOURNAL_HISTORY";
    payload: IVehicleJournalHistory | null;
    error: Error | null
}

//Reset

export interface RESET extends Action<string> {
    type: "RESET_ACTION";
}


type KnownAction = RequestAccount | RespondAccount
    | RequestAddJournalEntry | RespondAddJournalEntry
    | RequestFilterAccount | RespondFilterAccount
    | RequestBanckTotal | RespondBanckTotal
    | RequestAccountSummary | RespondAccountSummary
    | RequestPaymentAccount | RespondPaymentAccount
    | RequestGetCashBankJournals | RespondGetCashBankJournals
    | IRequestAddAccount | IRespondAddAccount
    | IRequestJournalDetails | IRespondJournalDetails
    | RequestGetJournalsByJvn | RespondGetJournalsByJvn |
    RspondVehicleJournalHistory | RequestVehcleJournalsHistory | RESET;

export const actionCreators = {

    requestAccounts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ACCOUNT" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ accounts: Array<IAccountModel> }>(
                `accounts{
                    id name code children{
                        id name code children{
      	                    id name code children{
     		                    id name code
    		                    }
    	                    }
                    }
                }`
                , [], {}, authData);
            dispatch({ type: "RESPOND_ACCOUNT", accounts: data.accounts, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ACCOUNT", error: e });
        }
    },
    requestPaymentAccounts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_PAYMENT_ACCOUNT" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ accounts: Array<IAccountModel> }>(
                `accounts:getPaymentAccounts{
                    id name code }`
                , [], {}, authData);
            dispatch({ type: "RESPOND_PAYMENT_ACCOUNT", paymentAccounts: data.accounts, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_PAYMENT_ACCOUNT", error: e });
        }
    },

    requestExpenseChildAccounts: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ACCOUNT" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ getAccounts: Array<IAccountModel> }>
                ("getAccounts(rootId:$rootId){id name code children{id name}}", [`$rootId:Int`], { rootId: id }, authData);

            dispatch({ type: "RESPOND_ACCOUNT", accounts: data.getAccounts, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ACCOUNT", error: e });
        }
    },


    requestFilterAccounts: (filter: string, receipts: boolean, payments: boolean, expense?: boolean, onlySupplier?: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_FILTER_ACCOUNT" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ accounts: Array<IAccountModel> }>(
                `accounts:getAccounts(filter:$filter,receipts:$receipts,payments:$payments,expense:$expense,onlySupplier:$onlySupplier){
                    id name code children{
                        id name code children{
      	                    id name code children{
     		                    id name code
    		                    }
    	                    }
                    }
                }`
                , [`$filter:String`, `$receipts:Boolean`, `$payments:Boolean`, `$expense:Boolean`, "$onlySupplier:Boolean"], { filter, receipts, payments, expense, onlySupplier }, authData);

            dispatch({ type: "RESPOND_FILTER_ACCOUNT", accounts: data.accounts, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_FILTER_ACCOUNT", error: e });
        }
    },

    requestTotalCashBankBalance: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "REQUEST_BANK_TOTAL" });
        const appState = getState();
        try {
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ accountTotalSummary: IBankTotal }>(`accountTotalSummary(accountId:$accountId,onlyTotal:$onlyTotal){accountId, debit, credit, lastUpdate}`, ["$accountId:ID!", "$onlyTotal:Boolean"], { accountId: AccountType.Bank, onlyTotal: true }, authData);
            const data2 = await graphQl.executeQuery<{ accountTotalSummary: IBankTotal }>(`accountTotalSummary(accountId:$accountId,onlyTotal:$onlyTotal){accountId, debit, credit, lastUpdate}`, ["$accountId:ID!", "$onlyTotal:Boolean"], { accountId: AccountType.Cash, onlyTotal: true }, authData);

            dispatch({ type: "RESPOND_BANK_TOTAL", cash: data2.accountTotalSummary, bank: data.accountTotalSummary, error: null });
        } catch (e) {
            console.log(e.response || e);
            dispatch({ type: "RESPOND_BANK_TOTAL", error: e });
        }
    },





    requestAccountTotalBalance: (accountId: number, forTwoMonths: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();

        try {
            dispatch({ type: "REQUEST_ACCOUNT_SUMMARY" });
            const authData = appState.authorization!.authData;


            if (!forTwoMonths) {
                const res = await graphQl.executeQuery<{ accountTotalSummary: IBankTotal }>(`accountTotalSummary(accountId:$accountId,onlyTotal:$onlyTotal){accountId, debit, credit, lastUpdate}`, ["$accountId:ID!", "$onlyTotal:Boolean"], { accountId: accountId, onlyTotal: true }, authData);
                dispatch({ type: "RESPOND_ACCOUNT_SUMMARY", payload: res.accountTotalSummary, error: null });
            } else {
                let date = new Date();
                let lastMonthFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
                let lastMonthTo = new Date(date.getFullYear(), date.getMonth(), 0, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
                
                const res1 = graphQl.executeQuery<{ accountTotalSummary: IBankTotal }>(`accountTotalSummary(accountId:$accountId,onlyTotal:$onlyTotal,fromDate:$fromDate,toDate:$toDate,exactRange:$exactRange){accountId, debit, credit, lastUpdate}`, ["$accountId:ID!", "$onlyTotal:Boolean", "$fromDate:Date", "$toDate:Date", "$exactRange:Boolean"], { accountId: accountId, onlyTotal: true, fromDate: lastMonthFrom, toDate: lastMonthTo, exactRange: true }, authData);
                
                let thisMonthFrom = new Date(date.getFullYear(), date.getMonth(), 1, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
                let thisMonthTo = date;
                
                const res2 = graphQl.executeQuery<{ accountTotalSummary: IBankTotal }>(`accountTotalSummary(accountId:$accountId,onlyTotal:$onlyTotal,fromDate:$fromDate,toDate:$toDate,exactRange:$exactRange){accountId, debit, credit, lastUpdate}`, ["$accountId:ID!", "$onlyTotal:Boolean", "$fromDate:Date", "$toDate:Date", "$exactRange:Boolean"], { accountId: accountId, onlyTotal: true, fromDate: lastMonthFrom, toDate: lastMonthTo, exactRange: true }, authData);

                let res = await Promise.all([res1, res2]);

                dispatch({ type: "RESPOND_ACCOUNT_SUMMARY", payload: {accountId: res[0].accountTotalSummary.accountId, data: res.map(a => a.accountTotalSummary)}, error: null });
            }
        
        } catch (e) {
            dispatch({ type: "RESPOND_ACCOUNT_SUMMARY", payload:null, error: e });
        }
    },

    requestGetCashBankJournals: (journalType: number, fromDate: Date, toDate: Date, onlySupplier? : boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_CASH_BANK_JOURNALS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ receipts: Array<ICashBankRecipt> }>(
                `receipts:cashBankJournals(journalType: $journalType, fromDate: $fromDate, toDate: $toDate, onlySupplier:$onlySupplier) {  accountName amount balance date notes paymentAccountName jvn } 
                `
                , [`$journalType:Int!`, `$fromDate:Date!`, `$toDate:Date!`, "$onlySupplier:Boolean"], { journalType, fromDate, toDate, onlySupplier }, authData);
            dispatch({ type: "RESPOND_GET_CASH_BANK_JOURNALS", payload: data.receipts, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_CASH_BANK_JOURNALS", payload: null, error: e });
        }
    },

    requestGetJournalsByJvn: (jvn: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_JOURNALS_BY_JVN" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ receipts: Array<IJournalModel> }>(`receipts:journalsByVoucharNo(jvn: $jvn) { account{name} amount comments jvn type voucher voucherNo voucherDate }`, [`$jvn:Int!`], { jvn }, authData);
            dispatch({ type: "RESPOND_GET_JOURNALS_BY_JVN", payload: data.receipts, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_JOURNALS_BY_JVN", payload: null, error: e });
        }
    },

    requestAddJournalEnty: (journals: Array<IJournalModel>): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_JOURNAL_ENTRY" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<any>(`result:journalEntry(journals:$journals){ needReceipt voucherNo }`, [`$journals: [JournalInputType]!`], { journals }, authData);
            dispatch({ type: "RESPOND_ADD_JOURNAL_ENTRY", payload: data.result, error: null });
            dispatch({ type: "RESET_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_JOURNAL_ENTRY", error: e });
        }
    },

    requestAddAccount: (accountInfo: IAccountModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_ACCOUNT" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addAccount: IAccountModel }>("addAccount(accountInfo:$accountInfo){ name }", ["$accountInfo:AccountModelInputType!"], { accountInfo }, authData);
            dispatch({ type: "RESPOND_ADD_ACCOUNT", payload: data.addAccount, error: null });
            dispatch({ type: "RESET_ACTION" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_ACCOUNT", payload: null, error: e });
        }
    },

    requestJournalDetails: (params: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {

            dispatch({ type: "REQUEST_JOURNAL_DETAILS" });

            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<any>
                ("journalDetails(accountId:$accountId,fromDate:$fromDate,toDate:$toDate,isDetail:$isDetail,isCorporateClient:$isCorporateClient,voucherNo:$voucherNo){account{name,willHaveChild,id, children {id,name,willHaveChild},createDate}, isDetail,isSpecial, openingBalance{credit, debit},journals{account{name,willHaveChild, id,children {id,name},openingBalanceJournal{debit,credit}},accountId,voucherDate voucher voucherNo debit,credit,isOpeningBalance,id,type,amount,comments}}", ["$accountId: ID", "$fromDate: Date", "$toDate: Date", "$isDetail: Boolean", "$isCorporateClient: Boolean", "$voucherNo: ID"], { accountId: params.accountId, fromDate: params.fromDate, toDate: params.toDate, isCorporateClient: params.isCorporateClient, isDetail: params.isDetail, voucherNo: params.voucherNo }, authData);
            dispatch({ type: "RESPOND_JOURNAL_DETAILS", payload: data.journalDetails, error: null });
            dispatch({ type: "RESET_ACTION" });
        } catch (e) {

        }

    },

    requestVehicleJournalHistoryByClient: (accountId: number, fromDate: Date, toDate: Date): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_VEHICLE_JOURNAL_HISTORY" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ history: IVehicleJournalHistory }>("history:vehiclesSalesHistory(accountId:$accountId,fromDate:$fromDate,toDate:$toDate){ client { id name parent{  id name } } vehicleSalesHistory { serviceCount  paymentAmount sales  vehicle { id licenseNo } } }", ["$accountId:Int!,$fromDate:Date,$toDate:Date"], { accountId, fromDate, toDate }, authData);
            dispatch({ type: "RESPOND_VEHICLE_JOURNAL_HISTORY", payload: data.history, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_VEHICLE_JOURNAL_HISTORY", payload: null, error: e });
        }
    },

    resetAccounts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "RESET_ACTION" });
    }
}

const unloadedState: AccountState = {
    accounts: [],
    paymentAccounts: [],
    error: null,
    isLoading: false,
    isJournalPosting: false,
    isJournalPosted: false,
    journals: null,
    reciptWrapper: null,
    accountSummary: {},
    isAccountPosting: false,
    isAccountPosted: false,
    account: null,
    journalModel: null,
    vehicleJournalHistory: null,
    cashBankReceipts: [],
    journalList:[]
}


export const reducer: Reducer<AccountState> = (state: AccountState | undefined, incomingAction: Action): AccountState => {

    if (state === undefined) {
        return { ...unloadedState };
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_ACCOUNT":
            return {
                ...state,
                isLoading: true,
                isJournalPosting: false,
                isJournalPosted: false,
                isAccountPosting: false,
                isAccountPosted: false,
            }
        case "RESPOND_ACCOUNT":
            return {
                ...state,
                accounts: action.accounts,
                isLoading: false,
                error: action.error
            }

        case "REQUEST_PAYMENT_ACCOUNT":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_PAYMENT_ACCOUNT":
            return {
                ...state,
                paymentAccounts: action.paymentAccounts,
                isLoading: false,
                error: action.error
            }
        case "REQUEST_FILTER_ACCOUNT":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_FILTER_ACCOUNT":
            return {
                ...state,
                accounts: action.accounts,
                isLoading: false,
                error: action.error
            }

        case "REQUEST_GET_CASH_BANK_JOURNALS":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_CASH_BANK_JOURNALS":
            return {
                ...state,
                cashBankReceipts: action.payload,
                isLoading: false,
                error: action.error
            }

        case "REQUEST_GET_JOURNALS_BY_JVN":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_JOURNALS_BY_JVN":
            return {
                ...state,
                journalList: action.payload,
                isLoading: false,
                error: action.error
            }

        case "REQUEST_ADD_JOURNAL_ENTRY":
            return {
                ...state,
                isJournalPosting: true
            }

        case "RESPOND_ADD_JOURNAL_ENTRY":
            return {
                ...state,
                isJournalPosting: false,
                isJournalPosted: true,
                journalEntryResult: action.payload,
                error: action.error
            }

        case "REQUEST_BANK_TOTAL":
            {
                return {
                    ...state,
                    isLoading: true,
                    isJournalPosted: false,
                    isJournalPosting: false
                }
            }

        case "RESPOND_BANK_TOTAL":
            {
                return {
                    ...state,
                    cash: action.cash,
                    bank: action.bank,
                    isLoading: false,
                    isJournalPosted: false,
                    isJournalPosting: false
                }
            }
        case "REQUEST_ACCOUNT_SUMMARY":
            {
                return {
                    ...state,
                    isLoading: true,
                    isJournalPosted: false,
                    isJournalPosting: false
                }
            }


        case "RESPOND_ACCOUNT_SUMMARY":
            {
                if (!action.payload) { // error in API
                    return {
                        ...state,
                        error: action.error
                    }
                }
                const summary = {};
                let nm = Object.keys(AccountType).find(a => AccountType[a] === action.payload.accountId);
                if (typeof nm === "undefined") {
                    nm = `acc_${action.payload.accountId}`;
                }
                if (action.payload.data) {
                    summary[nm + "_last"] = action.payload.data[0];
                    summary[nm + "_this"] = action.payload.data[1];
                } else {
                    summary[nm] = action.payload;
                }
                return {
                    ...state,
                    accountSummary: summary
                };
                
            }

        case "REQUEST_VEHICLE_JOURNAL_HISTORY":
            return {
                ...state,
                isLoading: true,
                vehicleJournalHistory: null
            }

        case "RESPOND_VEHICLE_JOURNAL_HISTORY":
            return {
                ...state,
                isLoading: false,
                vehicleJournalHistory: action.payload
            }


        case "REQUEST_ADD_ACCOUNT":
            {
                return {
                    ...state,
                    isAccountPosting: true,
                }
            }

        case "RESPOND_ADD_ACCOUNT":
            {
                return {
                    ...state,
                    isAccountPosting: false,
                    isAccountPosted: action.payload != null,
                    account: action.payload,
                    error: action.error
                }
            }

        case "RESPOND_JOURNAL_DETAILS": {

            return {
                ...state,
                journalModel: action.payload,
                error: action.error,
                isLoading: false
            }
        }


        case "RESET_ACTION":
            return {
                ...state,
                isLoading: false,
                accounts: [],
                isJournalPosted: false,
                journalEntryResult: null
            }

        default:
            return state;
    }
}
