import * as React from "react";
import {Location} from 'history';
import {MeasuringUnits} from "../../common/Constants";
import {match} from "react-router";
import NumberFormat from 'react-number-format';
import {connect} from "react-redux";
import {ApplicationState} from "../../store/index";
import "../../assets/css/invoice/sales-invoice-print.css";
import * as Account from "../../store/Account";
import * as Vehicle from "../../store/Vehicle";
import * as Qoutation from "../../store/Qoutation";


import {Link} from "react-router-dom";


interface CorporateClientPrintProps {
    match: match<{ accountId?: string }>,
    location: Location
}

interface CorporateClientPrintState {
    toDate: Date,
    fromDate: Date,
    vehicleId: number
}

type props = CorporateClientPrintProps & Account.AccountState & typeof Account.actionCreators & Vehicle.IVehicleState & Qoutation.IQoutationState & typeof Qoutation.actionCreators & typeof Vehicle.actionCreators;

class VehicleSalesHistoryPrint extends React.PureComponent<props, CorporateClientPrintState> {

    state = {
        toDate: new Date(),
        fromDate: new Date(),
        vehicleId: 0
    };

    componentDidMount(): void {
        const qp = new URLSearchParams(this.props.location.search);
        const accountId = parseInt(this.props.match.params["accountId"]) || -12300;
        let fromDate = new Date(qp.get("fromDate"));//new Date(date.setDate(date.getDate() - 1));
        let toDate = new Date(qp.get("toDate"));
        let vehicleId = parseInt(qp.get("vehicleId")) || 0;
        if (vehicleId > 0) {
            this.props.requestGetVehicleById(vehicleId);
            this.props.requestGetSalesInvoicesByDate(vehicleId, accountId, fromDate, toDate, 1, 100);
        } else {
            this.props.removeSalesInvoice();
            this.props.requestVehicleJournalHistoryByClient(accountId, fromDate, toDate);
        }
        this.setState({
            fromDate: fromDate,
            toDate: toDate,
            vehicleId: vehicleId
        })
    }

    // componentWillUnmount(): void {
    //     this.props.resetJournals();
    // }

    


    


    _renderInvoice(props: CorporateClientPrintProps) {
        const {vehicleJournalHistory, salesInvoices, vehicle } = this.props;
        if (!vehicleJournalHistory && !salesInvoices && !vehicle) return;
        const isVehicleHistoryExits = vehicleJournalHistory && vehicleJournalHistory.vehicleSalesHistory && vehicleJournalHistory.vehicleSalesHistory.length > 0;
        let table = "";
        if (isVehicleHistoryExits && !salesInvoices) {
            table = "vehicles"
        } else if (vehicle && this.state.vehicleId !== 0) {
            table = "invoices"
        }
        let rows = []; //this.generateRows(journalModel);
        if (table === "vehicles" && vehicleJournalHistory && vehicleJournalHistory.vehicleSalesHistory) {
            rows = vehicleJournalHistory.vehicleSalesHistory;
        } else if (table === "invoices" && vehicle && this.state.vehicleId !== 0 && salesInvoices) {
            rows = salesInvoices.items
        }
        let rowsChunk = [];
        const chunk = 30;

        let i, j;

        for (i = 0, j = rows.length; i < j; i += chunk) {

            rowsChunk.push(rows.slice(i, i + chunk));
        }

        let partIndex = 0;
        return (
            <React.Fragment>
                <div style={{height: 100}}/>
                {table === "vehicles" &&
                    <div style={{ position: "relative" }}>
                    <div style={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", fontSize: "35px", marginBottom:"20px"}}>Vehicle Sales Report</p>
                    </div>
                    <div style={{textAlign: "left"}}>
                        <p style={{fontSize: "15px" }}>Vehicle Registrated Under: <b>{vehicleJournalHistory && vehicleJournalHistory.client && vehicleJournalHistory.client.name /*+ (vehicleJournalHistory.client.parent ? ` ( ${vehicleJournalHistory.client.parent.name})` : "")*/}</b></p>
                    </div>
                   
                    <div style={{textAlign: "left"}}>
                        <p style={{ fontSize: "15px" }}>From: <b>{this.state.fromDate.toDateString()}</b></p>
                        <p style={{ fontSize: "15px" }}>To: <b>{this.state.toDate.toDateString()}</b></p>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <hr/>
                    </div>

                    <div style={{clear: "both"}}></div>


                    {rowsChunk.map((item, mainIndex) => {
                        partIndex += rowsChunk[mainIndex].length;

                        return <div key={mainIndex}
                                    style={{pageBreakAfter: mainIndex < rowsChunk.length - 1 || rowsChunk[rowsChunk.length - 1].length > 30 ? "always" : "avoid"}}>

                            <table className="tblData">
                                <tbody>
                                    <tr className="erp-table-header" style={{textTransform: "uppercase"}}>
                                        <th>#</th>
                                        <th>Vehicle Identity</th>
                                        <th>Total Number of Service</th>
                                        <th>Sales</th>
                                        <th>Due</th>
                                        <th>Total Amount</th>
                                    </tr> 
                                {item.map((s, index) => (
                                    <tr key={index}>
                                        <td className="th-td-center">{index + 1}</td>
                                        <td className="th-td-center">{s.vehicle && s.vehicle.licenseNo}</td>
                                        <td className="th-td-center">{s.serviceCount}</td>
                                        <td className="th-td-left"><NumberFormat value={s.sales} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div style={{ marginLeft: "10px" }}>{value}</div>} /></td>
                                        <td className="th-td-left"><NumberFormat value={s.sales - s.paymentAmount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div style={{ marginLeft: "10px" }}>{value}</div>} /></td>
                                        <td className="th-td-left"><NumberFormat value={s.sales} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div style={{ marginLeft: "10px" }}>{value}</div>} /></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    })
                    }
                    <div style={{ float: "right" }}>
                        <table className="tblDue erp-table-head">
                            <tbody>
                                <tr>
                                    <th>Total Amount: </th>
                                    <td>   <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                    </td>
                                </tr>

                                <tr>
                                    <th>(-)Discount: </th>
                                    <td> <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Tax: </th>
                                    <td> <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Vat: </th>
                                    <td> <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Total Payble: </th>
                                    <td><NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                }
                {table === "invoices" &&
                <div style={{position: "relative"}}>
                    <div style={{textAlign: "center"}}>
                        <p style={{ fontWeight: "bold", fontSize: "35px", marginBottom: "20px" }}>Vehicle Invoice Report</p>

                    </div>
                    <div style={{textAlign: "left"}}>
                        <p style={{ fontSize: "15px" }}>Client Name:<b> {vehicle.client && vehicle.client.name}</b></p>
                        <p style={{ fontSize: "15px" }}>License No: <b>{`${vehicle.licenseNo}`}</b></p>
                    </div>
                    <div style={{textAlign: "left"}}>
                        <p style={{ fontSize: "15px" }}>From: <b>{this.state.fromDate.toDateString()}</b></p>
                        <p style={{ fontSize: "15px" }}>To: <b>{this.state.toDate.toDateString()}</b></p>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <hr/>
                    </div>

                    <div style={{clear: "both"}}></div>


                    {rowsChunk.map((item, mainIndex) => {
                        partIndex += rowsChunk[mainIndex].length;

                        return <div key={mainIndex}
                                    style={{pageBreakAfter: mainIndex < rowsChunk.length - 1 || rowsChunk[rowsChunk.length - 1].length > 30 ? "always" : "avoid"}}>

                            <table className="tblData">
                                <tbody>
                                <tr className="erp-table-header" style={{textTransform: "uppercase"}}>
                                    <th>#</th>
                                    <th>Invoice No</th>
                                    <th>Quotation No</th>
                                    <th>Service Date</th>
                                    <th>Invoice Total</th>
                                    <th>Special Discount</th>
                                    <th>Due</th>
                                </tr>
                                {item.map((s, index) => (
                                    <tr key={index}>
                                        <td className="th-td-center">{index + 1}</td>
                                        <td className="th-td-center">{s.invoiceCode}</td>
                                        <td className="th-td-center">{s.qoutation && `Q-000${s.qoutation.qtNo}`}</td>
                                        <td className="th-td-center">{s.salesDate}</td>
                                        <td className="th-td-left" ><NumberFormat value={s.total} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div style={{ marginLeft: "10px" }}>{value}</div>} /></td>
                                        <td className="th-td-left" ><NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div style={{ marginLeft: "10px" }}>{value}</div>} /></td>
                                        <td className="th-td-left" ><NumberFormat value={s.total - s.paymentAmount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div style={{ marginLeft: "10px" }}>{value}</div>} /></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    })
                    }

                    <div style={{ float: "right" }}>
                        <table className="tblDue erp-table-head">
                            <tbody>
                                <tr>
                                    <th>Total Amount: </th>
                                    <td>   <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                    </td>
                                </tr>

                                <tr>
                                    <th>(-)Discount: </th>
                                    <td> <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Tax: </th>
                                    <td> <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Vat: </th>
                                    <td> <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Total Payble: </th>
                                    <td><NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                }
            </React.Fragment>
        );
    }

    render() {

        return (
            <React.Fragment>
                {this._renderInvoice(this.props)}

            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => {
        return {...state.account, ...state.vehicle, ...state.qoutation}
    }, {...Account.actionCreators, ...Qoutation.actionCreators, ...Vehicle.actionCreators}
)(VehicleSalesHistoryPrint as any);

