import { IRoute, RouteBuilder } from "../models/RouteModel";
import Login from "../components/pages/Login";
import Signup from "../components/pages/Signup";
import Counter from "../components/pages/Counter";
import FetchData from "../components/pages/FetchData";


const routes: IRoute[] = [  
    RouteBuilder.getBuilder(1).setPath("/login").setComponent(Login).build(),
    RouteBuilder.getBuilder(2).setPath("/signup").setComponent(Signup).build(),
    RouteBuilder.getBuilder(3).setPath("/counter").setComponent(Counter).build(),
    RouteBuilder.getBuilder(4).setPath("/fetch-data/:startDateIndex").setComponent(FetchData).build()
    
];

export default routes;