export interface IRoute {
    id: number,
    path: string,
    exact: boolean,
    roles: Array<string>,
    title: Object | string,
    icon: Object | string,
    isLink: boolean,
    isPrivate: boolean,
    component: Object | null,
    children: IRoute[] | null,
    redirect?: boolean;
}
export interface IRouteBuilder {

    setPath(value: string): IRouteBuilder;
    setComponent(comp: Object): IRouteBuilder;
    setTitle(value: Object): IRouteBuilder;
    setIcon(value: Object): IRouteBuilder;
    setRole(value: Array<string>): IRouteBuilder;
    setIsExact(value: boolean): IRouteBuilder;
    setIsLink(value: boolean): IRouteBuilder;
    setIsPrivate(value: boolean): IRouteBuilder;
    setChildren(value: IRoute[]): IRouteBuilder;

    build(): IRoute;
}
export class RouteBuilder implements IRouteBuilder {

    private constructor(private route: IRoute) { }

    public static getBuilder(id: number): IRouteBuilder {
        return new RouteBuilder(new Route(id));
    }

    setPath(value: string): IRouteBuilder {
        this.route.path = value;
        return this;
    }
    
    setRole(value: Array<string>): IRouteBuilder {
        this.route.roles = value;
        return this;
    }

    setComponent(comp: Object): IRouteBuilder {
      
        this.route.component = comp;
        return this;
    }

    setTitle(value: Object): IRouteBuilder {
        this.route.title = value;
        return this;
    }

    setIcon(value: Object): IRouteBuilder {
        this.route.icon = value;
        return this;
    }

    setIsExact(value: boolean): IRouteBuilder {
        this.route.exact = value;
        return this;
    }

    setIsLink(value: boolean): IRouteBuilder {
        this.route.isLink = value;
        return this;
    }

    setIsPrivate(value: boolean): IRouteBuilder {
        this.route.isPrivate = value;
        return this;
    }

    setChildren(value: IRoute[]): IRouteBuilder {
        this.route.children = value;
        return this;
    }

    build(): IRoute {
        return this.route;
    }

}
class Route implements IRoute {

    constructor(
        public id: number,
        public path: string = "",
        public roles: Array<string> = ["super_admin", "admin", "admin_op", "admin_st", "admin_ac", "executive_ac"], // common. all users can view
        public component: Object | null = null,
        public title: Object | string = "",
        public icon: Object | string = "",
        public exact: boolean = false,
        public isLink: boolean = false,
        public isPrivate: boolean = false,
        public children: IRoute[] | null = null,
        public redirect?: boolean) {
        this.redirect = false;
    }
}

