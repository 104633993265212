import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import {
    IProductCategoryModel,
    IProductModel,
    IProductBrand,
    IProductModelModel,
    IInventorySpareProduct
} from "../models/ProductModel";
import graphQl from "../services/GraphQL";
import { IProductStockItemModel, IProductStockItemWrapper, IProductStockItemCount } from "../models/ProductStock";


export interface IInventoryState {
    product: IProductModel | null,
    fetchError: Error | null,
    productStock: IProductStockItemModel | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    productCategories: Array<IProductCategoryModel> | null,
    products: Array<IProductModel> | null,
    spareProducts: Array<IInventorySpareProduct> | null,
    productBrands: Array<IProductBrand> | null
    productBrand: IProductBrand | null
    productModels: Array<IProductModelModel> | null
    productStockItem: IProductStockItemModel | null
    productStockItemWrapper: IProductStockItemWrapper | null,
    productStockItemCountByModel: IProductStockItemCount | null,
    inventoryUpdated: boolean
}


//Request GET actions
export interface RequestGetProduct extends Action<string> {
    type: "REQUEST_GET_PRODUCT";
}


export interface RespondGetProduct extends Action<string> {
    type: "RESPOND_GET_PRODUCT";
    payload: IProductModel | null;
    error: Error | null
}

export interface RequestGetProducts extends Action<string> {
    type: "REQUEST_GET_PRODUCTS";
}


export interface RespondGetProducts extends Action<string> {
    type: "RESPOND_GET_PRODUCTS";
    payload: Array<IProductModel> | null;
    error: Error | null
}


export interface IResetProduct extends Action<string> {
    type: "RESET_PRODUCT";
}



export interface IRequestProductBrands {
    type: "REQUEST_GET_PRODUCT_BRANDS"
}

export interface IRespondProductBrands {
    type: "RESPOND_GET_PRODUCT_BRANDS";
    payload: Array<IProductBrand> | null;
    error: Error | null
}

export interface IRequestGetOtherProductStock {
    type: "REQUEST_GET_OTHER_PRODUCT_STOCK"
}

export interface IIRespondGetOtherProductStock {
    type: "RESPOND_GET_OTHER_PRODUCT_STOCK";
    payload: IProductStockItemModel | null;
    error: Error | null
}

export interface IRequestGetInventorySpareProducts {
    type: "REQUEST_GET_SPARE_PRODUCTS"
}

export interface IRespondGetInventorySpareProducts {
    type: "RESPOND_GET_SPARE_PRODUCTS";
    payload: Array<IInventorySpareProduct> | null;
    error: Error | null
}

export interface IRequestAddOtherProductStock {
    type: "REQUEST_ADD_OTHER_PRODUCT_STOCK"
}

export interface IIRespondAddOtherProductStock {
    type: "RESPOND_ADD_OTHER_PRODUCT_STOCK";
    payload: IInventorySpareProduct | null;
    error: Error | null
}

export interface IRequestProductInventoryItems {
    type: "REQUEST_GET_PRODUCT_INVENTORY_ITEMS"
}

export interface IRespondProductInventoryItems {
    type: "RESPOND_GET_PRODUCT_INVENTORY_ITEMS";
    payload: IProductStockItemWrapper | null;
    error: Error | null
}

export interface IRequestAddProductBrand {
    type: "REQUEST_ADD_PRODUCT_BRAND"
}

export interface IRespondAddProductBrand {
    type: "RESPOND_ADD_PRODUCT_BRAND";
    payload: IProductBrand | null;
    error: Error | null
}
export interface IRequestAddProductModels {
    type: "REQUEST_ADD_PRODUCT_MODELS"
}

export interface IRequestAddProductInventoryItem {
    type: "REQUEST_ADD_PRODUCT_INVENTORY_ITEM"
}

export interface IRespondAddProductModels {
    type: "RESPOND_ADD_PRODUCT_MODELS";
    payload: Array<IProductModelModel> | null;
    error: Error | null
}

export interface IRespondAddProductInventoryItem {
    type: "RESPOND_ADD_PRODUCT_INVENTORY_ITEM";
    payload: IProductStockItemModel | null;
    error: Error | null
}

export interface IRequestProductModels {
    type: "REQUEST_GET_PRODUCT_MODELS"
}

export interface IRespondProductModels {
    type: "RESPOND_GET_PRODUCT_MODELS";
    payload: Array<IProductModelModel> | null;
    error: Error | null
}


export interface IRequestProductStockItemCountByModel {
    type: "REQUEST_GET_PRODUCT_STOCK_ITEM_COUNT_BY_MODEL"
}

export interface IRespondProductStockItemCountByModel {
    type: "RESPOND_GET_PRODUCT_STOCK_ITEM_COUNT_BY_MODEL";
    payload: IProductStockItemCount;
    error: Error | null
}


type KnownAction = RequestGetProducts |
    RespondGetProducts |
    RequestGetProduct |
    RespondGetProduct |
    IRequestProductInventoryItems |
    IRespondProductInventoryItems |
    IRequestProductModels |
    IRespondProductModels |
    IRequestGetOtherProductStock |
    IIRespondGetOtherProductStock |
    IRequestGetInventorySpareProducts |
    IRespondGetInventorySpareProducts |
    IRequestAddOtherProductStock |
    IIRespondAddOtherProductStock |
    IRequestAddProductModels |
    IRespondAddProductModels |
    IRequestProductBrands |
    IRequestAddProductBrand |
    IRespondAddProductBrand |
    IRespondProductBrands |
    IRequestAddProductInventoryItem |
    IRespondAddProductInventoryItem |
    IRequestProductStockItemCountByModel |
    IRespondProductStockItemCountByModel |
    IResetProduct

export const actionCreators = {

    requestGetProduct: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCT" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ product: IProductModel }>("product(id:$id){ productId name code isService category{name} }", [`$id:Int`], { id }, authData);
            dispatch({ type: "RESPOND_GET_PRODUCT", payload: data.product, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCT", payload: null, error: e });
        }
    },
    requestGetProducts: (keyword: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCTS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ products: Array<IProductModel> }>("products:searchProducts(keyword:$keyword){id name code isService category{name} productModels{ id name productId}}", [`$keyword:String`], { keyword }, authData);
            dispatch({ type: "RESPOND_GET_PRODUCTS", payload: data.products, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCTS", payload: null, error: e });
        }
    },

    requestGetProductBrands: (name: string, all: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCT_BRANDS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ productBrands: Array<IProductBrand> }>("productBrands(name:$name, all:$all){ id name active }", [`$name:String`, `$all:Boolean`], { name, all }, authData);
            dispatch({ type: "RESPOND_GET_PRODUCT_BRANDS", payload: data.productBrands, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCT_BRANDS", payload: null, error: e });
        }
    },

    requestGetProductInventoryItems: (productName: string, supplier: string, page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCT_INVENTORY_ITEMS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ productStockItems: IProductStockItemWrapper }>("productStockItems(productName:$productName, supplier:$supplier,page:$page,pageSize:$pageSize){page pageSize totalCount items{ id stockId productId brandId productModelId supplierId originalQty voucherNo createDate quantity costPrice salesPrice notes paymentAmount  paymentAccountId supplier{id name} productModel{id name}  product{id name} brand{id name} } }", [`$productName:String`, `$supplier:String`, `$page:Int!`, `$pageSize:Int!`], { page, pageSize, productName, supplier }, authData);
            dispatch({ type: "RESPOND_GET_PRODUCT_INVENTORY_ITEMS", payload: data.productStockItems, error: null });
            dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCT_INVENTORY_ITEMS", payload: null, error: e });
        }
    },

    requestAddProductBrand: (brand: IProductBrand): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_PRODUCT_BRAND" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ productBrand: IProductBrand }>("productBrand:addProductBrand(productBrand:$productBrand){ id name active }", [`$productBrand:ProductInputBrandType!`], { productBrand: brand }, authData);
            dispatch({ type: "RESPOND_ADD_PRODUCT_BRAND", payload: data.productBrand, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_PRODUCT_BRAND", payload: null, error: e });
        }
    },

    requestAddInventoryItem: (inventoryItem: IProductStockItemModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_PRODUCT_INVENTORY_ITEM" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ stockItem: IProductStockItemModel }>("stockItem:addInventory(productStockItem:$productStockItem){ id stockId productId supplierId productModelId }", [`$productStockItem:ProductStockItemInputType!`], { productStockItem: inventoryItem }, authData);
            dispatch({ type: "RESPOND_ADD_PRODUCT_INVENTORY_ITEM", payload: data.stockItem, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_PRODUCT_INVENTORY_ITEM", payload: null, error: e });
        }
    },

    requestGetProductModels: (productId: number, keyword?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCT_MODELS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ productModels: Array<IProductModelModel> }>("productModels(keyword:$keyword, productId:$productId){ id name }", [`$keyword:String`, `$productId:Int`], { keyword, productId }, authData);
            dispatch({ type: "RESPOND_GET_PRODUCT_MODELS", payload: data.productModels, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCT_MODELS", payload: null, error: e });
        }
    },

    requestGetOtherProductStock: (productId: number, modelId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_OTHER_PRODUCT_STOCK" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ productStock: IProductStockItemModel }>("productStock:productStockItemById(productId:$productId, modelId:$modelId){ productId productModelId quantity }", [`$productId:Int`, "$modelId:Int!"], { productId, modelId }, authData);
            dispatch({ type: "RESPOND_GET_OTHER_PRODUCT_STOCK", payload: data.productStock, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_OTHER_PRODUCT_STOCK", payload: null, error: e });
        }
    },

    requestInvoiceSpareItems: (invoiceId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SPARE_PRODUCTS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ spareProducts: Array<IInventorySpareProduct> }>("spareProducts:inventorySpareProducts(invoiceId:$invoiceId){ productId productModelId quantity voucher totalCost product{name} productModel{name} }", ["$invoiceId:Int!"], { invoiceId }, authData);
            dispatch({ type: "RESPOND_GET_SPARE_PRODUCTS", payload: data.spareProducts, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_OTHER_PRODUCT_STOCK", payload: null, error: e });
        }
    },

    requestAddOtherProductStock: (productId: number, modelId: number, invoiceId: number, quantity: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_OTHER_PRODUCT_STOCK" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ productStock: IInventorySpareProduct }>("productStock:otherProductStockUpdate(productId:$productId, modelId:$modelId, invoiceId:$invoiceId, quantity:$quantity){ productId productModelId quantity voucher totalCost product{name} productModel{name} }", [`$productId:Int!`, "$modelId:Int!", "$invoiceId:Int!", "$quantity:Int!"], { productId, modelId, invoiceId, quantity }, authData);
            dispatch({ type: "RESPOND_ADD_OTHER_PRODUCT_STOCK", payload: data.productStock, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_OTHER_PRODUCT_STOCK", payload: null, error: e });
        }
    },

    requestGetProductStockItemCountByModel: (productId: number, productModelId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCT_STOCK_ITEM_COUNT_BY_MODEL" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ count: IProductStockItemCount }>("count:getProductStockQuantityByModel(productModelId:$productModelId, productId:$productId){ productModelId productId count  }", [`$productModelId:Int!`, `$productId:Int!`], { productModelId, productId }, authData);
            dispatch({ type: "RESPOND_GET_PRODUCT_STOCK_ITEM_COUNT_BY_MODEL", payload: data.count, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCT_STOCK_ITEM_COUNT_BY_MODEL", payload: null, error: e });
        }
    },

    requestAddProductModel: (productModels: Array<IProductModelModel>): AppThunkAction<KnownAction> => async (dispatch, getState) => {

        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_PRODUCT_MODELS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ productModels: Array<IProductModelModel> }>("productModels:addProductModels(productModels:$productModels){ id name productId active }", [`$productModels:[ProductModelInputType]!`], { productModels }, authData);
            dispatch({ type: "RESPOND_ADD_PRODUCT_MODELS", payload: data.productModels, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_PRODUCT_MODELS", payload: null, error: e });
        }
    }
}

const unloadedState: IInventoryState = {
    product: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isPosted: false,
    productCategories: null,
    products: null,
    isLoaded: false,
    productBrand: null,
    productBrands: [],
    productModels: [],
    spareProducts: [],
    productStockItem: null,
    productStockItemWrapper: null,
    productStockItemCountByModel: null,
    inventoryUpdated: false,
    productStock: null,
}


export const reducer: Reducer<IInventoryState> = (state: IInventoryState | undefined, incomingAction: Action): IInventoryState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {

        case "REQUEST_GET_PRODUCTS":
            return {
                ...state,
                isLoading: true
            }


        case "RESPOND_GET_PRODUCTS":
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                products: action.payload,
                fetchError: action.error

            }
        case "REQUEST_GET_PRODUCT":
            return {
                ...state,
                isLoading: true
            }


        case "RESPOND_GET_PRODUCT":
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                product: action.payload,
                fetchError: action.error
            }

        case "REQUEST_GET_PRODUCT_BRANDS":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false

            }

        case "REQUEST_GET_OTHER_PRODUCT_STOCK":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false

            }

        case "RESPOND_GET_OTHER_PRODUCT_STOCK":
            return {
                ...state,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false,
                productStock: action.payload
            }

        case "REQUEST_GET_SPARE_PRODUCTS":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false, spareProducts: []

            }

        case "RESPOND_GET_SPARE_PRODUCTS":
            return {
                ...state,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false,
                spareProducts: action.payload
            }

        case "REQUEST_ADD_OTHER_PRODUCT_STOCK":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false, productStock: null

            }

        case "RESPOND_ADD_OTHER_PRODUCT_STOCK":
            return {
                ...state,
                fetchError: action.error,
                isLoaded: false,
                isLoading: false,
                productStock: null,
                spareProducts: [...state.spareProducts, action.payload]
            }

        case "RESPOND_GET_PRODUCT_BRANDS":
            return {
                ...state,
                productBrands: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }

        case "REQUEST_GET_PRODUCT_INVENTORY_ITEMS":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false

            }

        case "RESPOND_GET_PRODUCT_INVENTORY_ITEMS":

            return {
                ...state,
                productStockItemWrapper: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }

        case "REQUEST_ADD_PRODUCT_BRAND":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false

            }

        case "RESPOND_ADD_PRODUCT_BRAND":

            return {
                ...state,
                productBrand: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }

        case "REQUEST_ADD_PRODUCT_INVENTORY_ITEM":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false

            }

        case "RESPOND_ADD_PRODUCT_INVENTORY_ITEM":
            return {
                ...state,
                productStockItem: action.payload,
                inventoryUpdated: action.payload !== null,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }

        case "REQUEST_ADD_PRODUCT_MODELS":

            return {
                ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false

            }

        case "RESPOND_ADD_PRODUCT_MODELS":

            return {
                ...state,
                productModels: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }

        case "REQUEST_GET_PRODUCT_MODELS":

            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                isPosted: false,
                isPosting: false
            }

        case "RESPOND_GET_PRODUCT_MODELS":
            return {
                ...state,
                productModels: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }

        case "REQUEST_GET_PRODUCT_STOCK_ITEM_COUNT_BY_MODEL":

            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                isPosted: false,
                isPosting: false
            }

        case "RESPOND_GET_PRODUCT_STOCK_ITEM_COUNT_BY_MODEL":
            return {
                ...state,
                productStockItemCountByModel: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }

        case "RESET_PRODUCT":

            return {
                ...state,
                productStockItem: null,
                isLoaded: false,
                isLoading: false,
                isPosted: false,
                isPosting: false,
                inventoryUpdated:false
            }

        default:
            return state;
    }
}
