import * as React from "react";
import {Link,Redirect} from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Container, CardFooter, Label } from "reactstrap";
import { Input } from "../FormInputs/Input";
import "../../assets/css/login-register.css";

import * as Authorization from "../../store/Authorization";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import { ILoginModel } from "../../models/LoginModel";
import { Last } from "react-bootstrap/lib/Pagination";
import login from "../../assets/img/login_bg.jpg";
interface ILoginState {
    userName: string,
    password:string
}


type LoginProps = ILoginState & Authorization.IAuthorizationState & typeof Authorization.actionCreators 

class Login extends React.PureComponent<LoginProps, ILoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = {
            userName: "",
            password:""
        }   

        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }


    handleChange(event: React.ChangeEvent<HTMLInputElement>): void { 
        var target = event.target;
        var name = target.name;
        this.setState({
            [name]:target.value
        } as any)


    }

    handleLogin(event: React.FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        this.props.requestLogin(this.state.userName, this.state.password);
    }

    render() {
        const { isAuth, authData } = this.props
        if (isAuth && authData.roles) {
            return <Redirect to="/" />
        }

        const backgournd = {
            backgroundImage: `url(../../assets/img/login_bg.jpg)`
        }

        return (
            <div>
                <Container className="margin-lg-top-150 margin-sm-top-50">
               
                <Row>
                    <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }}>

                        <Card className="animated shake card-style">
                            <CardBody className="login" style={{padding:"0"}}>
                                <div>
                                    <CardHeader className="header-bg">
                                        <h4 className="login-title">Login</h4>
                                    </CardHeader>
                                    <div>
                                        <div className="box">
                                            <div className="content">
                                                {/*  <div className="social">
                                                    <a className="circle github" href="#">
                                                        <i className="fa fa-github fa-fw"></i>
                                                    </a>
                                                    <a id="google_login" className="circle google" href="#">
                                                        <i className="fa fa-google-plus fa-fw"></i>
                                                    </a>
                                                    <a id="facebook_login" className="circle facebook" href="#">
                                                        <i className="fa fa-facebook fa-fw"></i>
                                                    </a>
                                                </div>
                                                <div className="division">
                                                    <div className="line l"></div>
                                                    <span>or</span>
                                                    <div className="line r"></div>
                                                </div>*/}
                                                <div className="error"></div>
                                                <div className="form loginBox">
                                                    <form onSubmit={this.handleLogin} acceptCharset="UTF-8">
                                                        <Label>Email</Label>
                                                        <Input id="vendor_user" className="form-control" type="text" placeholder="Email" name="userName" onChange={this.handleChange} />
                                                        <Label>Password</Label>
                                                        <Input id="vendor_challenge" className="form-control" type="password" placeholder="Password" name="password" onChange={this.handleChange} />
                                                        <Input className="btn btn-default btn-login login-btn" type="submit" value="Login" />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CardFooter>
                                        <div className="forgot login-footer"><span>Looking to <Link to={"/signup"}>create an account</Link> ? </span></div>
                                    </CardFooter>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

            </Container>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => { return { ...state.authorization } }, { ...Authorization.actionCreators })(Login as any)