import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import { IProductCategoryModel, IProductModel, IProductBrand } from "../models/ProductModel";
import graphQl from "../services/GraphQL";


export interface IProductState {
    product: IProductModel | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    productCategories: Array<IProductCategoryModel> | null,
    products: Array<IProductModel> | null,
    productBrands: Array<IProductBrand> | null
}


//Request GET actions
export interface RequestGetProducts extends Action<string> {
    type: "REQUEST_GET_Products";
}

export interface RequestGetProductCategories extends Action<string> {
    type: "REQUEST_GET_Product_Categories";
}

export interface RequestGetProduct extends Action<string> {
    type: "REQUEST_GET_Product";
}
//Request ADD actions
export interface RequestAddOrUpdateProduct extends Action<string> {
    type: "REQUEST_ADD_UPDATE_Product";
}

export interface RequestGetProductCategory extends Action<string> {
    type: "REQUEST_GET_Product_Category";
}



export interface RespondGetProductCategories extends Action<string> {
    type: "RESPOND_GET_Product_Categories";
    payload: Array<IProductCategoryModel> | null;
    error: Error | null
}

export interface RespondGetProducts extends Action<string> {
    type: "RESPOND_GET_Product_MODELS";
    payload: Array<IProductModel> | null;
    error: Error | null
}

export interface RespondGetProduct extends Action<string> {
    type: "RESPOND_GET_Product";
    payload: IProductModel | null;
    error: Error | null
}

export interface RespondGetProductCategory extends Action<string> {
    type: "RESPOND_GET_PRODUCT_CATEGORY";
    payload: IProductCategoryModel | null;
    error: Error | null
}

export interface RespondAddOrUpdateVehicle extends Action<string> {
    type: "RESPOND_ADD_UPDATE_PRODUCT";
    payload: IProductModel | null;
    error: Error | null
}
export interface IResetProduct extends Action<string> {
    type: "RESET_PRODUCT";
}

export interface IRequestProductBrands {
    type: "REQUEST_GET_PRODUCT_BRANDS"
}

export interface IRespondProductBrands {
    type: "RESPOND_GET_PRODUCT_BRANDS";
    payload: Array<IProductBrand> | null;
    error: Error | null
}


type KnownAction = RequestGetProducts |
    RequestGetProductCategories |
    RequestGetProduct |
    RequestAddOrUpdateProduct |
    RequestGetProductCategory |
    RespondGetProductCategories |
    RespondGetProducts |
    RespondGetProduct |
    RespondGetProductCategory |
    RespondAddOrUpdateVehicle |
    IRequestProductBrands |
    IRespondProductBrands |
    IResetProduct

export const actionCreators = {
    requestAddOrUpdateProduct: (productInfo: IProductModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_Product" });
            const authData = appState.authorization!.authData;

            const data = await graphQl.executeMutation<{ addOrUpdateProduct: IProductModel }>("addOrUpdateProduct(productInfo:$productInfo){ productId name code categoryId }", ["$productInfo:ProductInputType!"], { productInfo }, authData);

            dispatch({ type: "RESPOND_ADD_UPDATE_PRODUCT", payload: data.addOrUpdateProduct, error: null });
            dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_PRODUCT", payload: null, error: e });
        }
    },

    requestGetProductCategories: (isService: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_Product_Categories" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ categories: Array<IProductCategoryModel> }>("categories(isService:$isService){ id name code }", ["$isService:Boolean!"], { isService }, authData);
            dispatch({ type: "RESPOND_GET_Product_Categories", payload: data.categories, error: null });
            dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_Product_Categories", payload: null, error: e });
        }
    },

    requestGetProducts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_Products" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ products: Array<IProductModel> }>("products{ productId name code isService category{name} }", [], {}, authData);
            dispatch({ type: "RESPOND_GET_Product_MODELS", payload: data.products, error: null });
            dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_Product_MODELS", payload: null, error: e });
        }

    },
    requestGetProductBrand: (name:string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_PRODUCT_BRANDS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ productBrands: Array<IProductBrand> }>("productBrands(name:$name){ id name}", [`$name:String`], {name}, authData);
            dispatch({ type: "RESPOND_GET_PRODUCT_BRANDS", payload: data.productBrands, error: null });
            dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_PRODUCT_BRANDS", payload: null, error: e });
        }

    }

}

const unloadedState: IProductState = {
    product: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isPosted: false,
    productCategories: null,
    products: null,
    isLoaded: false,
    productBrands: []
}


export const reducer: Reducer<IProductState> = (state: IProductState | undefined, incomingAction: Action): IProductState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_Product":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_ADD_UPDATE_PRODUCT":

            var payload = action;
            return {
                ...state,
                product: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_Products":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_Product_MODELS":
            return {
                ...state,
                products: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: false
            }

        case "REQUEST_GET_Product": {
            return {
                ...state,
                isLoading: true
            }
        }
        case "RESPOND_GET_Product": {
            return {
                ...state,
                product: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }

        case "REQUEST_GET_Product_Categories": {
            return {
                ...state,
                isLoading: true
            }
        }

        case "RESPOND_GET_Product_Categories": {
            var actions = action;
            return {
                ...state,
                productCategories: action.payload,
                fetchError: action.error,
                isLoaded: true,
                isLoading: false
            }
        }

        case "REQUEST_GET_PRODUCT_BRANDS":
            {
                return {
                    ...state, isLoaded: false, isLoading: false, isPosted: false, isPosting: false
                }
            }

        case "RESPOND_GET_PRODUCT_BRANDS":
            {
                return {
                    ...state,
                    productBrands: action.payload,
                    fetchError: action.error,
                    isLoaded: true,
                    isLoading: false
                }
            } 

        case "RESET_PRODUCT":
            {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: false,
                    isPosted: false,
                    isPosting: false,

                }
            }


        default:
            return state;
    }
}
