import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import { MeasuringUnits } from "../../common/Constants";
import * as Quatation from "../../store/Qoutation";
import { RouteComponentProps, match } from "react-router";
import NumberFormat from 'react-number-format';


import "../../assets/css/invoice/sales-invoice-print.css";
import { IVehicleModel } from "../../models/VehicleModel";

type actionCreators = typeof Quatation.actionCreators;

interface QoutationPrintProps extends RouteComponentProps, Quatation.IQoutationState, actionCreators {
    match: match<{ id?: string }>
}

interface QoutationPrintState {

}

class QoutationPrint extends React.PureComponent<QoutationPrintProps, QoutationPrintState> {

    componentDidMount() {
        this.props.requestGetQoutation(parseInt(this.props.match.params.id));
    }

    inWord(num) {
        var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
        var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        if ((num = num.toString()).length > 9) return 'overflow';
        var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return; var str = '';
        str += (parseInt(n[1]) !== 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
        str += (parseInt(n[2]) !== 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
        str += (parseInt(n[3]) !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
        str += (parseInt(n[4]) !== 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
        str += (parseInt(n[5]) !== 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : "";
        return str + " TK Only";
    }

    _getVehicleModelNYear(vehicle: IVehicleModel): string {
        var modelNYear = "";
        if (vehicle && vehicle.model) {
            modelNYear += vehicle.model.name + "-";
            modelNYear += vehicle.modelYear;
        }
        return modelNYear;
    }


    _renderInvoice(props: QoutationPrintProps) {
        const { qoutation } = this.props;
        const vehicle = qoutation.vehicle;
        const client = qoutation.vehicle && qoutation.vehicle.client && qoutation.vehicle.client;
        var registrationNumber = vehicle.licenseNo;
        if (vehicle.licenseNo.includes("-")) {
            var regSplit = vehicle.licenseNo.split("-");
            var licenseNo = regSplit[2];
            registrationNumber = regSplit[0] + "-" + regSplit[1] + " " + licenseNo.slice(0, 2) + "-" + licenseNo.slice(2, 6)

        }

        var services = qoutation.qoutationItems.filter(i => i.isService);
        var parts = qoutation.qoutationItems.filter(i => !i.isService);
        var serviceChunk = [];
        var productChunk = [];
        const chunkCnst = 27;
        var chunk = chunkCnst;

        var i, j;

        for (i = 0, j = services.length; i < j; i += chunk) {
            serviceChunk.push(services.slice(i, i + chunk));
        }
        var serviceChunkLength = serviceChunk.length > 0 ? serviceChunk[serviceChunk.length - 1].length : 0;

        chunk = Math.min(chunk, chunk - serviceChunkLength);
        if (chunk == 0) {
            chunk = chunkCnst;
        }
        for (i = 0, j = parts.length; i < j; i += chunk) {

            if (i == 0 && services.length + parts.length > 20 && services.length + parts.length <= chunkCnst) {
                chunk = (22 - (serviceChunk[serviceChunk.length - 1] || []).length) - 2;
            }

            if (i > 0 && chunk !== chunkCnst) {
                chunk = chunkCnst;
            }

            productChunk.push(parts.slice(i, i + chunk));

        }


        var partIndex = 0;
        var serviceIndex = 0;

        return (
            <React.Fragment>
                <div style={{ position: "relative" }}>
                    <div style={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", fontSize: "35px" }}>Quotation</p>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <hr />
                    </div>

                    <table className="tblInvTop" style={{ float: "right" }}>
                        <tbody>
                            <tr>
                                <td>DATE</td>
                                <td>{qoutation.createDate}</td>
                            </tr>
                            <tr>
                                <td>QUOTATION NO</td>
                                <td>Q-0000{qoutation.qtNo}</td>
                            </tr>
                            <tr>
                                <td>CUSTOMER ID</td>
                                <td>CUS-0000{qoutation.vehicle && qoutation.vehicle.clientId}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div style={{ clear: "both" }}></div>

                    <table className="tblInvAddr erp-table-head">
                        <tbody>
                            <tr>
                                <th>QUOTATION TO</th>
                                <th>VEHICLE DETAILS</th>
                            </tr>

                            <tr>
                                <td>

                                    <p>Owner/Contact Person <b>:</b> {qoutation && qoutation.contactPerson}</p>

                                </td>
                                <td><p>Registration <b>:</b> {vehicle && registrationNumber}</p></td>
                            </tr>

                            <tr>
                                <td><p>Client Name <b>:</b> {client && client.name}</p></td>
                                <td><p>Mileage <b>:</b> {vehicle && vehicle.mileage} KM</p></td>
                            </tr>
                            <tr>
                                <td><p>Address<b>:</b> {client && client.address}</p></td>
                                <td><p>Chessis no<b>:</b> {vehicle && vehicle.chessisNo}</p></td>
                            </tr>
                            <tr>
                                <td><p>Phone number<b>:</b> {client && client.cellPhone}</p></td>
                                <td><p>Model & Year<b>:</b> {this._getVehicleModelNYear(vehicle)}</p></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>{/*<p>Driver Name /number<b>:</b> {salesInvoice.vehicle.licenseNo}</p>*/}</td>
                            </tr>
                        </tbody>
                    </table>
                    {
                        serviceChunk.map((item, mainIndex) => {
                            serviceIndex += serviceChunk[mainIndex].length;
                            return <div style={{ pageBreakBefore: mainIndex == 0 && serviceChunk[serviceChunk.length - 1].length !== chunkCnst ? "avoid" : "always" }}>

                                <div>
                                    <h4 className="service-header">Services</h4>
                                </div>

                                <table className="tblData">
                                    <tbody>
                                        <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                            <th>SL.NO</th>
                                            <th>Description</th>
                                            <th>Note</th>
                                            <th>QUANTITY </th>
                                            <th>UNIT PRICE </th>
                                            <th>AMOUNT</th>
                                        </tr>

                                        {item.map((s, index) => (
                                            <tr key={index}>
                                                <td className="th-td-center">{(serviceIndex - serviceChunk[mainIndex].length) + index + 1}</td>
                                                <td className="table-td-wid ">{s.title + ` (${s.code && s.code})`}</td>
                                                <td className="table-td-wid ">{s.note.substring(0, 50)}</td>
                                                <td className="th-td-center">{s.quantity}</td>
                                                <td className="th-td-center"><NumberFormat value={s.price} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                                <td className="th-td-center"><NumberFormat value={s.quantity * s.price} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                            </tr>
                                        ))}
                                        {mainIndex == serviceChunk.length - 1 &&
                                            <tr className="tblDataTotal">
                                                <td colSpan={5} style={{ textTransform: "uppercase", textAlign: "right" }}>Sub Total</td>
                                                <td colSpan={3} className="th-td-center">
                                                    <NumberFormat value={qoutation.qoutationItems.filter(a => a.isService).map(a => a.quantity * a.price).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                                </td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>
                        })
                    }

                    {productChunk.map((item, mainIndex) => {
                        partIndex += productChunk[mainIndex].length;

                        return <div style={{ pageBreakAfter: mainIndex < productChunk.length - 1 || productChunk[productChunk.length - 1].length > 30 ? "always" : "avoid" }}>

                            <div>
                                <h4 className="service-header">Parts</h4>
                            </div>

                            <table className="tblData">
                                <tbody>
                                    <tr className="erp-table-header" style={{ textTransform: "uppercase" }}>
                                        <th>SL.NO</th>
                                        <th>Description</th>
                                        <th>Note</th>
                                        <th>QUANTITY </th>
                                        <th>UNIT PRICE</th>
                                        <th>AMOUNT</th>
                                    </tr>
                                    {item.map((s, index) => (
                                        <tr key={index}>
                                            <td className="th-td-center">{(partIndex - productChunk[mainIndex].length) + index + 1}</td>

                                            <td className="table-td-wid ">{s.title + ` (${s.code && s.code})`}</td>
                                            <td className="table-td-wid ">{s.note.substring(0, 50)}</td>

                                            <td className="th-td-center">{s.quantity + ` ${s.measuringUnit && s.measuringUnit < 10 ? MeasuringUnits.find(item => item.value == s.measuringUnit).label : ""}`}</td>

                                            <td className="th-td-center">  <NumberFormat value={s.price} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                            <td className="th-td-center">  <NumberFormat value={s.quantity * s.price} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                        </tr>
                                    ))}
                                    {mainIndex == productChunk.length - 1 &&
                                        <tr className="tblDataTotal">
                                            <td colSpan={5} style={{ textTransform: "uppercase", textAlign: "right" }}>Sub Total</td>
                                            <td colSpan={3} className="th-td-center">
                                                <NumberFormat value={qoutation.qoutationItems.filter(a => !a.isService).map(a => a.quantity * a.price).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />

                                            </td>
                                        </tr>
                                    }
                                    {mainIndex == productChunk.length - 1 &&
                                        <tr>
                                            <td style={{ textTransform: "uppercase" }} colSpan={8} className="th-td-center">
                                                IN WORD: {this.inWord(Math.abs(qoutation.total))}.
                                            </td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    })
                    }
                    <table>
                        <tbody>
                            <tr>
                                <th>   <p style={{ textAlign: "center", fontSize: "15px" }}>This quotation is prepared as per your requirement. Please feel free to contact us for any more queries.</p></th>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ float: "left" }} >

                        <table className="tblCmnts erp-table-head">
                            <tbody>
                                <tr>
                                    <th>Notes</th>
                                </tr>
                                <tr>
                                    <td>
                                        <ol>
                                            <li>This quotation is valid for next 15 days only.</li>
                                            <li>Please send your car for servicing after every 3000 KM or another 3 months, whichever comes first.</li>
                                            <li>For genuine parts the warranty will be 6 months.</li>
                                        </ol>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ float: "right" }}>
                        <table className="tblDue erp-table-head">
                            <tbody>
                                <tr>
                                    <th>Total Amount: </th>
                                    <td>   <NumberFormat value={(qoutation.total + qoutation.discountPercent) - (qoutation.vAT + qoutation.tax)} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                    </td>
                                </tr>

                                <tr>
                                    <th>(-)Discount: </th>
                                    <td> <NumberFormat value={qoutation.discountPercent} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Tax: </th>
                                    <td> <NumberFormat value={qoutation.tax} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Vat: </th>
                                    <td> <NumberFormat value={qoutation.vAT} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                                <tr>
                                    <th>Total Payble: </th>
                                    <td><NumberFormat value={qoutation.total} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <br />
                    <div className="tblCont">
                        <table className="tblFooter">
                            <tbody>
                                <tr>
                                    <td>
                                        <hr />
                                        Prepared By
                                    </td>
                                    <td>
                                        <hr />
                                        Checked By
                                    </td>
                                    <td>
                                        <hr />
                                        Approved By
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { isQoutationLoading, qoutation } = this.props;

        if (isQoutationLoading || qoutation == null) {
            return <div>Loading...</div>;
        }

        return (
            <React.Fragment>
                {this._renderInvoice(this.props)}

            </React.Fragment>
        );
    }
}

export default connect((state: ApplicationState) => state.qoutation, Quatation.actionCreators)(QoutationPrint as any);