import * as React from "react";
import { Grid, Row, Col, Table, Tabs, Tab } from "react-bootstrap";
import { Card } from "../../Card/Card";
import { Input, Button, InputGroup, FormGroup, CustomInput, Label, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Link } from "react-router-dom";
import { inherits } from "util";
import "../../../assets/css/accounts/cashBankPayment.css"
import { TextArea } from "../../FormInputs/Input";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import * as Qoutation from "../../../store/Qoutation";
import * as Account from "../../../store/Account";
import NumberFormat from "react-number-format"


interface IBankPaymentsProps {

}

interface IBankPaymentsState {
    fromDate: Date;
    toDate: Date;
}

type Props = IBankPaymentsProps & Account.AccountState & typeof Account.actionCreators;



class ViewSupplierPayment extends React.PureComponent<Props, IBankPaymentsState> {

    constructor(props: Props) {
        super(props);
        let date = new Date();
        let fromDate = new Date(date.setDate(date.getDate() - 10));
        let toDate = new Date(date.setDate(date.getDate() + 20));
        this.state = {
            fromDate: fromDate,
            toDate: toDate
        }
    }

    componentWillMount() {
        this.props.requestGetCashBankJournals(1, this.state.fromDate, this.state.toDate, true);
    }

    loadJournals = (event: any) => {
        event.preventDefault();
        this.props.requestGetCashBankJournals(1, this.state.fromDate, this.state.toDate, true);
    }

    loadDate(e) {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.setState({ [name]: value } as IBankPaymentsState)

    }


    handleLoadForm = (e: any) => {
        e.preventDefault();
        this.props.requestGetCashBankJournals(1, this.state.fromDate, this.state.toDate);
    }

    render() {
        const { cashBankReceipts } = this.props;
        return (
            <div>
                <Grid fluid>
                    <Row>

                        <Col md={12} className="cardPaddiingView">
                            <Card
                                title=""
                                content={
                                    <form onSubmit={this.handleLoadForm}>
                                        <Row>
                                            <div className=' d-inline shadow ml-3 searchFilter'>
                                                <i className='pe-7s-search' style={{ fontSize: 24 }}></i>
                                            </div>
                                            <h4 style={{ marginBottom: 20, marginTop: -20 }}>&nbsp; Filter </h4>
                                        </Row>
                                        <Row>
                                            <Col className='InputGroup' style={{ marginRight: "11px" }}>
                                                <label>From</label><br />
                                                <DatePicker
                                                    selected={this.state.fromDate}
                                                    onChange={(e) => {
                                                        this.setState({ fromDate: e })
                                                    }}
                                                    customInput={<input className='form-control' placeholder="Date" />}
                                                />
                                            </Col>
                                            <Col className='InputGroup'>
                                                <label>To</label><br />
                                                <DatePicker
                                                    className="from-control"
                                                    selected={this.state.toDate}
                                                    onChange={(e) => {
                                                        this.setState({ toDate: e })
                                                    }}
                                                    customInput={<input className='form-control' placeholder="Date" />}
                                                />
                                            </Col>

                                            <Col >
                                                <Button color="info" active style={{ marginTop: "25px", marginLeft: "11px" }}> <i className="fa fa-search" style={{ fontSize: 14, color: 'white', }}></i>&nbsp;  Search  </Button>
                                            </Col>
                                        </Row>

                                    </form>
                                }
                            />
                        </Col>

                        <Col md={12} className="mt-5">
                            <Card
                                title=''
                                content={
                                    <div>
                                        <form >
                                            <Row>
                                                <Col md={6}>
                                                    <div className="header-title title-bank">
                                                        <h4 >
                                                            <i className='pe-7s-wallet' style={{ fontSize: 36 }}></i>
                                                            Supplier Payments  </h4>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="newBtn">
                                                        <Link to="/inventory/supplier-payment-add" className='btn-sm'>
                                                            <i className="fa fa-plus-square-o"></i>&nbsp;
                                                            Add Supplier Payment
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Table hover responsive>
                                                <thead>
                                                <tr className="display-5">
                                                    <th>Date <i className="fa fa-sort"></i> </th>
                                                    <th>Voucher No. <i className="fa fa-sort"></i> </th>
                                                    <th>PAYMENT TO<i className="fa fa-sort"></i></th>
                                                    <th>PAYMENT TYPE*<i className="fa fa-sort"></i></th>
                                                    <th>NOTES*<i className="fa fa-sort"></i></th>
                                                    {/* <th>BALANCE*<i className="fa fa-sort"></i></th>*/}
                                                    <th>Amount <i className="fa fa-sort"></i></th>
                                                </tr>
                                                <tr>
                                                    <th style={{ border: 0, borderTop: 0, fontSize: 12 }}>
                                                        <input className='form' type="text" name="name" placeholder='' style={{ outline: 0, borderWidth: "0 0 1px 0", borderColor: "grey", fontSize: 9, width: "100%" }}
                                                        />
                                                    </th>
                                                    <th style={{ border: 0, borderTop: 0, fontSize: 12 }}>
                                                        <input className='form' type="text" name="name" placeholder='' style={{ outline: 0, borderWidth: "0 0 1px 0", borderColor: "grey", fontSize: 9, width: "100%" }}
                                                        />
                                                    </th>
                                                    <th style={{ border: 0, fontSize: 12 }}>
                                                        <input className='form' type="text" name="name" placeholder='' style={{ outline: 0, borderWidth: "0 0 1px 0", borderColor: "grey", fontSize: 9, width: "100%" }}
                                                        />
                                                    </th>
                                                    <th style={{ border: 0, fontSize: 12 }}>
                                                        <select className="form btn-block" style={{
                                                            outline: 0,
                                                            borderWidth: '0 0 2px 0',
                                                            borderColor: 'deeppink',
                                                            borderRadius: "0px",
                                                        }}>
                                                            <option >PAYMENT BY...</option>
                                                            <option selected>CASH </option>
                                                            <option>CheckBook</option>
                                                            <option>CARD</option>
                                                        </select>
                                                    </th>
                                                    <th style={{ border: 0, fontSize: 12 }}>
                                                        <input className='form' type="text" name="name" placeholder='' style={{ outline: 0, borderWidth: "0 0 1px 0", borderColor: "grey", fontSize: 9, width: "100%" }}
                                                        />
                                                    </th>
                                                    <th style={{ border: 0, fontSize: 12 }}></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    cashBankReceipts == null ? <tr><td colSpan={7}>No Receipts Found!</td></tr> :
                                                        cashBankReceipts.length > 0 ?
                                                            cashBankReceipts.map(item =>
                                                                <tr>
                                                                    <td>{item.date}</td>
                                                                    <td>{"JVN-000" + item.jvn}</td>
                                                                    <td>{item.accountName}</td>
                                                                    <td>{item.paymentAccountName}</td>
                                                                    <td>{item.notes}</td>
                                                                    {/*<td><NumberFormat value={item.balance} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>*/}

                                                                    <td><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>
                                                                    <td><a href={`print/cash-payment/${item.jvn}`} target="_blank"><i className="btn-info pe-7s-print print"></i></a></td>
                                                                </tr>
                                                            )
                                                            : <tr><td colSpan={7}>No Payments History!</td></tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </form>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}
export default connect((state: ApplicationState) => { return { ...state.account, } }, { ...Account.actionCreators })(ViewSupplierPayment as any);
