import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import graphQl from "../services/GraphQL";
import { IVehicleModel } from "../models/VehicleModel";
import { IQoutation, IJobCard, IJobCartItem, IJobCardWrapper, IJobCardStockItem } from "../models/Qoutation";
import * as VehicleStore from "./Vehicle";
import * as QoutationStore from "./Qoutation";
import { ISalesInvoice } from "../models/SalesInvoice";
import { IProductStockItemModelType, IProductStockItemModel } from "../models/ProductStock";

export interface JobCardState {
    qoutationId: number,
    jobCard: IJobCard,
    jobCards?: IJobCardWrapper,
    isLoading: boolean,
    jobCardAdded: boolean,
    jobCardItems: Array<IJobCartItem>,
    jobCardStockItems: Array<IJobCardStockItem>,
    page: number,
    pageSize: number,
    totalCount: number,
    jobCardPosted: boolean,
    isJobCardSubmitting: boolean
}

export interface RequestCreateJobCard extends Action<string> {
    type: "REQUEST_CREATE_JOB_CARD";
}


export interface RespondCreateJobCard extends Action<string> {
    type: "RESPOND_CREATE_JOB_CARD";
    jobCard: IJobCard;
    error: Error
}

export interface RequestSearchInvoice extends Action<string> {
    type: "REQUEST_SEARCH_JOB_CARD";
}


export interface RespondSearchInvoice extends Action<string> {
    type: "RESPOND_SEARCH_JOB_CARD";
    invoice: ISalesInvoice;
    error: Error
}

export interface RequestGetJobCard extends Action<string> {
    type: "REQUEST_GET_JOB_CARD";
}


export interface RespondGetJobCard extends Action<string> {
    type: "RESPOND_GET_JOB_CARD";
    jobCard: IJobCard;
    error: Error
}

export interface RequestGetJobCards extends Action<string> {
    type: "REQUEST_GET_JOB_CARDS";
}


export interface RespondGetJobCards extends Action<string> {
    type: "RESPOND_GET_JOB_CARDS";
    jobCards: IJobCardWrapper;
    error: Error
}


export interface RequestGetJobCardItems extends Action<string> {
    type: "REQUEST_GET_JOB_CARD_ITEMS";
}


export interface RespondGetJobCardItems extends Action<string> {
    type: "RESPOND_GET_JOB_CARD_ITEMS";
    jobCardItems: IJobCartItem[];
    error: Error
}

export interface RequestReturnJobCardItems extends Action<string> {
    type: "REQUEST_RETURN_JOB_CARD_ITEMS";
}

export interface RequestGetJobCardStockItemsByInvoice extends Action<string> {
    type: "REQUEST_GET_JOBCARD_STOCK_ITEMS_BY_INVOICE";
}


export interface RespondReturnJobCardItems extends Action<string> {
    type: "RESPOND_RETURN_JOB_CARD_ITEMS";
    jobCard: IJobCard;
    error: Error
}
 

export interface RespondGetJobCardStockItemsByInvoice extends Action<string> {
    type: "RESPOND_GET_JOBCARD_STOCK_ITEMS_BY_INVOICE";
    jobCardStockItems: IJobCardStockItem[];
    error: Error
}

export interface ResetState extends Action<string> {
    type: "RESET";
}

type KnownAction = RequestCreateJobCard |
    RespondCreateJobCard |
    RequestGetJobCard |
    RespondGetJobCard |
    RequestGetJobCardItems |
    RespondGetJobCardItems |
    RequestGetJobCards |
    RespondGetJobCards |
    RequestReturnJobCardItems |
    RespondReturnJobCardItems |
    RequestGetJobCardStockItemsByInvoice |
    RespondGetJobCardStockItemsByInvoice |
    ResetState;


export const actionCreators = {

    requestGetJobCards: (page: number, pageSize: number, sortKey: string, ascending: boolean, filterKeys: string, filterValues: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_JOB_CARDS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ jobCards: IJobCardWrapper }>("jobCards(page:$page,pageSize:$pageSize,sortKey:$sortKey,ascending:$ascending,filterKeys:$filterKeys,filterValues:$filterValues){ items {id jobCardNo createDate status invoice{invoiceCode} invoiceId} page pageSize totalCount }", ["$page:Int!", "$pageSize:Int!", "$sortKey:String!", "$ascending:Boolean!", "$filterKeys:String!", "$filterValues:String!"], { page, pageSize, sortKey, ascending, filterKeys, filterValues }, authData);
            dispatch({ type: "RESPOND_GET_JOB_CARDS", jobCards: data.jobCards, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_JOB_CARDS", jobCards: null, error: e });
        }

    },

    requestGetJobCard: (jobCardId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_JOB_CARD" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ jobCard: IJobCard }>("jobCard:jobCardById(jobCardId:$jobCardId){id driverCellPhone driverName jobCardNo qoutationId }", ["$jobCardId:Int"], { jobCardId }, authData);
            dispatch({ type: "RESPOND_GET_JOB_CARD", jobCard: data.jobCard, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_JOB_CARD", jobCard: null, error: null });
        }
    },

    requestGetJobCardItems: (jobCardId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_JOB_CARD_ITEMS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ jobCardItems: IJobCartItem[] }>("jobCardItems:jobCardItems(jobCardId:$jobCardId){ id jobCardId status productId productModelId quantity returnQuantity note product{id name code productModels{id name productId}} productModel{id name productId} jobCard{id invoiceId jobCardNo status} }", ["$jobCardId:Int"], { jobCardId }, authData);
            dispatch({ type: "RESPOND_GET_JOB_CARD_ITEMS", jobCardItems: data.jobCardItems, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_JOB_CARD_ITEMS", jobCardItems: null, error: null });
        }
    },

    requestGetJobCardStockItemsByInvoice: (invCode: number, invoiceId?: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_JOBCARD_STOCK_ITEMS_BY_INVOICE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ jobCardStockItems: IJobCardStockItem[] }>("jobCardStockItems:jobCardStockItemsByInvoice(invCode:$invCode,invoiceId:$invoiceId){ id  jobCardItem{id product{name code measuringUnit} productModel{name code} quantity returnQuantity  jobCard{id jobCardNo}} productStockItem{costPrice} quantity}", ["$invCode:Int","$invoiceId:Int"], { invCode,invoiceId }, authData);
            dispatch({ type: "RESPOND_GET_JOBCARD_STOCK_ITEMS_BY_INVOICE", jobCardStockItems: data.jobCardStockItems, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_JOBCARD_STOCK_ITEMS_BY_INVOICE", jobCardStockItems: null, error: null });
        }
    },

    requestReturnPostJobCardItems: (jobCard: IJobCard = null, jobCardItems: IJobCartItem[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_RETURN_JOB_CARD_ITEMS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ jobCard: IJobCard }>("jobCard:jobCardReturnOrPost(jobCard:$jobCard, jobCardItems:$jobCardItems){id invoiceId jobCardNo status }", ["$jobCard:JobCardInputType, $jobCardItems:[JobCardItemInputType]!"], { jobCard, jobCardItems }, authData);
            dispatch({ type: "RESPOND_RETURN_JOB_CARD_ITEMS", jobCard: data.jobCard, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_RETURN_JOB_CARD_ITEMS", jobCard: null, error: null });
        }
    },

    requestSubmitJobCard: (jobCard: IJobCard): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_CREATE_JOB_CARD" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ jobCard: IJobCard }>("jobCard(jobCard:$jobCard){id jobCardNo invoiceId status jobCardItems{ id jobCardId status productId productModelId quantity returnQuantity note}  }", ["$jobCard:JobCardInputType!"], { jobCard }, authData);
            dispatch({ type: "RESPOND_CREATE_JOB_CARD", jobCard: data.jobCard, error: null });

        } catch (e) {
            dispatch({ type: "RESPOND_CREATE_JOB_CARD", jobCard: null, error: e });
        }
    },

    resetJobCardState: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "RESET" });
    }
}

const unloadedState: JobCardState = {
    qoutationId: 0,
    jobCard: null,
    jobCardAdded: false,
    jobCardItems: [],
    isLoading: false,
    jobCards: null,
    page: 1,
    pageSize: 10,
    totalCount: 0,
    jobCardPosted: false,
    isJobCardSubmitting: false,
    jobCardStockItems: []
}

export const reducer: Reducer<JobCardState> = (state: JobCardState | undefined, incomingAction: Action): JobCardState => {

    if (state === undefined) {
        return { ...unloadedState };
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case "REQUEST_CREATE_JOB_CARD":
            return {
                ...state,
                isJobCardSubmitting: true
            }

        case "RESPOND_CREATE_JOB_CARD":
            return {
                ...state,
                jobCard: action.jobCard,
                jobCardAdded: action.jobCard !== null,
                isJobCardSubmitting: false
            }

        case "REQUEST_GET_JOB_CARD":
            return {
                ...state
            }

        case "RESPOND_GET_JOB_CARD":
            return {
                ...state,
                jobCard: action.jobCard
            }
        case "REQUEST_GET_JOB_CARD_ITEMS":
            return {
                ...state
            }

        case "RESPOND_GET_JOB_CARD_ITEMS":
            return {
                ...state,
                jobCardItems: action.jobCardItems
            }

        case "REQUEST_GET_JOB_CARDS":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_JOB_CARDS":
            return {
                ...state,
                isLoading: false,
                jobCards: action.jobCards
            }

        case "REQUEST_RETURN_JOB_CARD_ITEMS":
            return {
                ...state,
                isLoading: true,
                jobCardPosted: false,
                isJobCardSubmitting:true
            }

        case "RESPOND_RETURN_JOB_CARD_ITEMS":
            return {
                ...state,
                isLoading: false,
                jobCard: action.jobCard,
                jobCardPosted: action.jobCard !== null,
                isJobCardSubmitting: false
            }

        case "REQUEST_GET_JOBCARD_STOCK_ITEMS_BY_INVOICE":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_JOBCARD_STOCK_ITEMS_BY_INVOICE":
            return {
                ...state,
                isLoading: false,
                jobCardStockItems: action.jobCardStockItems, 
            }


        case "RESET":
            return {
                ...state,
                qoutationId: 0,
                isLoading: false,
                jobCard: null,
                jobCardAdded: false,
                jobCardPosted: false,
                isJobCardSubmitting: false
            }
        default:
            return state;
    }
}