import { IRoute, RouteBuilder } from "../models/RouteModel";
import DefaultLayout from "../components/layouts/DefaultLayout";
import AuthorizedLayout from "../components/layouts/AuthorizedLayout";
import SalesInvoicePrint from "../components/printing/SalesInvoicePrint";
import QouationPrint from "../components/printing/QoutationPrint";
import CorporateClientPrint from "../components/printing/CorporateClientPrint";
import VehicleSalesHistoryPrint from "../components/printing/VehicleSalesHistoryPrint";
import PrintDriverCommission  from "../components/printing/DriversCommissionPrint";
import PrintCashReceipt from "../components/printing/CashReceiptPrint";
import PrintCashPayment from  "../components/printing/CashPaymentPrint";
import InvoiceReport from "../components/printing/InvoiceReport";
import ReportPrinting from "../components/printing/ReportPrinting";
import AccountDetailsPrint from "../components/printing/AccountDetailsPrint";

const routes: IRoute[] = [
    RouteBuilder.getBuilder(1).setPath("/login").setComponent(DefaultLayout).build(),
    RouteBuilder.getBuilder(2).setPath("/print/sales-invoice/:id").setComponent(SalesInvoicePrint).build(),
    RouteBuilder.getBuilder(3).setPath("/print/qoutation/:id").setComponent(QouationPrint).build(),
    RouteBuilder.getBuilder(4).setPath("/print/invoice-report/:invoiceId").setComponent(InvoiceReport).build(),
    RouteBuilder.getBuilder(4).setPath("/print/report-printing/:invoiceId").setComponent(ReportPrinting).build(),
    RouteBuilder.getBuilder(5).setPath("/print/invoice-commision/:invoiceId").setComponent(PrintDriverCommission).build(),
    RouteBuilder.getBuilder(6).setPath("/print/cash-receipt/:jvn").setComponent(PrintCashReceipt).build(),
    RouteBuilder.getBuilder(7).setPath("/print/cash-payment/:jvn").setComponent(PrintCashPayment).build(),
    RouteBuilder.getBuilder(8).setPath("/print/corporate-client/:accountId").setComponent(CorporateClientPrint).build(),
    RouteBuilder.getBuilder(9).setPath("/print/vehicle-sales-history/:accountId").setComponent(VehicleSalesHistoryPrint).build(),
    RouteBuilder.getBuilder(10).setPath("/print/drivers-commission/:commissionId").setComponent(PrintDriverCommission).build(),
    RouteBuilder.getBuilder(11).setPath("/print/account-details/:id").setComponent(AccountDetailsPrint).build(),
    RouteBuilder.getBuilder(12).setPath("/").setComponent(AuthorizedLayout).setIsPrivate(true).build()
];

export default routes;