import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import { ISupplierModel, IDistrict, ISubDistrict } from "../models/SupplierModel";
import graphQl from "../services/GraphQL";

export interface ISupplierState {
    supplier: ISupplierModel | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    suppliers: Array<ISupplierModel> | null;
    districts: Array<IDistrict> | null;
    subDistricts: Array<ISubDistrict> | null;
}


//Request GET actions
export interface RequestGetSuppliers extends Action<string> {
    type: "REQUEST_GET_SUPPLIERS";
}

export interface RequestGetSupplier extends Action<string> {
    type: "REQUEST_GET_SUPPLIER";
}
//Request ADD actions
export interface RequestAddSupplier extends Action<string> {
    type: "REQUEST_ADD_SUPPLIER";
}

export interface RespondGetSuppliers extends Action<string> {
    type: "RESPOND_GET_SUPPLIERS";
    payload: Array<ISupplierModel> | null;
    error: Error | null
}



export interface RespondGetSupplier extends Action<string> {
    type: "RESPOND_GET_SUPPLIER";
    payload: ISupplierModel | null;
    error: Error | null;
}



export interface RespondAddSuppler extends Action<string> {
    type: "RESPOND_ADD_SUPPLIER";
    payload: ISupplierModel | null;
    error: Error | null;
}

export interface RequestGetDistricts extends Action<string> {
    type: "REQUEST_GET_DISTRICTS";
}

export interface RequestGetSubDistricts extends Action<string> {
    type: "REQUEST_GET_SUB_DISTRICTS";
}



export interface RespondGetDistricts extends Action<string> {
    type: "RESPOND_GET_Districts";
    payload: Array<IDistrict> | null;
    error: Error | null;
}




export interface RespondGetSubDistrict extends Action<string> {
    type: "RESPOND_GET_SUB_DISTRICT";
    payload: Array<ISubDistrict> | null;
    error: Error | null;
}




export interface RequestTopSupplier extends Action<string> {
    type: "REQUEST_TOP_SUPPLIER";
}
export interface RespondTopSupplier extends Action<string> {
    type: "RESPOND_TOP_SUPPLIER";
    payload: Array<ISupplierModel> | null;
    error: Error | null;
}








export interface IResetService extends Action<string> {
    type: "RESET_SERVICE";
}






type KnownAction = RequestGetSuppliers |
    RequestGetSupplier |
    RequestAddSupplier |
    RespondGetSuppliers |
    RespondAddSuppler |
    RespondGetSupplier |
    RequestGetDistricts |
    RequestGetSubDistricts |
    RespondGetDistricts |
    RespondGetSubDistrict |
    RequestTopSupplier |
    RespondTopSupplier |
    IResetService

export const actionCreators = {
    requestAddSupplier: (supplierInfo: ISupplierModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_SUPPLIER" });

            const authData = appState.authorization!.authData;

            var journals = supplierInfo.account.journals;

            const data = await graphQl.executeMutation<{ addOrUpdateService: ISupplierModel }>("addSupplier(supplierInfo: $supplierInfo){accountName address name cellPhone branchName ContactPerson}", ["supplierInfo: SupplierInputType"], { supplierInfo }, authData);

            dispatch({ type: "RESPOND_ADD_SUPPLIER", payload: data.addOrUpdateService, error: null });
            dispatch({ type: "RESET_SERVICE" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_SUPPLIER", payload: null, error: e });
        }
    },



    requestGetSuppliers: (name: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SUPPLIERS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ suppliers: Array<ISupplierModel> }>("suppliers(name:$name){ id name cellPhone accountName }", [`$name:String`], { name }, authData);

            dispatch({ type: "RESPOND_GET_SUPPLIERS", payload: data.suppliers, error: null });
            dispatch({ type: "RESET_SERVICE" });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SUPPLIERS", payload: null, error: e });
        }

    },
    requestGetDistricts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_DISTRICTS" });

            const authData = appState.authorization!.authData;

            const data = await graphQl.executeQuery<{ districts: Array<IDistrict> }>("districts{id,name }", [], {}, authData);

            dispatch({ type: "RESPOND_GET_Districts", payload: data.districts, error: null });
            dispatch({ type: "RESET_SERVICE" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_Districts", payload: null, error: e });
        }
    },



    requestGetSubDistricts: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_SUB_DISTRICTS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ subDistricts: Array<ISubDistrict> }>("subDistricts{ id, name districtId}", [], {}, authData);

            dispatch({ type: "RESPOND_GET_SUB_DISTRICT", payload: data.subDistricts, error: null });
            dispatch({ type: "RESET_SERVICE" });

        } catch (e) {
            dispatch({ type: "RESPOND_GET_SUB_DISTRICT", payload: null, error: e });
        }

    },
    requestGetTopSuppliers: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_TOP_SUPPLIER" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ suppliers: Array<ISupplierModel> }>("suppliers:topSuppliers{name,address,account{journal{ amount}}}", [], {}, authData);

            dispatch({ type: "RESPOND_TOP_SUPPLIER", payload: data.suppliers, error: null });
            dispatch({ type: "RESET_SERVICE" });

        } catch (e) {
            dispatch({ type: "RESPOND_TOP_SUPPLIER", payload: null, error: e });
        }

    }


}

const unloadedState: ISupplierState = {
    supplier: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isLoaded: false,
    isPosted: false,
    suppliers: [],
    subDistricts: [],
    districts: []
}


export const reducer: Reducer<ISupplierState> = (state: ISupplierState | undefined, incomingAction: Action): ISupplierState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_SUPPLIER":
            return {
                ...state,
                isPosting: true
            }


        case "RESPOND_ADD_SUPPLIER":
            return {
                ...state,
                supplier: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }


        case "REQUEST_GET_SUPPLIER":
            return {
                ...state,
                isLoading: true
            }


        case "RESPOND_GET_SUPPLIER": {
            return {
                ...state,
                supplier: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }




        case "REQUEST_GET_SUPPLIERS": {
            return {
                ...state,
                isLoading: true
            }
        }


        case "RESPOND_GET_SUPPLIERS":

            return {
                ...state,
                suppliers: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_DISTRICTS":
            return {
                ...state,
                isLoading: true
            }


        case "RESPOND_GET_Districts": {
            return {
                ...state,
                districts: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }




        case "REQUEST_GET_SUB_DISTRICTS": {
            return {
                ...state,
                isLoading: true
            }
        }


        case "RESPOND_GET_SUB_DISTRICT":
            return {
                ...state,
                subDistricts: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: action.payload !== null
            }

        case "REQUEST_TOP_SUPPLIER": {
            return {
                ...state,
                isLoading: true
            }
        }


        case "RESPOND_TOP_SUPPLIER":

            return {
                ...state,
                suppliers: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }



        case "RESET_SERVICE":
            {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: false,
                    isPosted: false,
                    isPosting: false,

                }
            }

        default:
            return state;
    }
}
