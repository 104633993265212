import {lazy} from "react";
import {IRoute, RouteBuilder} from "../models/RouteModel";
import ViewJournal from "../components/pages/Accounting/ViewJournal";
import AccountDetails from "../components/pages/Accounting/AccountDetails";
import {ExecutiveAccounts, AdminAccounts, AccountPlusOperations, Admin, AdminOperations, AccountsExclude, AdminStore} from "../common/Authorization";

import ViewCorporateClient from "../components/pages/CorporateClient/ViewCorporateClient";
import AddSupplier from "../components/pages/supplier/AddSupplier";
import ViewSupplierPayment from "../components/pages/inventory/ViewSupplierPayment";
import InventoryManagement from "../components/pages/inventory/InventoryManagement";
import AddSupplierPayment from "../components/pages/inventory/AddSupplierPayment";
import JobCardEntry from "../components/pages/job-card/NewJobCard";
import InvoiceReport from "../components/pages/inventory/InvoiceReport";

const dashboard = lazy(() => import("../components/pages/admin/Dashboard"));

const viewQuotation = lazy(() => import("../components/pages/quotation/ViewQuotation"));
const vehicleSalesHistory = lazy(() => import("../components/pages/vehicle/VehicleSalesHistory"));
const addNewQuotation = lazy(() => import("../components/pages/quotation/addNewQuotation"));
const quotationEntry = lazy(() => import("../components/pages/quotation/QuotationEntry"));
const journalEntry = lazy(() => import("../components/pages/Accounts/JournalEntry"));
const bankReceipts = lazy(() => import("../components/pages/Accounts/BankReceipts"));
const bankPayments = lazy(() => import("../components/pages/Accounts/BankPayments"));
const expenseTracking = lazy(() => import("../components/pages/Accounts/ExpenseTracking"));
const salaryBounsEntry = lazy(() => import("../components/pages/Accounts/SalaryBounsEntry"));
const addReceipts = lazy(() => import("../components/pages/Accounts/AddReceipts"));
const addBankPayments = lazy(() => import("../components/pages/Accounts/AddBankPyments"));
const viewInvoicePayments = lazy(() => import("../components/pages/Accounts/ViewInvoicePayments"));
const addInvoicePayments = lazy(() => import("../components/pages/Accounts/AddInvoicePayments"));

const productEntry = lazy(() => import("../components/pages/inventory/ProductEntry"));

const productInventory = lazy(() => import("../components/pages/inventory/ProductInventory"));

const vehicleInfoEntry = lazy(() => import("../components/pages/inventory/VehicleInfoEntry")); 
const addProductInventory = lazy(() => import("../components/pages/inventory/AddProductInventory"));
const invoiceClosing = lazy(() => import("../components/pages/inventory/InvoiceClosing"));

const driversCommission = lazy(() => import("../components/pages/general/DriversCommission"));
const viewDriversCommission = lazy(() => import("../components/pages/general/ViewDriversCommission")); 

const printQuotation = lazy(() => import("../components/printing/QoutationPrint"));
const printSalesInvoice = lazy(() => import("../components/printing/SalesInvoicePrint")); 

const addAccount = lazy(() => import("../components/pages/Accounting/AddAccount"));
const chartOfAccount = lazy(() => import("../components/pages/Accounting/ChartOfAccount"));
 
const viewJournal = lazy(() => import("../components/pages/Accounting/ViewJournal"));
const accountingSummary = lazy(() => import("../components/pages/Accounting/AccountingSummary"));

const viewInvoice = lazy(() => import("../components/pages/invoice/ViewInvoice"));
const addInvoice = lazy(() => import("../components/pages/invoice/AddInvoice"));
const printInvoice = lazy(() => import("../components/pages/invoice/PrintInvoice"));
const invoiceOutsideService = lazy(() => import("../components/pages/invoice/InvoiceOutsideService"));

const addUser = lazy(() => import("../components/pages/user/AddUser"));
const viewUsers = lazy(() => import("../components/pages/user/ViewUsers"));

const addCorporateClient = lazy(() => import("../components/pages/CorporateClient/AddCorporateClient"));
const viewProducts = lazy(() => import("../components/pages/product/ViewProducts"));
const logOut = lazy(() => import("../components/pages/LogOut"));
const closing = lazy(() => import("../components/pages/Closing"));
const trialBalanceView = lazy(() => import("../components/pages/Accounting/TrialBalanceView"));


const roles = {
    admin: ["super_admin", "admin"],
    accounts_exclude: ["super_admin", "admin", "admin_st", "admin_op"],
    admin_operations: ["super_admin", "admin", "admin_op"],
    admin_store: ["super_admin", "admin", "admin_st"],
    admin_accounts: ["super_admin", "admin", "admin_ac"],
    executive_accounts: ["super_admin", "admin", "admin_ac", "executive_ac"],
    accounts_plus_operations: ["super_admin", "admin", "admin_ac", "admin_op", "executive_ac"]
};

const accountingRoutes: IRoute[] = [

    RouteBuilder.getBuilder(1)
        .setPath("/accounting/add")
        .setRole(roles.executive_accounts)
        .setComponent(ExecutiveAccounts(addAccount))
        .setTitle("Add Account")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(2)
        .setPath("/accounting/view")
        .setComponent(AdminAccounts(chartOfAccount))
        .setRole(roles.admin_accounts)
        .setTitle("Chart of Account")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(3)
        .setPath("/accounting/journal-entry")
        .setComponent(AdminAccounts(journalEntry))
        .setRole(roles.admin_accounts)
        .setTitle("Journal Entry")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(4)
        .setPath("/accounting/journal-list")
        .setComponent(ExecutiveAccounts(viewJournal))
        .setRole(roles.executive_accounts)
        .setTitle("View Journal")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(5)
        .setPath("/accounting/details/:name-:id")
        .setComponent(ExecutiveAccounts(AccountDetails))
        .setTitle("Account Details")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),

    // RouteBuilder.getBuilder(6)
    //     .setPath("/accounting/summary")
    //     .setComponent(accountingSummary)
    //     .setTitle("Accounting Summary")
    //     .setIsLink(true)
    //     .setIsPrivate(true)
    //     .build(),

    RouteBuilder.getBuilder(6)
        .setPath("/accounting/trial-balance")
        .setComponent(AdminAccounts(trialBalanceView))
        .setRole(roles.admin_accounts)
        .setTitle("Trial Balance")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

];
const quotationRoutes: IRoute[] = [
    RouteBuilder.getBuilder(1)
        .setPath("/quotation/new")
        .setComponent(AdminOperations(addNewQuotation))
        .setRole(roles.admin_operations)
        .setTitle("New Quotation")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(2)
        .setPath("/quotation/entry/:vehicleId")
        .setComponent(AdminOperations(quotationEntry))
        .setRole(roles.admin_operations)
        .setTitle("Quotation Entry")
        .setIsLink(true)
        .setIsPrivate(true)
        .build()

];

const invoiceRoutes: IRoute[] = [ 

    RouteBuilder.getBuilder(1)
        .setPath("/invoice/list")
        .setComponent(viewInvoice)
        .setTitle("View/Add Invoice")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(2)
        .setPath("/invoice/new")
        .setComponent(AdminOperations(addInvoice))
        .setTitle("Add Invoice")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(), 
    RouteBuilder.getBuilder(3)
        .setPath("/account/invoice-payment")
        .setComponent(ExecutiveAccounts(viewInvoicePayments))
        .setRole(roles.executive_accounts)
        .setTitle("Invoice Payments")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    //RouteBuilder.getBuilder(4)
    //    .setPath("/inventory/invoice-closing")
    //    .setComponent(AdminStore(invoiceClosing))
    //    .setRole(roles.admin_store)
    //    .setIsLink(true)
    //    .setTitle("Invoice Closing")
    //    .build(),

    RouteBuilder.getBuilder(5)
        .setPath("/invoice/print")
        .setComponent(printInvoice)
        .setTitle("New Card")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),

];

const invoiceExtraRoutes: IRoute[] = [
    RouteBuilder.getBuilder(1)
        .setPath("/inventory/invoice-closing")
        .setComponent(AdminStore(invoiceClosing))
        .setRole(roles.admin_store)
        .setIsLink(true)
        .setTitle("Invoice Others")
        .build(),

    RouteBuilder.getBuilder(2)
        .setPath("/invoice/report")
        .setComponent(AdminStore(InvoiceReport))
        .setRole(roles.admin_store)
        .setTitle("Invoice Report")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
]

const accountsRoutes: IRoute[] = [

    RouteBuilder.getBuilder(1)
        .setPath("/account/journalEntry")
        .setComponent(ExecutiveAccounts(journalEntry))
        .setTitle("Journal Entry")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(2)
        .setPath("/account/receipts")
        .setComponent(ExecutiveAccounts(bankReceipts))
        .setRole(roles.executive_accounts)
        .setTitle("Cash/Bank Recipts")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(3)
        .setPath("/account/payments")
        .setComponent(ExecutiveAccounts(bankPayments))
        .setRole(roles.executive_accounts)
        .setTitle("Cash/Bank Payments")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(4)
        .setPath("/account/expense-tracking")
        .setComponent(ExecutiveAccounts(expenseTracking))
        .setRole(roles.executive_accounts)
        .setTitle("Expense Tracking")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    //RouteBuilder.getBuilder(5)
    //    .setPath("/account/salary-entry")
    //    .setComponent(salaryBounsEntry)
    //    .setTitle("Salary&Bouns Entry")
    //    .setIsLink(true)
    //    .setIsPrivate(true)
    //    .build(),

    RouteBuilder.getBuilder(5)
        .setPath("/account/new-payment")
        .setComponent(ExecutiveAccounts(addBankPayments))
        .setTitle("Bank Payments")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(6)
        .setPath("/account/new-receipt")
        .setComponent(ExecutiveAccounts(addReceipts))
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(8)
        .setPath("/account/add-invoice-payment")
        .setTitle("Add Invoice Payment")
        .setComponent(addInvoicePayments)
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),
];

const inventoryRoutes: IRoute[] = [
    RouteBuilder.getBuilder(1)
        .setPath("/inventory/product-inventory")
        .setComponent(productInventory)
        .setTitle("Product Inventory")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(2)
        .setPath("/inventory/management")
        .setComponent(AdminStore(InventoryManagement))
        .setRole(roles.admin_store)
        .setTitle("View/Add Job Card")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(3)
        .setPath("/inventory/product")
        .setComponent(AccountsExclude(productEntry))
        .setRole(roles.accounts_exclude)
        .setTitle("Add Service/Spare Parts")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(4)
        .setPath("/inventory/add-supplier")
        .setComponent(AdminStore(AddSupplier))
        .setRole(roles.admin_store)
        .setTitle("Add Supplier")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(5)
        .setPath("/inventory/supplier-payment")
        .setComponent(AdminStore(ViewSupplierPayment))
        .setRole(roles.admin_store)
        .setTitle("Supplier Payment")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(), 

    RouteBuilder.getBuilder(6)
        .setPath("/inventory/vehicle")
        .setComponent(AccountsExclude(vehicleInfoEntry))
        .setRole(roles.accounts_exclude)
        .setTitle("Vehicle List/Entry")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),


    RouteBuilder.getBuilder(7)
        .setPath("/product/list")
        .setComponent(viewProducts)
        .setTitle("Product/Service List")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
  
    RouteBuilder.getBuilder(8)
        .setPath("/inventory/add-Inventory")
        .setComponent(AdminStore(addProductInventory))
        .build(),


    RouteBuilder.getBuilder(9)
        .setPath("/invoice/outside-service/:invoiceId")
        .setComponent(AdminStore(invoiceOutsideService))
        .setIsLink(false)
        .setTitle("Services From Outside")
        .build(),


    RouteBuilder.getBuilder(10)
        .setPath("/inventory/supplier-payment-add")
        .setComponent(AddSupplierPayment)
        .setTitle("Add Supplier Payment")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),
   
    RouteBuilder.getBuilder(11)
        .setPath("/jobCard/new")
        .setComponent(AdminStore(JobCardEntry))
        .setRole(roles.admin_store)
        .setTitle("New Job Card")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),
  

];

const corporateClientRoutes: IRoute[] = [
    RouteBuilder.getBuilder(1) 
        .setPath("/corporate-client/list")
        .setComponent(ViewCorporateClient)
        .setTitle("Private Clients")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(2) 
        .setPath("/corporate-client/list")
        .setComponent(ViewCorporateClient)
        .setTitle("Corporate Clients")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(3)
        .setPath("/corporate-client/new")
        .setComponent(AccountPlusOperations(addCorporateClient))
        .setTitle("Add Corporate Client")
        .setIsLink(false)
        .setIsPrivate(true)
        .build()
];

const printingRoutes: IRoute[] = [
    RouteBuilder.getBuilder(1)
        .setPath("/printing/quatation")
        .setComponent(printQuotation)
        .setTitle("Quatation Print")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(2)
        .setPath("/printing/invoice")
        .setComponent(printSalesInvoice)
        .setTitle("Sales Invoice Print")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),


    //RouteBuilder.getBuilder(3)
    //    .setPath("/printing/corporate-client")
    //    .setComponent(printCorporateClient)
    //    .setTitle("Corporate Client Print")
    //    .setIsLink(true)
    //    .setIsPrivate(true)
    //    .build(),

    //RouteBuilder.getBuilder(4)
    //    .setPath("/printing/sub-corporate-client")
    //    .setComponent(printSubCorporateClient)
    //    .setTitle("Sub Corporate Client Print")
    //    .setIsLink(true)
    //    .setIsPrivate(true)
    //    .build(),

];

const generalRoutes: IRoute[] = [

    RouteBuilder.getBuilder(1)
        .setPath("/general/view-drivers-commission")
        .setComponent(viewDriversCommission)
        .setIcon("VDC")
        .setTitle("View Driver's Commission")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(2)
        .setPath("/general/drivers-commission/new/:invoiceId?")
        .setComponent(driversCommission)
        .setIcon("DC")
        .setTitle("Driver's Commission")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),

    // RouteBuilder.getBuilder(3)
    //     .setPath("/general/print-drivers-commission")
    //     .setComponent(printDriversCommission)
    //     .setIcon("PDC")
    //     .setTitle("Print Driver's Commission")
    //     .setIsLink(true)
    //     .setIsPrivate(true)
    //     .build(),

    //RouteBuilder.getBuilder(3)
    //    .setPath("/general/storeslip")
    //    .setComponent(storeSlip)
    //    .setTitle("Store Slip")
    //    .setIsLink(true)
    //    .setIsPrivate(true)
    //    .build(),

    //RouteBuilder.getBuilder(4)
    //    .setPath("/general/user")
    //    .setComponent(userManagement)
    //    .setTitle("User Management")
    //    .setIsLink(true)
    //    .setIsPrivate(true)
    //    .build(),
];

const usersRoutes: IRoute[] = [
    RouteBuilder.getBuilder(1)
        .setPath("/users/list")
        .setComponent(Admin(viewUsers))
        .setRole(roles.admin)
        .setTitle("Users")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
    RouteBuilder.getBuilder(2)
        .setPath("/users/new")
        .setComponent(Admin(addUser))
        .setRole(roles.admin)
        .setTitle("Add User")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
];


const routes: IRoute[] = [


    RouteBuilder.getBuilder(1)
        .setPath("/dashboard")
        .setComponent(dashboard)
        .setTitle("Dashboard")
        .setIcon("pe-7s-graph")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(2)
        .setChildren(accountingRoutes)
        .setTitle("Accounting")
        .setRole(roles.executive_accounts)
        .setIcon("pe-7s-box1")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(3)
        .setPath("/quotation/list")
        .setComponent(viewQuotation)
        .setRole(roles.admin_operations)
        .setChildren(quotationRoutes)
        .setTitle("Quotation/Estimation")
        .setIcon("pe-7s-comment")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),


    RouteBuilder.getBuilder(4) 
        .setChildren(invoiceRoutes)
        .setTitle("Invoice")
        .setIcon("pe-7s-cash")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(5) 
        .setChildren(invoiceExtraRoutes)
        .setTitle("Invoice Extra")
        .setIcon("pe-7s-cash")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),


    RouteBuilder.getBuilder(6)
        .setChildren(accountsRoutes)
        .setTitle("Transactions")
        .setRole(roles.executive_accounts)
        .setIcon("pe-7s-id")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(7)
        .setChildren(inventoryRoutes)
        .setTitle("Inventory")
        .setIcon("pe-7s-note2")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(8)
        .setChildren(corporateClientRoutes)
        .setTitle("Customer Management")
        .setIcon("pe-7s-note")
        .setIsLink(true)
        .setIsExact(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(9)
        .setPath("/corporate-client/list/:name-:accountId")
        .setComponent(AccountPlusOperations(ViewCorporateClient))
        .setRole(roles.accounts_plus_operations)
        .setChildren(corporateClientRoutes)
        .setTitle("Corporate Client")
        .setIcon("pe-7s-note")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(10)
        .setPath("/vehicle-sales-history/:accountId")
        .setComponent(AccountPlusOperations(vehicleSalesHistory))
        // .setChildren(corporateClientRoutes)
        .setTitle("Vehicle Sales History")
        .setIcon("pe-7s-note")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(11)
        .setPath("/printing")
        .setChildren(printingRoutes)
        .setTitle("Print")
        .setIcon("pe-7s-print")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(12)
        .setPath("/general")
        .setChildren(generalRoutes)
        .setTitle("General")
        .setIcon("pe-7s-bookmarks")
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),


    RouteBuilder.getBuilder(13)
        .setChildren(usersRoutes)
        .setRole(roles.admin)
        .setTitle("User Management")
        .setIcon("pe-7s-box1")
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),

    RouteBuilder.getBuilder(14)
        .setIcon("pe-7s-power")
        .setTitle("Logout")
        .setPath("/logout")
        .setComponent(logOut)
        .setIsLink(true)
        .setIsPrivate(true)
        .build(),
     

    RouteBuilder.getBuilder(15)
        .setPath("/")
        .setComponent(dashboard)
        .setIsLink(false)
        .setIsPrivate(true)
        .build(),


];

export default routes;