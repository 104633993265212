import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import graphQl from "../services/GraphQL";
import {IJournalModel} from "../models/SupplierModel";

export interface IJournalState {
    isLoading: boolean,
    journals: Array<IJournalModel>,
    cashBalance: any
    error: Error
}


//Request GET actions
export interface RequestGetVehicles extends Action<string> {
    type: "REQUEST_GET_JOURNALS";
}

export interface RespondGetVehicleTypes extends Action<string> {
    type: "RESPOND_GET_JOURNALS";
    payload: Array<IJournalModel> | null;
    error: Error | null
}

export interface RequestCashBalance extends Action<string> {
    type: "REQUEST_CASH_BALANCE";
}

export interface RespondCashBalance extends Action<string> {
    type: "RESPOND_CASH_BALANCE";
    payload: any | null;
    error: Error | null
}

export interface RESET {
    type: "RESET_ALL";
}


type KnownAction = RequestGetVehicles |
    RespondGetVehicleTypes |
    RequestCashBalance |
    RespondCashBalance |
    RESET

export const actionCreators = {

    requestJournals: (fromDate: Date, toDate: Date, voucherNo: string | null = null): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_JOURNALS" });
            const authData = appState.authorization!.authData;
            if (voucherNo) {
                const jvn = parseInt(voucherNo);
                const data = await graphQl.executeQuery<{ journals: Array<IJournalModel> }>("journals:journalsByVoucharNo(jvn:$jvn){ id type amount voucherDate comments createDate createdUser{ fullName } voucherNo account { id name } }", ["$jvn:Int!"], { jvn }, authData);
                dispatch({ type: "RESPOND_GET_JOURNALS", payload: data.journals, error: null });
            } else {
                const data = await graphQl.executeQuery<{ journals: Array<IJournalModel> }>("journals:journalsByDate(fromDate:$fromDate, toDate:$toDate){ id type amount voucherDate comments createDate createdUser{ fullName } voucherNo account { id name } }", ["$fromDate:Date!", "$toDate:Date!"], { fromDate, toDate }, authData);
                dispatch({ type: "RESPOND_GET_JOURNALS", payload: data.journals, error: null });
            }
            
        } catch (e) {
            dispatch({ type: "RESPOND_GET_JOURNALS", payload: null, error: e });
        }
    },

    requestCashBalance: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_CASH_BALANCE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ cashBalance: any }>("cashBalance(){ }", [], { }, authData);
            dispatch({ type: "RESPOND_GET_JOURNALS", payload: data.cashBalance, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_JOURNALS", payload: null, error: e });
        }
    },
    
    resetAll: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "RESET_ALL" });
    },
    
};

const unloadedState: IJournalState = {
    isLoading: false,
    error: null,
    cashBalance: null,
    journals: []
};


export const reducer: Reducer<IJournalState> = (state: IJournalState | undefined, incomingAction: Action): IJournalState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        

        case "REQUEST_GET_JOURNALS":
            return {
                ...state,
                isLoading: true,
            };
            
        case "RESPOND_GET_JOURNALS":
            return {
                ...state,
                isLoading: true,
                error: action.error,
                journals: action.payload || []
            };

        case "RESET_ALL":
            return {
                ...state,
                isLoading: false,
                error: null,
                //cashBalance: null,
                journals: []
            };

        default:
            return state;
    }
}
