import * as React from "react";
import { Row, Col, Table, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Card from "../../Card/Card"
import { Link, Redirect } from "react-router-dom";
import "../../../assets/css/quotation/view-quotation.css"
import * as JobCard from "../../../store/JobCard";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import Select from "react-select";
import NumberFormat from "react-number-format";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter, FILTER_TYPES, textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { Components } from "../../../common/Authorization";
import { toastr } from "react-redux-toastr";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

interface ViewInvoiceProps {

}

interface ViewInvoiceState {
    totalCount: number,
    pageSize: number,
    page: number
}

type InvoiceProps = ViewInvoiceProps & JobCard.JobCardState & typeof JobCard.actionCreators;

function getFilterProps() {
    return {
        //placeholder: placeholder,
        placeholder: " ",
        style: {
            position: "relative",
            textAlign: "left",
            backgroundColor: "white",
            opacity: 1
        },
        className: "inputField"
    }
}

class ViewJobCard extends React.PureComponent<InvoiceProps, ViewInvoiceState> {

    state = {
        totalCount: 0,
        pageSize: 5,
        page: 1
    };

    filterDate = null;

    componentDidMount() {
        this.props.resetJobCardState();
        this.props.requestGetJobCards(this.props.page, this.props.pageSize, "id", false, "", "");
    }

    componentWillUnmount(): void {
        //this.props.resetState();
        this.props.resetJobCardState();
    }


    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {
        if (filters["createDate"] && !filters["createDate"].filterVal.date) delete filters.createDate;
        const filterKeys = filters ? Object.keys(filters).map(key => key).join(",") : "";
        const filterValues = filters ? Object.keys(filters).map(key => {
            const value = filters[key];
            if (value.filterType === "DATE") {
                return encodeURIComponent(value.filterVal.date.toISOString());
            } else {
                return encodeURIComponent(value.filterVal);
            }
        }).join(",") : "";
        sortField = sortField || "id";
        const ascending = sortOrder == "asc";
        this.props.requestGetJobCards(page, sizePerPage, sortField, ascending, filterKeys, filterValues);
    };


    render() {
        const { jobCards, page, pageSize, totalCount } = this.props;
        let data = [];
        if (jobCards && jobCards.items) {
            data = jobCards.items.map(prop => ({
                jobCardNo: prop.jobCardNo,
                createDate: new Date(prop.createDate).toDateString(),
                invoiceCode: (prop.invoice || { invoiceCode: "" }).invoiceCode,
                status: (prop.status === 0) ? <span style={{ color: "red" }}> Draft</span> : <span style={{ color: "Green" }}>Posted</span>,
                actions: <span>

                    <a href="#" title="Print">  <i className="pe-7s-print" style={{ fontSize: 24, fontWeight: 600, color: 'gray' }} />&nbsp;&nbsp;&nbsp;  </a>
                    {prop.status == 0 &&
                        <a href={`/jobCard/new?jobCardId=${prop.id}`} target="_blank" title="Print">  <i className="pe-7s-edit" style={{ fontSize: 24, fontWeight: 600, color: 'green' }} />&nbsp;&nbsp;&nbsp;  </a>
                    }
                </span>


            }));
        }
        const columns = [
            {
                text: <strong>Job Card</strong>,
                dataField: "jobCardNo",
                sort: true,
                filter: textFilter(getFilterProps()),

            },
            {
                text: <strong>Invoice No</strong>,
                dataField: "invoiceCode",
                sort: true,
                filter: textFilter(getFilterProps()),
            },
            {
                text: <strong>Create Date</strong>,
                dataField: "createDate",
                sort: true,
                filter: dateFilter({
                    comparatorStyle: {
                        display: "none"
                    },
                    style: {
                        display: "contents"
                    },
                    dateStyle: {
                        backgroundColor: "white",
                        opacity: 1,
                        marginLeft: 0,
                        position: "inherit",
                        width: "90%",
                        marginTop: -7
                    },
                    dateClassName: "inputField"
                })
            },
            {
                text: <strong>Status</strong>,
                dataField: "status",
                sort: true,
                filter: false

            },
            {
                text: <strong>Actions</strong>,
                dataField: "actions",
                headerStyle: { verticalAlign: "baseline" }
            }

        ];

        return (
            <div >
                <Row>
                    <Col md={12} className="invoice-section">

                        <Card
                            title=""
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                                <form>

                                    <Row>
                                        <Col>
                                            <div className="header-title">
                                                <h4 >
                                                    <i className='pe-7s-mail qutIcon' style={{ fontSize: 30 }}></i>
                                                        View Job Cards
                                                        </h4>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="newBtn">
                                                <Components.AdminOperationsComponent
                                                    showMessage={false}
                                                    component={
                                                        <Link to="/jobCard/new" target="_blank" className='btn-sm'>
                                                            <i className="fa fa-plus-square-o" style={{ fontSize: 16, color: 'red', }} />&nbsp;Add New Job Card
                                                        </Link>
                                                    }
                                                />

                                            </div>
                                        </Col>
                                    </Row>

                                    <BootstrapTable
                                        remote={{
                                            filter: true,
                                            pagination: true,
                                            sort: true,
                                            cellEdit: false
                                        }}
                                        onTableChange={this.handleTableChange}
                                        bootstrap4
                                        data={data}
                                        columns={columns}
                                        pagination={paginationFactory({ page: page, sizePerPage: pageSize, totalSize: totalCount })}
                                        filter={filterFactory()}
                                        keyField="invoiceNo"
                                        striped
                                        hover
                                        condensed
                                        bordered={false}
                                        noDataIndication="No Job Card Found!"
                                    />

                                    {/*<Table responsive hover>*/}
                                    {/*    <thead>*/}
                                    {/*        <tr>*/}
                                    {/*            <th>*/}
                                    {/*                <strong>Invoice No</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className="filter-input">*/}
                                    {/*                    <input className='form-control inputField' type='search' />*/}
                                    {/*                </div>*/}
                                    {/*            </th>*/}

                                    {/*            <th>*/}
                                    {/*                <strong>Client</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className="filter-input">*/}
                                    {/*                    <input className='form-control inputField' type='search' />*/}
                                    {/*                </div>*/}
                                    {/*            </th>*/}

                                    {/*            <th>*/}
                                    {/*                <strong>Vehicle</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className="filter-input">*/}
                                    {/*                    <input className='form-control inputField' type='search' />*/}
                                    {/*                </div>*/}
                                    {/*            </th>*/}

                                    {/*            <th>*/}
                                    {/*                <strong>Ref. Qoutaion</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className="filter-input">*/}
                                    {/*                    <input className='form-control inputField' type='search' />*/}
                                    {/*                </div>*/}
                                    {/*            </th>*/}
                                    {/*            <th>*/}
                                    {/*                <strong>Sales Date</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className="filter-input">*/}
                                    {/*                    <input className='form-control inputField' type='date' />*/}
                                    {/*                </div>*/}
                                    {/*            </th>*/}
                                    {/*            <th style={{ width: 150 }}>*/}
                                    {/*                <strong>Total</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className='form-control inputFieldDisable'>*/}

                                    {/*                </div>*/}
                                    {/*            </th>*/}
                                    {/*            <th style={{ width: 150 }}>*/}
                                    {/*                <strong>Discount</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className='form-control inputFieldDisable'>*/}

                                    {/*                </div>*/}
                                    {/*            </th>*/}
                                    {/*            <th>*/}
                                    {/*                <strong>Action</strong>*/}
                                    {/*                <i className="fa fa-sort"></i>*/}
                                    {/*                <div className='form-control inputFieldDisable'>*/}

                                    {/*                </div>*/}
                                    {/*            </th>*/}

                                    {/*        </tr>*/}
                                    {/*    </thead>*/}
                                    {/*    <tbody>*/}
                                    {/*        {this.props.salesInvoices == null ?*/}
                                    {/*            <tr> <td colSpan={8}>  No Invoice Found! </td></tr>*/}
                                    {/*            : this.props.salesInvoices.items.length > 0 ?*/}
                                    {/*                this.props.salesInvoices.items.map((prop, key) => {*/}
                                    {/*                    return (*/}
                                    {/*                        <tr key={key}>*/}
                                    {/*                            <td>#{prop.invoiceCode}</td>*/}
                                    {/*                            <td>{prop.client && prop.client.name}</td>*/}
                                    {/*                            <td>{prop.vehicle && this._getRegistrationNumber(prop.vehicle.licenseNo)}</td>*/}
                                    {/*                            <td>{prop.qoutation && "Q-000" + prop.qoutation.qtNo}</td>*/}
                                    {/*                            <td >{*/}
                                    {/*                                new Date(prop.createDate).toDateString()*/}
                                    {/*                            }</td>*/}
                                    {/*                            <td><NumberFormat value={prop.total} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>*/}
                                    {/*                            <td><NumberFormat value={prop.discount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></td>*/}
                                    {/*                            <td style={{ width: 100 }}> <a href={`/print/sales-invoice/${prop.id}`} target="_blank">*/}
                                    {/*                                <i className="pe-7s-print" style={{ fontSize: 24, fontWeight: 600, color: 'red' }}></i>&nbsp;&nbsp;&nbsp;*/}
                                    {/*                            <i className="pe-7s-less" style={{ fontSize: 24, color: 'red' }}></i>*/}
                                    {/*                            </a> </td>*/}

                                    {/*                        </tr>*/}
                                    {/*                    );*/}
                                    {/*                }) : <tr> <td colSpan={8}>  No Invoice Found! </td></tr>*/}
                                    {/*        }*/}
                                    {/*    </tbody>*/}


                                    {/*</Table>*/}
                                    {/*<Row>*/}
                                    {/*    <Col>*/}
                                    {/*        <Pagination aria-label="Page navigation example">*/}

                                    {/*            {this.paginate()}*/}

                                    {/*        </Pagination>*/}

                                    {/*    </Col>*/}
                                    {/*</Row>*/}
                                </form>
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    
}
export default connect((state: ApplicationState) => { return { ...state.authorization, ...state.jobCard } }, { ...JobCard.actionCreators })(ViewJobCard as any);;