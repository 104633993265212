import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import graphQl from "../services/GraphQL";
import { ISalesInvoice } from "../models/SalesInvoice";
import {IVehicleModel} from "../models/VehicleModel";
import {IVehicleState} from "./Vehicle";
import {ICommissionModel, ICommissionWrapper} from "../models/CommissionModel";

export interface ICommissionState {
    vehicle: IVehicleModel | null,
    commissions: Array<ICommissionModel> | null,
    commission: ICommissionModel | null,
    fetchError: Error | null,
    isLoading: boolean,
    isAdding: boolean,
    commissionAdded: boolean,
}

export interface RequestGetInvoices extends Action<string> {
    type: "REQUEST_GET_VEHICLE_INVOICES";
}

export interface RespondGetInvoices extends Action<string> {
    type: "RESPOND_GET_VEHICLE_INVOICES";
    payload: IVehicleModel | null;
    error: Error | null;
}

export interface RequestGetCommissions extends Action<string> {
    type: "REQUEST_GET_COMMISSIONS";
}

export interface RespondGetCommissions extends Action<string> {
    type: "RESPOND_GET_COMMISSIONS";
    payload: Array<ICommissionModel> | null;
    error: Error | null;
}

export interface RequestGetCommission extends Action<string> {
    type: "REQUEST_GET_COMMISSION";
}

export interface RespondGetCommission extends Action<string> {
    type: "RESPOND_GET_COMMISSION";
    payload: ICommissionModel | null;
    error: Error | null;
}

export interface RequestAddCommission extends Action<string> {
    type: "REQUEST_ADD_COMMISSION";
}

export interface RespondAddCommission extends Action<string> {
    type: "RESPOND_ADD_COMMISSION";
    payload: ICommissionModel | null;
    error: Error | null;
}

export interface ResetCommission {
    type: "RESET_COMMISSION";
}

type KnownAction = RequestGetInvoices |
    RespondGetInvoices |
    RequestGetCommissions |
    RespondGetCommissions |
    RequestGetCommission |
    RespondGetCommission |
    RequestAddCommission |
    RespondAddCommission |
    ResetCommission;

export const actionCreators = {
    requestGetInvoices: (licenseNo: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_VEHICLE_INVOICES" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ vehicle: IVehicleModel }>("vehicle(licenseNo:$licenseNo){id invoices {id invoiceCode total totalCommission commissionVouchers}}", ["$licenseNo:String"], { licenseNo }, authData);
            dispatch({ type: "RESPOND_GET_VEHICLE_INVOICES", payload: data.vehicle, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_VEHICLE_INVOICES", payload: null, error: e });
        }
    },
    requestGetCommissions: (page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_COMMISSIONS" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ commissions: ICommissionWrapper }>("commissions(page:$page,pageSize:$pageSize){items { id name commissionDate amount phone voucher invoice{ invoiceCode total vehicle { licenseNo } } } pageSize page totalCount}", ["$page:Int!","$pageSize:Int!"], { page, pageSize }, authData);
            dispatch({ type: "RESPOND_GET_COMMISSIONS", payload: data.commissions.items, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_COMMISSIONS", payload: null, error: e });
        }
    },
    requestGetCommission: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_COMMISSION" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ commission: ICommissionModel }>("commission(id:$id){id commissionDate name phone amount voucher invoice{ invoiceCode total }}", ["id:Int!"], { id }, authData);
            dispatch({ type: "RESPOND_GET_COMMISSION", payload: data.commission, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_COMMISSION", payload: null, error: e });
        }
    },
    requestAddCommission: (commission: ICommissionModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_COMMISSION" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addCommission: ICommissionModel }>("addCommission(commission:$commission){id}", ["$commission:CommissionInputType!"], { commission }, authData);
            dispatch({ type: "RESPOND_ADD_COMMISSION", payload: data.addCommission, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_COMMISSION", payload: null, error: e });
        }

    },
    resetCommissionState: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: "RESET_COMMISSION" });
    },
};

const unloadedState: ICommissionState = {
    commissions: [],
    commission: null,
    vehicle: null,
    isAdding: false,
    fetchError: null,
    isLoading: false,
    commissionAdded: false,
};

export const reducer: Reducer<ICommissionState> = (state: ICommissionState | undefined, incomingAction: Action): ICommissionState => {
    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_GET_VEHICLE_INVOICES":
        case "REQUEST_GET_COMMISSIONS":
        case "REQUEST_GET_COMMISSION":
            return {
                ...state,
                isLoading: true
            };
        case "RESPOND_GET_VEHICLE_INVOICES":
            return {
                ...state,
                vehicle: action.payload,
                fetchError: action.error,
                isLoading: false
            };
        case "RESPOND_GET_COMMISSIONS":
            return {
                ...state,
                commissions: action.payload,
                fetchError: action.error,
                isLoading: false
            };
        case "RESPOND_GET_COMMISSION":
            return {
                ...state,
                commission: action.payload,
                fetchError: action.error,
                isLoading: false
            };
        case "REQUEST_ADD_COMMISSION":
            return {
                ...state,
                isAdding: true,
            };
        case "RESPOND_ADD_COMMISSION":
            return {
                ...state,
                isAdding: false,
                commission: action.payload,
                commissionAdded: !action.error,
            };
        case "RESET_COMMISSION":
            return {
                ...state,
                commissions: [],
                commission: null,
                vehicle: null,
                isAdding: false,
                fetchError: null,
                isLoading: false,
                commissionAdded: false,
            };

        default:
            return state;
    }
};

