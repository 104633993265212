import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import { IEmployeeModel } from "../models/EmployeeModel";
import graphQl from "../services/GraphQL";
import { IVehicleModel } from "../models/VehicleModel";

export interface IEmployeeState {
    isLoading?: boolean,
    employees?: Array<IEmployeeModel> | null,
    error: Error | null,
    employee: IEmployeeModel | null,
    employeePosted: boolean;
    employeePosting: boolean;
    isLoaded: boolean;
}


//Request GET actions
export interface RequestGetEmployee extends Action<string> {
    type: "REQUEST_GET_EMPLOYEE";
}
//Request add actions
export interface RequestAddUpdateEmployee extends Action<string> {
    type: "REQUEST_ADD_UPDATE_EMPLOYEE";
}

//Response GET actions
export interface RespondGetEmployee extends Action<string> {
    type: "RESPOND_GET_EMPLOYEE";
    payload: Array<IEmployeeModel> | null;
    error: Error | null
}

//Respond add action
export interface RspondAddUpdateEmployee extends Action<string> {
    type: "RESPOND_ADD_UPDATE_EMPLOYEE";
    payload: IEmployeeModel | null;
    error: Error | null
}



//Reset

export interface RESETEPLOYEE {
    type: "RESET_EMPLOYEE";
}


type KnownAction = RequestGetEmployee | RequestAddUpdateEmployee | RespondGetEmployee | RspondAddUpdateEmployee | RESETEPLOYEE

export const actionCreators = {

    requestAddEmployee: (employeeInfo: IEmployeeModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_EMPLOYEE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ employee: IEmployeeModel }>("employee:addEmployeeAccount(employeeInfo:$employeeInfo){name}", ["$employeeInfo:EmployeeInputType!"], { employeeInfo }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_EMPLOYEE", payload: data.employee, error: null });
            dispatch({ type: "RESET_EMPLOYEE" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_EMPLOYEE", payload: null, error: e });
        }
    }


}

const unloadedState: IEmployeeState = {
    isLoading: false,
    employees: [],
    error: null,
    employee: null,
    employeePosted: false,
    employeePosting: false,
    isLoaded:false
}


export const reducer: Reducer<IEmployeeState> = (state: IEmployeeState | undefined, incomingAction: Action): IEmployeeState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_GET_EMPLOYEE":
            return {
                ...state,
                isLoading: true,
                employeePosted: false
            }

        case "REQUEST_ADD_UPDATE_EMPLOYEE":
            return {
                ...state,
                isLoading: false,
                employeePosting: true,
                employeePosted: false
            }

        case "RESPOND_ADD_UPDATE_EMPLOYEE": {
            return {
                ...state,
                employeePosted: action.payload !== null,
                isLoading: false,
                employeePosting: false,
                employee: action.payload,
                error:action.error
            }
        }

        case "RESPOND_GET_EMPLOYEE":
            return {
                ...state,
                isLoading: false,
                employeePosting: false,
                employeePosted: false,
                employees: action.payload,
                error: action.error,
                isLoaded: action.payload !== null
            }

        case "RESET_EMPLOYEE":
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                employeePosting: false,
                employeePosted: false,
            }

        default:
            return state;
    }
}
