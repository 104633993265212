import * as React from "react";
import {
    Grid,
    Row,
    Col,
    InputGroup,
    Button, FormControl, Table
} from "react-bootstrap";

import { Card } from "../../Card/Card"; import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import "../../../assets/css/viewStock.css";
import * as Qoutation from "../../../store/Qoutation";
import * as Supplier from "../../../store/Supplier";
import * as Inventory from "../../../store/Inventory";
import * as JobCard from "../../../store/JobCard";
import "../../../assets/css/jobcard/add-job-card.css";
import { ISalesInvoiceItem, IInvoiceStockItem } from "../../../models/SalesInvoice";
import Select from "react-select";
import { IJobCartItem } from "../../../models/Qoutation";
import { ISupplierModel } from "../../../models/SupplierModel";
import { IProductStockItemModel, ProductStockItemModel, IProductStockItemCount } from "../../../models/ProductStock";
import { Redirect } from "react-router";
import * as Account from "../../../store/Account";
import { toastr } from "react-redux-toastr";
import { Input } from "reactstrap";
import cloneDeep from 'lodash/cloneDeep';
import NumberFormat from "react-number-format";
import AddOtherProduct from "./AddOtherProduct";
import { quantity } from "chartist";
import classes from "*.module.css";

interface IInvoiceReportState {
    invNo: number;
    supplierId: number;
    invoiceId: number;
    productStockItems: Array<IProductStockItemModel>;
    productWithModelStockCounts: Array<IProductStockItemCount>;
    invoiceClosed: boolean;
    showModal: boolean,
}


interface IInvoiceReportDropdown {
    label: string,
    value: number;
}

type InvoiceReportProps = JobCard.JobCardState & typeof JobCard.actionCreators & Account.AccountState & Inventory.IInventoryState & typeof Inventory.actionCreators & Qoutation.IQoutationState & typeof Qoutation.actionCreators & typeof Account.actionCreators & Supplier.ISupplierState & typeof Supplier.actionCreators;

class InvoiceReport extends React.PureComponent<InvoiceReportProps, IInvoiceReportState> {

    constructor(props: any) {
        super(props);
        this.state = {
            invNo: 0,
            productStockItems: null,
            supplierId: 0,
            invoiceId: 0,
            productWithModelStockCounts: null,
            invoiceClosed: false,
            showModal: false
        }
    }


    componentDidMount() {
        window.addEventListener("focus", this.focusHandler, false);
    }

    focusHandler = () => {
        this.state.productStockItems && this.state.productStockItems.forEach(item => {
            item.productModelId > 0 && this.props.requestGetProductStockItemCountByModel(item.productId, item.productModelId);
        })
    }

    componentWillReceiveProps(nextProps: InvoiceReportProps) {
        if (nextProps.salesInvoice) {
            var invoiceId = nextProps.salesInvoice.id;
            if (invoiceId !== this.state.invoiceId) {

                this.props.requestGetOutsideServices(invoiceId);
                this.props.requestGetSalesInvoiceCommission(invoiceId);

                this.setState({ invoiceId })
            }
        }

    }

    handleInvoiceSearch = () => {
        if (this.state.invNo > 0) {
            this.setState({ invoiceId: 0 })
            this.props.requestGetJobCardStockItemsByInvoice(this.state.invNo,null);
            this.props.requestGetSalesInvoice(0, this.state.invNo);
        }
    }

    _renderProductStockItems(): any {

        const { jobCardStockItems } = this.props;        
        var total = 0;
        jobCardStockItems.forEach(ps => {
            total += ps.productStockItem.costPrice * ps.quantity
        })
        return (
            <Table>
                <tr className="th-service-header ">
                    <td>Item Name</td>
                    <td>Item Model</td>
                    <td>Item Cost Price</td>
                    <td>Item Quantity</td>
                    <td>Return Quantity</td>
                    <td>Use</td>
                    <td>Total</td>
                </tr>

                {jobCardStockItems && jobCardStockItems.map(ps => {
                    return <tr>
                        <td style={{ width: "200px" }}>
                            <p>
                                {ps.jobCardItem && ps.jobCardItem.product && ps.jobCardItem.product.name}
                            </p>
                        </td>
                        <td style={{ width: "200px" }}>
                            <p>
                                {ps.jobCardItem && ps.jobCardItem.productModel && ps.jobCardItem.productModel.name}
                            </p>
                        </td>

                        <td style={{ width: "200px" }}>
                            <p>
                                {ps.productStockItem && ps.productStockItem.costPrice}
                            </p>
                        </td>

                        <td >{ps.jobCardItem && ps.jobCardItem.quantity }</td>
                        <td >{ps.jobCardItem && ps.jobCardItem.returnQuantity}</td>
                        <td >{ps.jobCardItem && ps.jobCardItem.quantity - ps.jobCardItem.returnQuantity}</td>

                    
                        <td style={{ width: "200px" }}>
                            <p>
                                <NumberFormat value={ps.productStockItem.costPrice * ps.quantity} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                            </p>
                        </td>
                    </tr>
                        
                })}
                <tr>
                    <td colSpan={5} style={{ textAlign: "right" }}> <b>Total</b>  </td>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                        <p>
                            <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div><b>{value}</b></div>} />
                        </p>
                    </td>
                </tr>


            </Table>
        );
    }

    _renderClosedInvoiceItems(): any {
        const { jobCardStockItems, invoiceCommissions, salesInvoice, outsideServices } = this.props;

        var commissions = 0;
        var costPrice = 0;
        var outsideServiceCost = 0;
        var invoiceTotal = 0;
        var balance = 0;

        commissions = invoiceCommissions && invoiceCommissions.reduce((a, x, i, sr) => a + x.amount, 0);
        costPrice = jobCardStockItems && jobCardStockItems.reduce((a, b, index, src) => { return a + b.productStockItem.costPrice }, 0);
        outsideServiceCost = outsideServices && outsideServices.reduce((a, x, i, sr) => a + x.cost, 0);
        invoiceTotal = salesInvoice && salesInvoice.total;


        balance = invoiceTotal - (commissions + outsideServiceCost + costPrice);

        return (
            <Table>
                <tr className="th-service-header ">
                    <td>Invoice No.</td>
                    <td>Job Card No.</td>
                    <td>Items Cost Price</td>
                    <td>Outside Service Cost</td>
                    <td>Commissions</td>
                    <td>Invoice Total</td>
                    <td>Balance</td>
                </tr>
                <tr>
                    <td style={{ width: "200px" }}>
                        <p>
                            {salesInvoice && salesInvoice.invoiceCode}
                        </p>
                    </td>
                    <td style={{ width: "200px" }}>
                        <p>
                            {jobCardStockItems && jobCardStockItems.length>0&&jobCardStockItems[0].jobCardItem && jobCardStockItems[0].jobCardItem.jobCard && jobCardStockItems[0].jobCardItem.jobCard.jobCardNo}
                        </p>
                    </td>
                    <td style={{ width: "200px" }}>
                        <p>
                            {costPrice}
                        </p>
                    </td>

                    <td style={{ width: "200px" }}>
                        <p>
                            {outsideServiceCost}
                        </p>
                    </td>
                    <td style={{ width: "200px" }}>
                        <p>
                            {commissions}
                        </p>
                    </td>
                    <td style={{ width: "200px" }}>
                        <p>
                            {invoiceTotal}
                        </p>
                    </td>
                    <td style={{ width: "200px" }}>
                        <p>
                            <NumberFormat value={balance} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                        </p>
                    </td>
                </tr>



            </Table>
        );
    }

    _renderSalesCommission(): any {
        var commissions = this.props.invoiceCommissions;
        return (
            <Row>
                <Col md={12}>
                    <Row style={{ backgroundColor: "#d3d3d3b2" }}>
                        <Col md={12}>
                            <label style={{ fontSize: 14 }}><b>Invoice Commissions</b></label>
                        </Col>

                    </Row>

                    <br />

                    {commissions && commissions.length > 0 &&
                        <Table>

                            <tr className="th-service-header ">
                                <td>Name</td>
                                <td>Phone</td>
                                <td>Amount </td>
                            </tr>
                            {
                                commissions.map(item => {
                                    return <tr>
                                        <td style={{ width: "200px" }}>
                                            <p>{item && item.name}</p>
                                        </td>

                                        <td style={{ width: "200px" }}>
                                            <p>{item && item.phone}</p>
                                        </td>

                                        <td style={{ width: "200px" }}>
                                            <p><NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></p>
                                        </td>
                                    </tr>
                                })
                            }
                        </Table>
                    }
                </Col>
            </Row>
        )

    }

    _renderOutsideService(): any {
        var outsideServices = this.props.outsideServices;
        return (
            <Row>
                <Col md={12}>
                    <Row style={{ backgroundColor: "#d3d3d3b2" }}>
                        <Col md={12}>
                            <label style={{ fontSize: 14 }}><b>Service From Outside</b></label>
                        </Col>
                    </Row>

                    <br />

                    {outsideServices && outsideServices.length > 0 &&
                        <Table>

                            <tr className="th-service-header ">
                                <td>Supplier</td>
                                <td>Services</td>
                                <td>Cost </td>
                                <td>Payment</td>
                                <td>Payment Method</td>
                            </tr>
                            {
                                outsideServices.map(item => {
                                    return <tr>
                                        <td style={{ width: "200px" }}>
                                            <p>{item.supplier && item.supplier.name}</p>
                                        </td>

                                        <td style={{ width: "200px" }}>
                                            <p>{item.services && item.services}</p>
                                        </td>

                                        <td style={{ width: "200px" }}>
                                            <p><NumberFormat value={item.cost} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></p>
                                        </td>

                                        <td style={{ width: "200px" }}>
                                            <p><NumberFormat value={item.payment} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} /></p>

                                        </td>

                                        <td style={{ width: "200px" }}>
                                            <p> {item.paymentAccount && item.paymentAccount.name}</p>
                                        </td>

                                    </tr>
                                })
                            }

                        </Table>
                    }
                </Col>
            </Row >
        )


    }



    render() {

        return (
            <div className="content">
                <Row>
                    <Col md={8} style={{ margin: "0 auto" }}>

                        <Card
                            content={
                                <div className="mt-5">
                                    <form>
                                        <Row>
                                            <span className=' d-inline shadow ml-3 cost-price-icon-box '>
                                                <i className='pe-7s-id' style={{ fontSize: 25 }}></i>
                                            </span>
                                            <h4 className='ml-3' style={{ marginBottom: 20, marginTop: -20 }}>Invoice Report </h4>
                                        </Row>
                                        <Row>
                                            <Col md={2} className="text-right">
                                                <input className='form-control' type="text" value="INV-" disabled={true} />
                                            </Col>
                                            <Col md={4} className="text-left">
                                                <input className='form-control' type="text" placeholder="Invoice Number"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({ invNo: parseInt(e.target.value) })
                                                    }}
                                                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.keyCode === 13 && this.handleInvoiceSearch}
                                                />

                                            </Col>
                                            <Col md={3}>
                                                <Button className="form-control primary"
                                                    onClick={(e) => {
                                                        this.handleInvoiceSearch();
                                                    }}
                                                >Search</Button>
                                            </Col>
                                        </Row>

                                        {
                                            this.state.invoiceId > 0 &&
                                            <>


                                                <Row>
                                                    <Col md={12}>
                                                        {
                                                            this._renderProductStockItems()
                                                        }
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12} className="text-justify text-uppercase mt-5">

                                                        <br />
                                                        {
                                                            this.state.invoiceId > 0 && this._renderOutsideService()
                                                        }
                                                        <br />
                                                        {
                                                            this.state.invoiceId > 0 && this.props.invoiceCommissions && this.props.invoiceCommissions.length > 0 && this._renderSalesCommission()
                                                        }

                                                    </Col>

                                                </Row>


                                                <Row>
                                                    <Col md={12}>
                                                        {
                                                            this._renderClosedInvoiceItems()
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </form>

                                    <Row>
                                        <Col>
                                            <span style={{ float: "right" }}>
                                                {this.state.invoiceId > 0 && < a className="pull-right" href={`/print/report-printing/${this.state.invoiceId}`} style={{ float: "right" }} target="_blank">Print</a>}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </div >

        );
    }
    componentWillUnmount() {
        this.props.resetState();
    }
}
export default connect((state: ApplicationState) => {
    return {
        ...state.authorization,
        ...state.qoutation,
        ...state.account,
        ...state.supplier,
        ...state.inventory,
        ...state.jobCard
    }
}, {
    ...Qoutation.actionCreators,
    ...Supplier.actionCreators,
    ...Account.actionCreators,
    ...Inventory.actionCreators,
    ...JobCard.actionCreators
})(InvoiceReport as any); 