import * as React from "react";
import {Col, Row, Container, Button, Table, Pagination, PaginationItem, PaginationLink} from "reactstrap";
import Card from "../../Card/Card";
import {Link, Redirect} from "react-router-dom";
import DatePicker from "react-datepicker";
import {RouteComponentProps} from "react-router";
import "../../../assets/css/corporateClient/view-corporate-client.css";
import {type} from "os";
import * as Client from "../../../store/Client";
import * as Account from "../../../store/Account";
import {connect} from "react-redux";
import {ApplicationState} from "../../../store/index";
import "react-datepicker/dist/react-datepicker.css";


interface IViewCorpClientProps {

}

interface ICorporateClientState {
    fromDate: string,
    toDate: string,
    accountId: number,
    isDetails: boolean,
    isCorporateClient: boolean
}

//type Props = IViewCorpClientProps & Client.IClientState & typeof Client.actionCreators & Account.AccountState & typeof Account.actionCreators & RouteComponentProps;

class ViewCorporateClient extends React.PureComponent<any, any> {


    constructor(props: any) {
        super(props);
        const fromDate = new Date();
        fromDate.setFullYear(2020, 2, 30);
        //let fromDate = new Date(date.setDate(date.getDate() - 1));
        //let toDate = new Date(date.setDate(date.getDate() + 1));
        this.state = {
            fromDate: fromDate,
            toDate: new Date(),
            accountId: -12300,
            isDetails: false,
            isCorporateClient: true
        }
        this.loadDate = this.loadDate.bind(this);
        this.handleLoadForm = this.handleLoadForm.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        var prevAccountId = this.props.match.params.accountId;
        var currentAccountId = nextProps.match.params.accountId;
        if (currentAccountId) {
            let params = {
                accountId: currentAccountId,
                toDate: this.state.toDate,
                fromDate: this.state.fromDate,
                isDetail: true,
                voucherNo: 0,
                isCorporateClient: false
            }

            if (prevAccountId !== currentAccountId) {
                this.props.requestJournalDetails(params);
                this.setState({"accountId": parseInt(currentAccountId), isCorporateClient: false})
            }
        } else if (currentAccountId === undefined && prevAccountId !== currentAccountId) { // when back button is pressed
            let params = {
                accountId: -12300,
                toDate: this.state.toDate,
                fromDate: this.state.fromDate,
                isDetail: true,
                voucherNo: 0,
                isCorporateClient: true
            };
            this.setState({"accountId": -12300, isCorporateClient: true});
            this.props.requestJournalDetails(params);
        }

    }

    componentDidMount() {
        let accountId = this.props.match.params.accountId;
        let params = {
            accountId: accountId ? accountId : -12300,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            isDetail: true,
            voucherNo: 0,
            isCorporateClient: true
        }
        this.props.requestJournalDetails(params);
        if (accountId !== undefined) {
            this.setState({accountId: parseInt(accountId)})
        }
    }


    _thousandSeparator(num) {
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }


    _generateRow(a, convt1, convt2, initBalance, endingBalance, isSpecial) {
        const voucherDt = a.voucherDate && new Date(a.voucherDate);
        const minDate = new Date(1, 1, 1);
        //const jvnInv = (a.account.name === "Sales" && a.salesInvoice.invoiceCode
        const jvnInv = (a.account.name === "Sales" && a.salesInvoice && a.salesInvoice.invoiceCode

            ? (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}<br/>{`${a.salesInvoice.invoiceCode}`}</div>)
            : (<div>{`JVN-${a.voucherNo.toString().padStart(8, "0")}`}</div>));
        if (isSpecial) {
            let op = a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit;
            return [
                (<Link
                    title="Click to see details"
                    to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(a.voucherNo)}` : a.account.willHaveChild === false ? `/vehicle-sales-history/${a.accountId}?fromDate=${this.state.fromDate}&toDate=${this.state.toDate}` : `/corporate-client/list/${a.account.name}-${a.accountId}`}>
                    {a.account.name}
                </Link>),
                this._thousandSeparator(op.toFixed(2)), //op > 0 ? this._thousandSeparator(op.toFixed(2)) : "0.00",
                //op < 0 ? this._thousandSeparator(Math.abs(op).toFixed(2)) : "0.00",
                a.debit ? this._thousandSeparator(a.debit.toFixed(2)) : "0.00",
                a.credit ? this._thousandSeparator(a.credit.toFixed(2)) : "0.00",
                this._thousandSeparator(Math.abs(endingBalance).toFixed(2)), //endingBalance > 0 ? this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) : "0.00",
                //endingBalance < 0 ? this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) : "0.00"
            ];

        } else {
            return [
                voucherDt && voucherDt.getTime() > minDate.getTime() ? convt1.format(voucherDt) : "",
                a.isOpeningBalance ?
                    "(Opening Balance)"
                    : <Link
                        title="Click to see details"
                        to={a.account.action ? `/accounting/journal/view?no=${encodeURIComponent(a.voucherNo)}` : a.account.willHaveChild === false ? `/vehicle-sales-history/${a.accountId}?fromDate=${this.state.fromDate}&toDate=${this.state.toDate}` : `/corporate-client/list/${a.account.name}-${a.accountId}`}>
                        {a.account.name}
                    </Link>,
                //Math.abs(initBalance).toMoney() + " " + (initBalance >= 0 ? "Dr" : "Cr"),
                this._getAmountForCell(a, 1),
                this._getAmountForCell(a, 2),
                this._thousandSeparator(Math.abs(endingBalance).toFixed(2)) + " " + (endingBalance >= 0 ? "Dr" : "Cr"),
                a.comments || "No Comments",
                a.voucherNo ? jvnInv : ""
            ];
        }
    }


    _getAmountForCell(a, type) {
        return a.isOpeningBalance
            ? (a.type === type
                ? this._thousandSeparator(a.amount.toFixed(2))
                : "---")
            : a.type === type
                ? "---"
                : this._thousandSeparator(a.amount.toFixed(2));
    }


    generateRows(journalModel) {
        journalModel = {...journalModel}
        if (journalModel.journals) {
            journalModel.journals = [...journalModel.journals]
        }
        const opt1 = {month: "long", day: "numeric", year: "numeric"};
        const opt2 = {month: "short", day: "numeric"};
        const convt1 = new Intl.DateTimeFormat("en-US", opt1);
        const convt2 = new Intl.DateTimeFormat("en-US", opt2);
        let lastBalance, manufecturings,
            endingBalance = journalModel.openingBalance ? journalModel.openingBalance.debit - journalModel.openingBalance.credit : 0;

        const rows = (journalModel.journals || []).map((a, i) => {
            const voucherDt = a.voucherDate && new Date(a.voucherDate);

            if (!journalModel.isDetail) {
                if (!lastBalance) {
                    //if (journalModel.openingBalance) {
                    lastBalance = journalModel.openingBalance.debit - journalModel.openingBalance.credit;
                    //}
                    //else {
                    //    lastBalance = 0;
                    //}

                }
                return [
                    voucherDt ? convt1.format(voucherDt) : "",
                    "Balance",
                    this._thousandSeparator(Math.abs(lastBalance).toFixed(2)) + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                    this._thousandSeparator(a.debit.toFixed(2)),
                    this._thousandSeparator(a.credit.toFixed(2)),
                    this._thousandSeparator(Math.abs((lastBalance = (a.debit && a.credit ? ((a.debit - a.credit) + lastBalance) : 0))).toFixed(2)) + " " + (a.debit && a.credit ? a.debit >= a.credit ? "Dr" : "Cr" : "")
                ];
            }

            let initBalance = endingBalance;
            if (journalModel.isSpecial) {
                endingBalance = (a.account.openingBalanceJournal.debit - a.account.openingBalanceJournal.credit) + (a.debit - a.credit);
            } else {
                endingBalance += a.isOpeningBalance
                    ? a.type === 2
                        ? a.amount
                        : -1 * a.amount
                    : a.type === 1
                        ? a.amount
                        : -1 * a.amount;
            }
            return this._generateRow(a, convt1, convt2, initBalance, endingBalance, journalModel.isSpecial);
        });
        if (rows.length === 0) {
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
            rows.push(["---", "---", "---", "---", "---", "---", "---"]);
        }
        let openingBalance = journalModel.openingBalance;

        if (journalModel.isDetail && openingBalance) {
            var date = new Date(this.state.fromDate)
            var dateCal = date.setDate(date.getDate() - 1)

            rows.splice(0, 0, [
                "Initial Balance",
                `From ${convt1.format(dateCal)}`,
                "",
                "",
                this._thousandSeparator(Math.abs(openingBalance.debit - openingBalance.credit).toFixed(2)) + " " +
                ((openingBalance.debit - openingBalance.credit) >= 0 ? "Dr" : "Cr"), "", ""
            ])
        }
        if (!journalModel.isDetail) {
            rows.push([
                "",
                "Total",
                this._thousandSeparator((Math.abs(lastBalance) || 0).toFixed(2)) + " " + (lastBalance >= 0 ? "Dr" : "Cr"),
                "",
                "",
                ""
            ]);
            return rows;
        }
        if (journalModel.isSpecial) {
            let sumOfOpDr = journalModel.journals
                .map(j => j.account.openingBalanceJournal.debit - j.account.openingBalanceJournal.credit)
                .filter(j => j > 0)
                .reduce((a, b) => a + b, 0);

            // let sumOfOpCr = journalModel.journals
            //     .map(j => j.account.openingBalanceJournal.credit - j.account.openingBalanceJournal.debit)
            //     .filter(j => j > 0)
            //     .reduce((a, b) => a + b, 0);

            let sumOfDr = journalModel.journals
                .map(j => j.debit)
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .map(j => j.credit)
                .reduce((a, b) => a + b, 0);

            let endDr = journalModel.journals
                .map(j => (j.account.openingBalanceJournal.debit - j.account.openingBalanceJournal.credit) + (j.debit - j.credit))
                .reduce((a, b) => a + b, 0);
            // let endCr = sumOfOpCr + sumOfCr;

            let row = [
                "Total: ",
                this._thousandSeparator(sumOfOpDr.toFixed(2)),
                // this._thousandSeparator(sumOfOpCr.toFixed(2)),
                `৳ ${this._thousandSeparator(sumOfDr.toFixed(2))}`,
                `৳ ${this._thousandSeparator(sumOfCr.toFixed(2))}`,
                this._thousandSeparator(endDr.toFixed(2)),
                // this._thousandSeparator(endCr.toFixed(2))

            ];
            rows.push(row);
        } else if (journalModel.journals) { // total balance calculation

            let sumOfDr = journalModel.journals
                .filter(a => journalModel.isDetail
                    ? a.isOpeningBalance
                        ? a.type === 2
                        : a.type === 1
                    : a.amount < 0)
                .map(j => Math.abs(j.amount))
                .reduce((a, b) => a + b, 0);

            let sumOfCr = journalModel.journals
                .filter(a => journalModel.isDetail ? a.isOpeningBalance ? a.type === 1 : a.type === 2 : a.amount > 0)
                .map(j => Math.abs(j.amount))
                .reduce((a, b) => a + b, 0);

            let row = [
                "Total Debit/Credit",
                "",
                "৳ " + this._thousandSeparator(sumOfDr.toFixed(2)),
                "৳ " + this._thousandSeparator(sumOfCr.toFixed(2)),
                "",
                "",
                ""
            ];
            rows.push(row);


            if (openingBalance) {
                sumOfCr += openingBalance.credit;
                sumOfDr += openingBalance.debit;
            }

            let totalBalance = Math.abs(sumOfCr - sumOfDr);

            rows.push([
                "",
                "Total",
                sumOfDr >= sumOfCr ? "৳ " + this._thousandSeparator(totalBalance.toFixed(2)) : "",
                sumOfCr > sumOfDr ? "৳ " + this._thousandSeparator(totalBalance.toFixed(2)) : "",
                "",
                "",
                ""
            ]);

        }
        return rows;
    }

    loadDate(e) {
        let target = e.target;
        let name = target.name;
        let value = target.value;
        this.setState({
            [name]: value
        } as Date)


    }

    handleLoadForm(e: any) {
        e.preventDefault();

        let params = {
            accountId: this.state.accountId,
            toDate: this.state.toDate,
            fromDate: this.state.fromDate,
            isDetail: true,
            voucherNo: 0,
            isCorporateClient: true
        }
        this.props.requestJournalDetails(params);
    }


    render() {
        const {journalModel, isLoading} = this.props;
        let heads = [], rows = [];
        if (!isLoading && journalModel && journalModel.journals as any) {
            heads = !journalModel.isSpecial
                ? [
                    "Date of Transaction",
                    "Account Against",
                    journalModel.isDetail ? "Debit" : "Opening Balance",
                    journalModel.isDetail ? "Credit" : "Debit",
                    journalModel.isDetail ? "Note" : "Credit",
                    journalModel.isDetail ? "JVN/INV" : "Ending Balance"
                ]
                : [
                    "Account Against",
                    "Previous Due", //"Opening(Debit)",
                    //"Opening(Credit)",
                    "New Sales",//"Debit",
                    "Paid",//"Credit",
                    "Current Due",//"Ending(Debit)",
                    //"Ending(Credit)"
                ];

            if (!journalModel.isSpecial) {
                //if (journalModel.isDetail) {
                //    header.splice(2, 0, "Opening Balance");
                //}
                if (journalModel.isDetail) {
                    heads.splice(4, 0, "Balance");
                }
            }

            rows = this.generateRows(journalModel) as any;

        }

        const convt1 = new Intl.DateTimeFormat("en-US", {month: "long", day: "numeric", year: 'numeric'});

        return (
            <div className="corporateClient-section">
                <Row>

                    <Col md={12} className="cardPaddiingView">
                        <Card
                            title=""
                            content={
                                <form onSubmit={this.handleLoadForm}>
                                    <Row>
                                        <div className=' d-inline shadow ml-3 searchFilter'>
                                            <i className='pe-7s-search' style={{fontSize: 24}}></i>
                                        </div>
                                        <h4 style={{marginBottom: 20, marginTop: -20}}>&nbsp; Filter </h4>
                                    </Row>
                                    <Row>
                                        <Col md={3} className='mb-2 InputGroup'>
                                            <label>From</label><br/>
                                            {/*<input className='date-picker-from-input inputField' type="date" name="fromDate" value={this.state.fromDate.format("YYYY-MM-DD")} onChange={this.loadDate} />*/}
                                            <DatePicker className='date-picker-from-input inputField'
                                                        dateFormat="dd-MMM-yyyy" selected={this.state.fromDate}
                                                        onChange={date => this.setState({"fromDate": date})}/>
                                        </Col>

                                        <Col md={3} className='mb-2 ml-auto InputGroup'>
                                            <label>To</label><br/>
                                            {/*<input className='date-picker-from-input inputField' type="date" name="toDate" value={this.state.toDate} onChange={this.loadDate} />*/}
                                            <DatePicker className='date-picker-from-input inputField'
                                                        dateFormat="dd-MMM-yyyy" selected={this.state.toDate}
                                                        onChange={date => this.setState({"toDate": date})}/>
                                        </Col>

                                        {/**<Col md={3} className='mt-2 ml-auto mb-2 form'>

                                         <div className="custom-control custom-radio">
                                         <input type="radio" className="custom-control-input" id="customControlValidation1" name="radio-stacked" checked />
                                         <label className="custom-control-label" htmlFor="customControlValidation1">Deatails View</label>
                                         </div>

                                         <div className="custom-control custom-radio mb-2">
                                         <input type="radio" className="custom-control-input" id="customControlValidation2" name="radio-stacked" />
                                         <label className="custom-control-label" htmlFor="customControlValidation2">Brief View</label>

                                         </div>


                                         </Col>**/}

                                        <Col md={6} className='mb-2'>

                                            <Button className='btn btn-sm btn-info text-uppercase btn-search'
                                                    formAction='search' active>
                                                <i className="fa fa-search"
                                                   style={{fontSize: 14, color: 'white',}}></i>&nbsp;
                                                Search
                                            </Button>

                                        </Col>
                                    </Row>

                                    {/** <Row>
                                     <Col md={5} className='mt-1'>

                                     <input className='form-control inputField' type='search' placeholder='Search Client' />
                                     </Col>

                                     </Row>**/}


                                </form>
                            }
                        />
                    </Col>

                    <Col md={12}>
                        <Card
                            title=""
                            content={
                                <form>
                                    <Row>
                                        <Col>
                                            <div className="header-title title1">
                                                <h4>
                                                    <i className='pe-7s-user' style={{fontSize: 34}}></i>

                                                    {(journalModel !== null && journalModel.account !== null) ? journalModel.account.name : ""}
                                                    {(journalModel !== null && journalModel.account !== null) ? typeof journalModel.account !== "undefined" ? `(${convt1.format(new Date(journalModel.account.createDate))})` : null : null}
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="newBtn">
                                                <Link to="/corporate-client/new" className='btn-sm'>
                                                    <i className="pe-7s-add-user"></i>&nbsp;
                                                    Create New Client
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>


                                    <Table hover responsive>
                                        <thead>
                                        {/* 
                                             * <tr>
                                                <th>#</th>
                                                <th>
                                                    <strong> Client Name</strong>
                                                    <i className="fa fa-sort"></i>
                                                    <div className="filter-input">
                                                        <input className='form-control inputField2' type='search' />
                                                    </div>
                                                </th>

                                                <th>
                                                    <strong>Previous Balance</strong>
                                                    <i className="fa fa-sort"></i>
                                                    <div className="filter-input">
                                                        <input className='form-control inputField2' type='search' />
                                                    </div>
                                                </th>

                                                <th>
                                                    <strong>Sales</strong>
                                                    <i className="fa fa-sort"></i>
                                                    <div className="filter-input">
                                                        <input className='form-control inputField2' type='search' />
                                                    </div>
                                                </th>

                                                <th style={{ width: 200 }}>
                                                    <strong> Payment </strong>
                                                    <i className="fa fa-sort"></i>
                                                    <div className='form-control inputFieldDisable'>

                                                    </div>
                                                </th>
                                                <th style={{ width: 200 }}>
                                                    <strong> Final Due </strong>
                                                    <i className="fa fa-sort"></i>
                                                    <div className='form-control inputFieldDisable'>

                                                    </div>
                                                </th>



                                                <th></th>
                                            </tr>
                                            */}
                                        <tr>
                                            {heads.map(a => (
                                                <th>
                                                    <strong>{a}</strong>
                                                    <i className="fa fa-sort"></i>
                                                </th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows && rows.map(r => (<tr>  {r.map(c => (<td>{c}</td>))} </tr>))}

                                        </tbody>
                                    </Table>
                                    <Pagination aria-label="Page navigation example">


                                    </Pagination>

                                    {journalModel && journalModel.journals &&
                                    <Row>
                                        <a className="btnRound shadow" href={`/print/corporate-client/${this.state.accountId}?fromDate=${this.state.fromDate.toDateString()}&toDate=${this.state.toDate.toDateString()}&isCorporateClient=${this.state.isCorporateClient}`} target="_blank">
                                            <i className="pe-7s-print" style={{fontSize: 28, color: 'white'}} />
                                        </a>
                                    </Row>}
                                </form>
                            }
                        />
                    </Col>


                </Row>
            </div>
        )

    }
}

export default connect(
    (state: ApplicationState) => {
        return {...state.client, ...state.account}
    }, {...Client.actionCreators, ...Account.actionCreators}
)(ViewCorporateClient as any);