import { IAuthData } from "../models/AuthData";
import { GraphQL as API } from "../common/ApiUrls";
import { GraphQLData } from "../models/CommonModels";
import http, { PostBodyType } from "./HttpService";

interface IGraphQL {
    executeQuery<T>(query: string, params?: string[], variables?: any, authData?: IAuthData | null): Promise<T>;
    executeMutation<T>(query: string, params?: string[], inputs?: any, authData?: IAuthData | null): Promise<T>;
}

class GraphQL implements IGraphQL {

    async executeQuery<T>(query: string, params?: string[], variables?: any, authData?: IAuthData | null): Promise<T> {

        const param = (params || [] as string[]).map((a: string) => a.startsWith("$") ? a : `$${a}`).join(", ");
        const gql = `query RootQuery ${(param.length === 0 ? "" : `(${param})`)} { ${query} }`;

        return await this.execute(gql, variables, authData);
    }

    async executeMutation<T>(query: string, params?: string[], inputs?: any, authData?: IAuthData | null): Promise<T> {
       
        const param = (params || [] as string[]).map((a: string) => a.startsWith("$") ? a : `$${a}`).join(", ");
        const gql = `mutation ${(param.length === 0 ? "" : `(${param})`)} { ${query} }`;

        return await this.execute(gql, inputs, authData);
    }

    async execute<T>(query: string, variables?: any, authData?: IAuthData | null): Promise<T> {
       
        const gqlData = await http.postAsync<GraphQLData<T>>(API, { query, variables }, PostBodyType.JSON, authData);

        if (gqlData.errors) {
            console.log(gqlData.errors);
        }

        return new Promise((resolve, reject) => { 
            if (gqlData.data) {
                resolve(gqlData.data);
            } else {
                reject(gqlData.errors);
            }
        });
    }

}

export default new GraphQL() as IGraphQL;