import * as React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Container, CardFooter } from "reactstrap";
import { Input } from "../FormInputs/Input";

import "../../assets/css/login-register.css";

export default class Signup extends React.PureComponent {

    handleSignup(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }

    render() {
        return (

            <Container className="margin-lg-top-150 margin-sm-top-50">
                <Row>
                    <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }}>

                        <Card className="animated shake">
                            <CardBody className="login" style={{ padding: "0" }}>
                                <div>
                                    <CardHeader>
                                        <CardTitle>Registration with</CardTitle>
                                    </CardHeader>
                                    <div>
                                        <div className="box">
                                            <div className="content registerBox">

                                                <div className="social">
                                                    <a className="circle github" href="#">
                                                        <i className="fa fa-github fa-fw"></i>
                                                    </a>
                                                    <a id="google_login" className="circle google" href="#">
                                                        <i className="fa fa-google-plus fa-fw"></i>
                                                    </a>
                                                    <a id="facebook_login" className="circle facebook" href="#">
                                                        <i className="fa fa-facebook fa-fw"></i>
                                                    </a>
                                                </div>
                                                <div className="division">
                                                    <div className="line l"></div>
                                                    <span>or</span>
                                                    <div className="line r"></div>
                                                </div>
                                                <div className="error"></div>

                                                <div className="form">
                                                    <form onSubmit={this.handleSignup} acceptCharset="UTF-8">
                                                        <Input id="vendor_user" className="form-control" type="text" placeholder="Email" name="vendor_user" />
                                                        <Input id="vendor_challenge" className="form-control" type="password" placeholder="Password" name="vendor_challenge" />
                                                        <Input id="vendor_challenge_confirm" className="form-control" type="password" placeholder="Repeat Password" name="vendor_challenge_confirm" />
                                                        <Input className="btn btn-default btn-register" type="button" value="Create account" name="commit" />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CardFooter>
                                        <div className="forgot register-footer">
                                            <span>Already have an account ? </span><Link to={"/login"}>Login</Link>
                                        </div>
                                    </CardFooter>
                                </div>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

            </Container >

        );
    }
}