import SecureLS from "secure-ls";
import { LC_KEY } from "../common/Constants";

class TempDB {
    getItem<T>(key: string): T | null {
        try {
            const aes_u = new SecureLS({ encodingType: "aes", isCompression: true, encryptionSecret: atob(LC_KEY) });
            const data = aes_u.get(key);

            if (data == null) {
                return null;
            }

            return data as T;
        } catch (e) {
            console.warn(e);
        } 

        return null;
    }

    setItem(key: string, data: any) {
        try {
            if (data == null) {
                return;
            }
            const aes_u = new SecureLS({ encodingType: "aes", isCompression: true, encryptionSecret: atob(LC_KEY) });

            aes_u.set(key, data);
        } catch (e) {
            console.warn(e);
        } 
    }


    removeItem(key: string) {
        try {
            const aes_u = new SecureLS({ encodingType: "aes", isCompression: true, encryptionSecret: atob(LC_KEY) });
            aes_u.remove(key);
        } catch (e) {
            console.warn(e);
        }
    }
}

export default new TempDB();