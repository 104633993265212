import * as React from "react";
import {
    Form,
    Row, Col, FormGroup, Label, Input
} from 'reactstrap';

import { Card } from "../../Card/Card";
import { FormInputs } from "../../FormInputs/FormInputs";
import { UserCard } from "../../UserCard/UserCard";
import Button from "../../CustomButton/CustomButton";

import avatar from "../../../assets/img/faces/face-3.jpg";

import { thArray, tdArray } from "../../../variables/Variables";

import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import { ISupplierModel, ISubDistrict, IDistrict } from "../../../models/SupplierModel"
import * as Supplier from "../../../store/Supplier";
import { borderRadius } from "react-select/src/theme";
import "../../../assets/css/Accounting/add-account.css";
import "../../../assets/css/invoice/sales-invoice-print.css";

import { toastr } from "react-redux-toastr";




interface ISupplierState {
    id: number,
    name: string,
    contactPerson: string,
    address: string,
    cellPhone: string,
    districtId: number,
    subDistrictId: number,
    bankName: string,
    branchName: string | null,
    accountName: string | null,
    accountNo: string | null,
    bKashAccountNo: string | null,
    active: boolean,
    district: IDistrict | undefined,
    subDistrict: ISubDistrict | undefined,
    initialBalance: number,
    type: number,
}

type SupplierProps = ISupplierState & Supplier.ISupplierState & typeof Supplier.actionCreators

class AddService extends React.PureComponent<SupplierProps, ISupplierState> {
    constructor(props: SupplierProps) {
        super(props);
        this.state = {
            id: 0,
            name: "",
            contactPerson: "",
            address: "",
            cellPhone: "",
            districtId: 0,
            subDistrictId: 0,
            bankName: "",
            branchName: "",
            accountName: "",
            accountNo: "",
            bKashAccountNo: "",
            active: false,
            district: null,
            subDistrict: null,
            initialBalance: 0,
            type: 1


        }

        //this.loadDistrictSubDistrict = this.loadDistrictSubDistrict.bind(this);
        this.addSupplierInfo = this.addSupplierInfo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.validateForm = this.validateForm.bind(this);
        this.handlerChangeEvent = this.handlerChangeEvent.bind(this);
        this.setDebitCredit = this.setDebitCredit.bind(this);
        this.handleChangeSubDistrictSelect = this.handleChangeSubDistrictSelect.bind(this);
        this.handleChangeDistrictSelect = this.handleChangeDistrictSelect.bind(this);

    }
     

    componentDidMount() {

        this.props.requestGetDistricts();
        this.props.requestGetSubDistricts();

    }

    addSupplierInfo(e: React.ChangeEvent<HTMLInputElement>): void {

        var target = e.target;
        let name = target.name;

        let value = target.value;
        if (value) {
            this.setState({
                [name]: value
            } as any);

        }
    }

    handlerChangeEvent(e: React.ChangeEvent<HTMLInputElement>): void {

        let target = e.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        } as any);
    }


    setDebitCredit(e: React.ChangeEvent<HTMLSelectElement>) {

        e.preventDefault();

        let target = e.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        } as any);
    }



    handleSubmit(e: React.FormEvent<HTMLFormElement>): void {

        e.preventDefault();
        let dist = this.props.districts.find(a => a.name.toLowerCase() === "dhaka");
   
        this._isFormValid() && this.props.requestAddSupplier({
            name: this.state.name,
            subDistrictId: this.state.subDistrictId,
            cellPhone: this.state.cellPhone,
            contactPerson: this.state.contactPerson,
            accountName: this.state.accountName,
            districtId: dist && dist.id !== 0 ? dist.id : 0,
            address: this.state.address,
            accountNo: this.state.accountNo,
            bKashAccountNo: this.state.bKashAccountNo,
            bankName: this.state.bankName,
            branchName: this.state.branchName,
            active: undefined,
            id: 0,
            district: undefined,
            subDistrict: undefined,
            account: {
                journals: [
                    {
                        type: this.state.type,
                        amount: this.state.initialBalance,
                        accountId: undefined,
                        comments: undefined, id: undefined, isOpeningBalance: undefined, voucherDate: undefined, voucherNo: undefined
                    } as any],
                id: 0,
                willHaveChild: true,
                name: this.state.name 
            }
        } as any);

    }


    handleChangeSubDistrictSelect(e: any): void {
        this.setState({
            subDistrictId: e.value
        } as any);
    }

    handleChangeDistrictSelect(e: any): void {
        this.setState({
            districtId: e.value
        } as any); 
    }

    _isFormValid = (): boolean => {
        const { districtId, subDistrictId, name, contactPerson, address } = this.state;

        if (districtId <= 0) {
            toastr.error("Please select a district!")
            return false;
        } else if(subDistrictId <= 0){
            toastr.error("Please select Sub-District!");
            return false;
        } else if (name == "" || name.length < 2) {
            toastr.error("Please select Name!");
            return false;
        } else if (address ==""){
            toastr.error("Please enter address!");
            return false;
        }

        return true;
    }


    render() {
        const { districts, subDistricts, isPosted } = this.props;
        let dists = districts && districts.map((type: IDistrict) => { return { value: type.id, label: type.name } });
        let subDists = subDistricts && subDistricts.map((type: IDistrict) => { return { value: type.id, label: type.name } });
        var dist = dists && dists.find(a => a.label.toLowerCase() === "dhaka");
        if (isPosted) {
            return <Redirect to={"/accounting/view"} />;
        }

        return (
            <div>
                <Row>
                    <Col md={12}>
                        <form onSubmit={this.handleSubmit}>
                            <h4 className="suppliersTitle lh-8"><i className="pe-7s-home fnt-24"></i><span className="title"> Add Suppliers Entry</span> </h4>
                        <div className="line-container">
                            <div className="title-line1"></div>
                            <div className="title-line2"></div>
                        </div>
                        <Row>
                            <Col md={5} style={{ padding: 0 }}>
                                <span className="sub-title text-uppercase"> Suppliers Information</span>
                                        <FormGroup className="group-input">
                                                <Label>Company Name</Label>
                                            <Input className='form-control custom-input-blue' type="text" name="name" onChange={this.handlerChangeEvent} placeholder='Company Name'/>
                                            </FormGroup>                                                                         
                                        <FormGroup className="group-input">
                                                <Label>Contact Person</Label>
                                            <Input className='form-control custom-input-blue' type="text" name="contactPerson" onChange={this.handlerChangeEvent} placeholder='Contact Person'/>
                                            </FormGroup>                                    
                                        <FormGroup className="group-input">
                                                <Label>Contact No</Label>
                                            <Input className='form-control custom-input-blue' type="text" name="cellPhone" onChange={this.handlerChangeEvent} placeholder='Contact No'/>
                                            </FormGroup>                                       
                                <Row className="row-address">
                                    <Col md={12} className="p-0">
                                        <FormGroup >
                                            <Label className="pl-3">Address</Label>
                                            <Row className="row-input ml-mins-13">
                                                    <Col md={4}>
                                                        <Input className='form-control custom-input-blue' name="address" onChange={this.handlerChangeEvent} placeholder="Address" />
                                                    </Col>
                                                    <Col md={4}>
                                                        {dist && <Select
                                                            options={dists}
                                                            onChange={this.handleChangeDistrictSelect} 
                                                            value={dists && dists.filter(a => a.value === dist.value)}
                                                        />}
                                                    </Col>
                                                    <Col md={4}>
                                                        <Select
                                                            options={subDists}
                                                            onChange={this.handleChangeSubDistrictSelect}
                                                            value={(subDists && this.state.subDistrictId > 0 && subDists.length > 0) ? subDists.filter(o => o.value === this.state.subDistrictId) : ""}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="row-address">
                                        <Col md={12} className="p-0">
                                            <Row className="row-input ml-mins-13">
                                                <Col md={7}>
                                                    <FormGroup>
                                                        <Label>Initial Balance (BDT)</Label>
                                                        <Input className='form-control custom-input-blue' type="text" name="initialBalance" onChange={this.handlerChangeEvent} placeholder='' />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Label>Type</Label>
                                                        <select onChange={this.setDebitCredit} name="type" className=" form-control custom-input-blue" >
                                                            <option value="1">Due</option>
                                                            <option value="2">Advance</option>

                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="column-saperator"></div>

                                </Col>


                                <Col md={1} className="suppl-vart-line"></Col>

                                <Col md={5} className="BnkAccDetails p-0">
                                    <span className="sub-title text-uppercase">Bank Account Details</span>

                                    <FormGroup className="group-input" >
                                        <Label>Bank Name</Label>
                                        <Input className='form-control custom-input-blue' type="text" name="bankName" placeholder='Bank Name' />
                                    </FormGroup>

                                    <FormGroup className="group-input">
                                        <Label>Branch Name</Label>
                                        <Input className='form-control custom-input-blue' type="text" name="branchName" placeholder='Branch Name' />
                                    </FormGroup>

                                    <FormGroup className="group-input">
                                        <Label>Account Name</Label>
                                        <Input className='form-control  custom-input-blue' type="text" name="accountName" placeholder='Account Name' />
                                    </FormGroup>

                                    <FormGroup className="group-input">
                                        <Label>Account No:</Label>
                                        <Input className='form-control  custom-input-blue' type="text" name="accountNo" placeholder='Account No' />
                                    </FormGroup>

                                    <FormGroup className="group-input">
                                        <Label> BKash Account No:</Label>
                                        <Input className='form-control custom-input-blue' type="text" name="bKashAccountNo" placeholder='Bkash Mobile No' />
                                    </FormGroup>

                                    <Button pullRight fill type="submit" bsStyle="info">
                                        Save
                            </Button>

                                </Col>
                            </Row>

                        </form>
                    </Col>
                </Row >
            </div>

        );
    }
}
export default connect((state: ApplicationState) => { return { ...state.supplier, ...state.authorization } }, { ...Supplier.actionCreators })(AddService as any)