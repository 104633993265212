import * as React from "react";
import NumberFormat from "react-number-format";
import { Col } from "reactstrap";

import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import * as Account from "../../store/Account";
import { RouteComponentProps } from "react-router-dom";

interface ICashReceiptPrintState {

}

type Props = Account.AccountState & typeof Account.actionCreators & RouteComponentProps;



class CashReceiptPrint extends React.PureComponent<Props, ICashReceiptPrintState> {

    componentDidMount() {
        let jvn = this.props.match.params["jvn"];
        if (jvn !== null && jvn > 0) {
            this.props.requestGetJournalsByJvn(jvn);
        }
    }

    render() {
        const { journalList } = this.props;
        let journalDr = journalList !== null ? journalList.find(item => item.type === 1):null;
        let journalCr = journalList !== null ? journalList.find(item => item.type === 2):null;
        return (
            <div>
                <React.Fragment>
                    <div style={{ position: "relative", marginTop: "80px" }}>
                        <div style={{ textAlign: "center" }}>
                            <h2>Cash Receipt</h2>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <hr />
                        </div>


                        <div style={{ clear: "both" }}></div>

                        <table className="tblInvAddr erp-table-head">
                            <tbody>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                                <tr>

                                    <td><p>Voucher No <b>:</b> {journalCr && journalCr.voucher}  </p></td>
                                </tr>
                              
                                <tr>
                                    <td><p>Received From <b>:</b> {journalCr && journalCr.account && journalCr.account.name}  </p></td>
                                    <td><p>Date <b>:</b> {journalDr && new Date(journalDr.voucherDate).toLocaleDateString()} </p></td>
                                </tr>

                                <tr>
                                    <td><p>Received Type <b>:</b> {journalDr && journalDr.account && journalDr.account.name}  </p></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>




                        <div>
                            <div>
                                <h4 className="service-header"></h4>
                            </div>

                            <table className="tblData">
                                <tbody>
                                    <tr></tr>
                                    <tr></tr>
                                    <tr></tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ float: "left" }}>
                            <p style={{ textAlign: "center", fontSize: "15px" }}>This cash receipt is prepared as per your requirement. Please feel free to contact us for any more queries.</p>
                            <table className="tblCmnts erp-table-head">
                                <tbody>
                                    <tr>
                                        <th>Notes</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ol>
                                                <li>This cash receipt is valid for next 15 days only.</li>
                                                <li>Please send your car for servicing after every 3000 KM or another 3 months, whichever comes first.</li>
                                                <li>For genuine parts the warranty will be 6 months.</li>
                                            </ol>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div style={{ float: "right" }}>
                            <table className="tblDue erp-table-head">
                                <tbody>
                                    <tr>
                                        <th>Received Amount: </th>
                                        <td>   <NumberFormat value={journalDr && journalDr.amount} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <br />

                        <Col md={3} style={{ float: "left", textAlign: "center" }}>
                            <div className="tblCont mt-5">
                                <hr />
                                Date

                        </div>
                        </Col>
                        <Col md={3} style={{ float: "right", textAlign: "center" }}>
                            <div className="tblCont mt-5">
                                <hr />
                                            Signature

                        </div>
                        </Col>
                    </div>
                </React.Fragment>

            </div>
        )
    }
}
export default connect((state: ApplicationState) => { return { ...state.account, } }, { ...Account.actionCreators })(CashReceiptPrint as any);