import * as React from "react";
import NumberFormat from "react-number-format";
import { Col } from "reactstrap";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";
import * as Commission from "../../store/SalesCommision";
import {RouteComponentProps} from "react-router-dom";

interface IPrintCommissionState {
}

interface IPrintCommissionProps {
}

type PrintCommissionProps =
    IPrintCommissionProps
    & Commission.ICommissionState
    & typeof Commission.actionCreators
    & RouteComponentProps;

class PrintDriversCommission extends React.PureComponent<PrintCommissionProps, IPrintCommissionState> {
    
    componentDidMount(): void {
        const id = this.props.match.params['commissionId'];
        if (id) {
            this.props.requestGetCommission(id);
        }
    }
    
    componentWillUnmount(): void {
        this.props.resetCommissionState();
    }

    render() {
        
        const commission = this.props.commission;
        
        return (
            <div>
                <React.Fragment>
                    <div style={{ position: "relative", marginTop: "80px" }}>
                        <div style={{ textAlign: "center" }}>
                            <h2>Commission Voucher</h2>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <hr />
                        </div>


                        <div style={{ clear: "both" }}></div>

                        <table className="tblInvAddr erp-table-head">
                            <tbody>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>

                                <tr>
                                    <td><p>Invoice NO <b>: {commission && commission.invoice.invoiceCode}</b></p></td>
                                    <td><p>Commission Voucher <b>: {commission && commission.voucher}</b></p></td>
                                </tr>

                                <tr>
                                    <td><p>Invoice Total <b>: {commission && commission.invoice.total}</b> </p></td>
                                    <td><p>Date <b>: {commission && new Date(commission.commissionDate).toDateString()}</b></p></td>
                                </tr>
                                <tr>
                                    <td><p>Driver Name<b>: {commission && commission.name}</b></p></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><p>Driver Contact<b>: {commission && commission.phone}</b> </p></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>




                        <div>
                            <div>
                                <h4 className="service-header"></h4>
                            </div>

                            <table className="tblData">
                                <tbody>

                                </tbody>
                            </table>
                        </div>


                        <div style={{ float: "right" }}>
                            <table className="tblDue erp-table-head">
                                <tbody>
                                    <tr>
                                        <th>Amount: </th>
                                        <td>   <NumberFormat value={commission ? commission.amount : 0} displayType={'text'} thousandSeparator={true} prefix={'BDT '} renderText={value => <div>{value}</div>} />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div style={{ clear: "both" }}></div>
                        <br />
                        
                        <Col md={2} style={{ float: "right", textAlign: "center" }}>
                            <div className="tblCont mt-5">  
                                            <hr />
                                            Receive<br />Signature of Driver
                       
                        </div>
                        </Col>
                    </div>
                </React.Fragment>

            </div>
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.commission }), { ...Commission.actionCreators })(PrintDriversCommission);