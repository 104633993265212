import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import { IOwnersEquity } from "../models/OwnersEquityModel";
import graphQl from "../services/GraphQL";

export interface IOwnersEquityState {
    ownersEquity: IOwnersEquity | null,
    ownersEquities: Array<IOwnersEquity> | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    ownersEquityAdded: boolean,

}


//Request GET actions
export interface RequestGetOwnersEquity extends Action<string> {
    type: "REQUEST_GET_OWNERS_EQUITY";
}

export interface RequestGetOwnerEquity extends Action<string> {
    type: "REQUEST_GET_OWNER_EQUITY";
}
//Request ADD actions
export interface RequestAddOwners extends Action<string> {
    type: "REQUEST_ADD_OWNERS_EQUITY";
}

export interface RespondGetOwnersEquity extends Action<string> {
    type: "RESPOND_GET_OWNERS_EQUITY";
    payload: Array<IOwnersEquity> | null;
    error: Error | null
}



export interface RespondGetOwnerEquity extends Action<string> {
    type: "RESPOND_GET_OWNER_EQUITY";
    payload: IOwnersEquity | null;
    error: Error | null;
}



export interface RespondAddOwnerEquity extends Action<string> {
    type: "RESPOND_ADD_OWNERS_EQUITY";
    payload: IOwnersEquity | null;
    error: Error | null;
}

export interface IResetOwnerEquity extends Action<string> {
    type: "RESET_OWNER_EQUITY";
}










type KnownAction = RequestGetOwnersEquity |
    RequestGetOwnerEquity |
    RequestAddOwners |
    RespondGetOwnersEquity |
    RespondGetOwnerEquity |
    RespondAddOwnerEquity |    
    IResetOwnerEquity

export const actionCreators = {
    requestAddOwner: (ownerInfo: IOwnersEquity): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_OWNERS_EQUITY" });

            const authData = appState.authorization!.authData;

            var journals = ownerInfo.account.journals;
          
            const data = await graphQl.executeMutation<{ addOwnerEquity: IOwnersEquity }>("addOwnerEquity(ownerInfo: $ownerInfo){name cellPhone}", ["ownerInfo: OwnerEquityInputType!"], { ownerInfo }, authData);
        
            dispatch({ type: "RESPOND_ADD_OWNERS_EQUITY", payload: data.addOwnerEquity, error: null });
            dispatch({ type: "RESET_OWNER_EQUITY" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_OWNERS_EQUITY", payload: null, error: e });
        }
    },



    //requestGetOwners: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    //    const appState = getState();
    //    try {
    //        dispatch({ type: "REQUEST_GET_OWNERS_EQUITY" });
    //        const authData = appState.authorization!.authData;
    //        const data = await graphQl.executeQuery<{ suppliers: Array<IOwnersEquity> }>("suppliers{ id name cellPhone accountName }", [], {}, authData);

    //        dispatch({ type: "RESPOND_GET_SUPPLIERS", payload: data.suppliers, error: null });
    //        dispatch({ type: "RESET_SERVICE" });

    //    } catch (e) {
    //        dispatch({ type: "RESPOND_GET_SUPPLIERS", payload: null, error: e });
    //    }

    //},




  
}

const unloadedState: IOwnersEquityState = {
    ownersEquity:null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isLoaded: false,
    ownersEquityAdded: false,
    ownersEquities: []   
}


export const reducer: Reducer<IOwnersEquityState> = (state: IOwnersEquityState | undefined, incomingAction: Action): IOwnersEquityState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_OWNERS_EQUITY":
            return {
                ...state,
                isPosting: true,
                ownersEquityAdded:false
            }


        case "RESPOND_ADD_OWNERS_EQUITY":
            return {
                ...state,
                ownersEquity: action.payload,
                fetchError: action.error,
                isPosting: false,
                ownersEquityAdded: action.payload !== null
            }
        case "RESET_OWNER_EQUITY":
            {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: false,
                    ownersEquityAdded: false,
                    isPosting: false,

                }
            }

        default:
            return state;
    }
}
