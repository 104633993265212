import * as React from "react";
import routes from "./routes/routes.main";
import { RouteLooper } from "./components/common/RouteLooper"; 

import LoadingBar from 'react-redux-loading-bar';


export default class App extends React.PureComponent {

    render() {
        return (
            <React.Fragment>

                <LoadingBar style={{ backgroundColor: 'blue', height: '5px', zIndex: 9999999 }} />

                <RouteLooper routes={routes} />
            </React.Fragment>
        );
    }
}